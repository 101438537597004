import React, { useState } from "react";
import Index from "../../../Index";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PagesIndex from "../../../PagesIndex";

const ViewBlog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const row = location?.state?.row;
  console.log(row, 11);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [image, setImage] = useState(null);

  return (
    <>
      <Index.Box className="flex-all admin-title-box">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            className="admin-sub-title admin-buyer-title"
            component="h3"
            variant="h3"
          >
            Blog Details
          </Index.Typography>
        </Index.Box>
        <Index.Box className="view-btn-main border-btn-main">
          <PagesIndex.BlueborderButton
            className="cancel-btn blue-border-btn"
            btnLabel="Back"
            onClick={() => navigate("/admin/blogs-list")}
          />
        </Index.Box>
      </Index.Box>
      <Index.Box className="common-grid-main">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
            className="login-row"
          >
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Blog Name :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {row ? row?.title : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Categories :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {row
                    ? row?.categoryId?.map((row, index) => {
                      return <>{row ? row?.title : "-"}</>;
                    })
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>

            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Tags :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {row
                    ? row?.tagId?.map((row, index) => {
                      return <>{row ? row?.title : "-"}</>;
                    })
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Blog Post Date :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {row ? row?.title : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>

            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Sub Category :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {row ? row?.title : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>

            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Date :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {row?.createdAt
                    ? moment(row?.createdAt).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>

            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Image:
                </Index.Typography>
                <Index.Box className="common-edit-image-box">
                  <Index.Box className='common-edit-image'>
                    <img src={`http://194.233.77.156:3042/images/${image}`} alt='product' className='product-img' />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ViewBlog;

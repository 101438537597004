import React, { useEffect, useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import moment from "moment";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ViewBuyer(props) {
  const { openViewBuyerModal, handleCloseViewBuyerModal, rows } = props;

  // tab
  const [value, setValue] = React.useState("one");
  const [singleData, setSingleData] = useState({});
  const [image, setImage] = useState(null);

  // image modal

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSingleBuyer = async () => {
    await DataService.get(`${Api.Admin.GET_SINGLE_BUYER}/${rows?._id}`)
      .then((res) => {
        setSingleData(res?.data?.data);
      })
      .catch((error) => {
        console.log((error) => {
          console.log(error, 366);
        });
      });
  };
  useEffect(() => {
    getSingleBuyer();
  }, [rows]);
  return (
    <>
      <Index.Modal
        open={openViewBuyerModal}
        onClose={handleCloseViewBuyerModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="register-modal-inner-main auth-modal-inner modal-common-style"
        >
          <Index.Box className=" cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <Index.Typography className="auth-modal-title">
                Buyer Details
              </Index.Typography>

              <img
                src={PagesIndex.Svg.closeicon}
                className="modal-close-icon"
                onClick={handleCloseViewBuyerModal}
              />
            </Index.Box>
            <Index.Box sx={{ width: "100%" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
              >
                <Index.Tab value="one" label="Personal Details" />
                <Index.Tab value="two" label="Business Details" />
                <Index.Tab value="three" label="Documents Details" />
              </Index.Tabs>
            </Index.Box>
            {value == "one" ? (
              <>
                <Index.Box className="modal-body">
                  <Index.Box className="auth-content-main">
                    <Index.Box className="register-content-main">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                          className="login-row"
                        >
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Full Name :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData ? singleData?.fullName : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Phone Number :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {singleData
                                        ? singleData?.phoneNumber
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Email Address :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData ? singleData?.email : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              ""
            )}
            {value == "two" ? (
              <>
                <Index.Box className="modal-body">
                  <Index.Box className="auth-content-main">
                    <Index.Box className="register-content-main">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                          className="login-row"
                        >
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Bank name :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.bankDetails
                                        ? singleData?.bankDetails?.bankName
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Account number :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {singleData?.bankDetails
                                        ? singleData?.bankDetails?.accountNumber
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      IFSC code :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.bankDetails
                                        ? singleData?.bankDetails?.ifscCode
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              ""
            )}
            {value == "three" ? (
              <>
                <Index.Box className="modal-body">
                  <Index.Box className="auth-content-main">
                    <Index.Box className="register-content-main">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                          className="login-row"
                        >
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      MSME Certificate :
                                    </Index.Box>
                                    <Index.Box
                                      sx={{ paddingLeft: 1, cursor: "pointer" }}
                                      onClick={(e) => {
                                        handleOpen();
                                        setImage(singleData?.msme?.msmeImage);
                                      }}
                                    >
                                      {" "}
                                      {singleData?.msme
                                        ? singleData?.msme.msmeImage
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      GST Certificate :
                                    </Index.Box>
                                    <Index.Box
                                      sx={{ paddingLeft: 1, cursor: "pointer" }}
                                      onClick={(e) => {
                                        handleOpen();
                                        setImage(singleData?.gst?.gstImage);
                                      }}
                                    >
                                      {singleData?.gst
                                        ? singleData?.gst.gstImage
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      PAN of the company :
                                    </Index.Box>
                                    <Index.Box
                                      sx={{ paddingLeft: 1, cursor: "pointer" }}
                                      onClick={(e) => {
                                        handleOpen();
                                        setImage(singleData?.pan?.panImage);
                                      }}
                                    >
                                      {" "}
                                      {singleData?.pan
                                        ? singleData?.pan?.panImage
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Cancelled Cheque :
                                    </Index.Box>
                                    <Index.Box
                                      sx={{ paddingLeft: 1, cursor: "pointer" }}
                                      onClick={(e) => {
                                        handleOpen();
                                        setImage(
                                          singleData?.bankDetails?.cancelCheque
                                        );
                                      }}
                                    >
                                      {" "}
                                      {singleData?.bankDetails
                                        ? singleData?.bankDetails?.cancelCheque
                                        : ""}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              ""
            )}
            <Index.Box className="modal-auth-btn-main">
              <PagesIndex.OrangeFillButton
                btnLabel="Back"
                className="orange-fill-btn auth-modal-btn"
                onClick={() => {
                  handleCloseViewBuyerModal();
                }}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      {/* image modal */}
      <div>
        <Index.Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Index.Box sx={style}>
            <Index.Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              <img
                src={`http://194.233.77.156:3042/images/${image}`}
                alt="GST Image"
                width={300}
                height={300}
              />
            </Index.Typography>
          </Index.Box>
        </Index.Modal>
      </div>
    </>
  );
}

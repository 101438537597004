import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useNavigate } from "react-router-dom";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import moment from "moment";
import { toast } from "react-toastify";

export default function OrderManagement() {
  const navigate = useNavigate();
  const [getOrder, setGetOrder] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoader] = useState(false);
  console.log(getOrder, "getOrder");

  // Start Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    const result = getOrder?.filter((item) => {
      return (
        item?.buyerName?.fullName
          ?.toLowerCase()
          ?.toString()
          ?.includes(event?.target?.value.toLowerCase()) ||
        item?.orderId
          ?.toLowerCase()
          ?.toString()
          ?.includes(event?.target?.value.toLowerCase())
      );
    });

    setFilterData(result);
  };
  const viewData = (row) => {
    navigate("/admin/order-view", { state: { row: row } });
  };
  const editData = (row) => {
    navigate("/admin/order-edit", { state: { row: row } });
  };
  const deleteData = async (row) => {
    await DataService.get(`${Api.Admin.DELETE_ORDER}`, {
      params: { id: row },
    })
      .then((res) => {
        console.log(res, 21);
        toast.success(res?.data?.message);
        getAllOrder();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
      });
  };
  const getAllOrder = async () => {
    setIsLoader(true);
    await DataService.get(Api.Admin.GET_ALL_ORDER)
      .then((res) => {
        console.log(res, 21);
        setGetOrder(res?.data?.data);
        setFilterData(res?.data?.data);
        setTimeout(() => {
          setIsLoader(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });
  };
  useEffect(() => {
    getAllOrder();
  }, [page, rowsPerPage]);

  if (isLoading) {
    return <PagesIndex.Loader />;
  } else {
    return (
      <>
        <Index.Box className="order-management">
          <Index.Box className="flex-all admin-title-box">
            <Index.Box className="admin-sub-title-main">
              <Index.Typography
                className="admin-sub-title admin-buyer-title"
                component="h3"
                variant="h3"
              >
                Order Management
              </Index.Typography>
            </Index.Box>
            <Index.Box className="view-btn-main border-btn-main">
              <Index.Box className="admin-search-common">
                <Index.TextField
                  fullWidth
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e)}
                />
                <img src={PagesIndex.Svg.search} className="search-icon" />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="page-order-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container cus-scrollbar"
            >
              <Index.Table
                sx={{ minWidth: 1000 }}
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="12%"
                    >
                      Order ID
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="20%"
                    >
                      Buyer
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="15%"
                    >
                      Amount
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="18%"
                    >
                      Status
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="20%"
                    >
                      Date / Time
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                      style={{ fontWeight: 700 }}
                      width="15%"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {filterData?.length > 0 ? (
                    filterData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row, index) => {
                        return (
                          <>
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              key={row?._id}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row?.orderId}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row?.buyerName?.fullName}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row?.amount}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                <Index.Box
                                  // sx={{ color: "white", backgroundColor: "#27447C" }}
                                  className="buyer-list-status"
                                  sx={{
                                    ...(row.status === "Confirmed" && {
                                      color: "white",
                                      backgroundColor: "#27447C",
                                    }),
                                    ...(row.status === "In Process" && {
                                      color: "white",
                                      backgroundColor: "orange",
                                    }),
                                    ...(row.status === "Shipped" && {
                                      color: "white",
                                      backgroundColor: "darkorange",
                                    }),
                                    ...(row.status === "Delivered" && {
                                      color: "white",
                                      backgroundColor: "green",
                                    }),
                                  }}
                                >
                                  {row ? row.status : "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row?.createdAt
                                  ? moment(row?.createdAt).format(
                                      "DD-MM-YYYY HH:mm:ss"
                                    )
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                align="center"
                              >
                                <Index.Box
                                  sx={{ padding: "0px", margin: "0px" }}
                                >
                                  <Index.IconButton
                                    onClick={() => viewData(row)}
                                  >
                                    <Index.Visibility
                                      style={{ color: "black" }}
                                    />
                                  </Index.IconButton>
                                  <Index.IconButton
                                    onClick={() => editData(row)}
                                  >
                                    <img
                                      src={PagesIndex.Svg.editingIcon}
                                      alt="edit icon"
                                      width="20"
                                      height="20"
                                    />
                                  </Index.IconButton>
                                  <Index.IconButton
                                    onClick={() => deleteData(row?._id)}
                                  >
                                    <Index.DeleteOutlineIcon
                                      style={{ color: "black" }}
                                    />
                                  </Index.IconButton>
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>
                          </>
                        );
                      })
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                        colSpan={6}
                      >
                        No Record found
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
            <Index.TablePagination
              className="cus-scrollbar"
              component="div"
              page={page}
              count={filterData?.length}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 15, 20]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>
        </Index.Box>
        {/* <PagesIndex.EditOrder />
        <PagesIndex.ViewOrder /> */}
      </>
    );
  }
}

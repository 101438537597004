import React, { useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import "./auth.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogin } from "../../../redux/features/slices/user/UserService";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function Login({
  openLoginModal,
  handleCloseLoginModal,
  handleOpenLoginModal,
}) {
  // LoginModal modal
  // const [openLoginModal, setOpenLoginModal] = useState(false);
  // const handleOpenLoginModal = () => setOpenLoginModal(true);
  // const handleCloseLoginModal = () => setOpenLoginModal(false);

  // RegisterModal modal
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const handleOpenRegisterModal = () => setOpenRegisterModal(true);
  const handleCloseRegisterModal = () => setOpenRegisterModal(false);

  const [openForgotUser, setOpenForgotUser] = React.useState(false);
  const handleOpenForgotUser = () => setOpenForgotUser(true);
  const handleCloseForgotUser = () => setOpenForgotUser(false);

  const navigate = useNavigate();
  // password
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const dispatch = useDispatch();
  const initialValues = {
    masterField: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    masterField: Yup.string().required("Please enter email or phone number"),
    // masterField: Yup.string()
    //   .email("Please enter valid email")
    //   .matches(
    //     /^[a-z][a-z0-9]*(?:\.[a-z]+)*@[a-z]+\.[a-z]{2,}$/,
    //     "Please enter valid email"
    //   )
    //   .required("Please enter email"),

    password: Yup.string()
      .required("Please enter password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
  });
  const handleFormSubmit = async (values) => {
    console.log(values, 42);

    // await DataService.post(Api.User.Auth.USER_LOGIN, values)
    await dispatch(userLogin(values)).then((res) => {
      console.log(res?.payload?.data?.role, 72);
      // toast.success(res?.payload?.message);
      if (res?.payload?.status == 200) {
        handleCloseLoginModal();
        if (res?.payload?.data?.role == "buyer") {
          navigate("/dashboard");
        } else {
          navigate("/seller/dashboard");
        }
      }
    });
  };

  return (
    <>
      {/* <PagesIndex.OrangeFillButton btnLabel="Login" onClick={handleOpenLoginModal} className="orange-fill-btn go-tocart-btn" /> */}
      <Index.Modal
        open={openLoginModal}
        onClose={handleCloseLoginModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="login-modal-inner-main auth-modal-inner modal-common-style"
        >
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <Index.Typography className="auth-modal-title">
                Log in your account
              </Index.Typography>
              <img
                src={PagesIndex.Svg.closeicon}
                className="modal-close-icon"
                onClick={handleCloseLoginModal}
              />
            </Index.Box>
            <Formik
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                setFieldValue,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Index.Box>
                    <Index.Box className="modal-body">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xxs: 2, xs: 2, sm: 2, md: 3, lg: 4 }}
                          className="login-row"
                        >
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="comman-form-group">
                              <Index.Box className="auth-input-main">
                                <Index.TextField
                                  className="comman-form-control"
                                  placeholder="Email or Phone"
                                  name="masterField"
                                  onChange={(e) => {
                                    const updatedValue = e.target.value.trim();
                                    setFieldValue("masterField", updatedValue);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.masterField}
                                  // helperText={touched.email && errors.email}
                                  // error={Boolean(errors.email && touched.email)}
                                />
                              </Index.Box>
                              {errors.masterField && touched.masterField && (
                                <p className="error-text">
                                  {errors.masterField}
                                </p>
                              )}
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="comman-form-group">
                              <Index.Box className="auth-input-main">
                                <Index.OutlinedInput
                                  className="comman-form-control"
                                  placeholder="Please enter password"
                                  autocomplete="off"
                                  id="outlined-adornment-password"
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  value={values?.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  endAdornment={
                                    <Index.InputAdornment position="end">
                                      <Index.IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <Index.VisibilityOff />
                                        ) : (
                                          <Index.Visibility />
                                        )}
                                      </Index.IconButton>
                                    </Index.InputAdornment>
                                  }
                                />
                              </Index.Box>
                              {errors.password && touched.password && (
                                <p className="error-text">{errors.password}</p>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="dont-para-main">
                      <Index.Typography
                        className="auth-forget-text"
                        onClick={() => {
                          handleOpenForgotUser();
                          handleCloseLoginModal();
                        }}
                      >
                        Forgot Password?
                      </Index.Typography>

                      <Index.Box className="modal-auth-btn-main">
                        <PagesIndex.OrangeFillButton
                          btnLabel="Login"
                          className="orange-fill-btn auth-modal-btn"
                          type="submit"
                        />
                      </Index.Box>

                      <Index.Typography
                        className="dont-modal-para"
                        component="p"
                        variant="p"
                      >
                        Don’t have an account?{" "}
                        <span
                          className="auth-redirect-link"
                          onClick={() => {
                            handleOpenRegisterModal();
                            handleCloseLoginModal();
                          }}
                        >
                          Sign up
                        </span>
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Form>
              )}
            </Formik>

            <Index.Box className="modal-footer"></Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <PagesIndex.Register
        openRegisterModal={openRegisterModal}
        handleCloseRegisterModal={handleCloseRegisterModal}
        handleOpenLoginModal={handleOpenLoginModal}
      />
      <PagesIndex.ForgotPassword
        openForgotUser={openForgotUser}
        handleCloseForgotUser={handleCloseForgotUser}
        handleOpenLoginModal={handleOpenLoginModal}
        openLoginModal={openLoginModal}
      />
    </>
  );
}

import React, { useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import { Form, Formik } from "formik";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
import { toast } from "react-toastify";
import * as Yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function AddNewBuyerModal(props) {
  const { openAddNewBuyerModal, handleCloseAddNewBuyerModal, getBuyerList } =
    props;
  const [radioValue, setRadioValue] = useState("buyer");
  const [isLoading, setIsLoading] = useState(false);

  console.log(radioValue, "radioValue");
  const handleRadioChange = (newValue, e) => {
    setRadioValue(e);
  };
  // password
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Confirm password
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    fullName: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .matches(/^[a-zA-Z\s-, ]+$/, "Accept only alphabetic and white space")
      .required("Please enter your full name"),
    email: Yup.string()
      .email("Please enter your valid email")
      .matches(
        /^[a-z][a-z0-9]*(?:\.[a-z]+)*@[a-z]+\.[a-z]{2,}$/,
        "Please enter your valid email"
      )
      .required("Please enter your email address"),
    password: Yup.string()
      .required("Please enter password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password")],
        "Password and confirmed passwords do not match"
      ),
    phoneNumber: Yup.string().required("Please enter your phone number"),
  });
  const handleFormSubmit = async (values) => {
    setIsLoading(true);

    console.log(values, "values");
    const data = {
      fullName: values.fullName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      password: values.password,
      confirmPassword: values.confirmPassword,
      role: radioValue,
    };
    await DataService.post(Api.Admin.CREATE_BUYER_SELLER, data)
      .then((res) => {
        setIsLoading(false);

        console.log(res, "res218");
        toast.success(res?.data?.message);
        if (res?.data?.status === 200) {
          getBuyerList();
          handleCloseAddNewBuyerModal();
        }
      })
      .catch((error) => {
        console.log(error, "res225");
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Index.Modal
        open={openAddNewBuyerModal}
        onClose={handleCloseAddNewBuyerModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="register-modal-inner-main auth-modal-inner modal-common-style"
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={validationSchema}
          >
            {({
              handleSubmit,
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Index.Box className=" cus-scrollbar modal-hgt-scroll">
                  <Index.Box className="modal-header">
                    <Index.Typography className="auth-modal-title">
                      Add new buyer
                    </Index.Typography>
                    <img
                      src={PagesIndex.Svg.closeicon}
                      className="modal-close-icon"
                      onClick={handleCloseAddNewBuyerModal}
                    />
                  </Index.Box>
                  <Index.Box className="modal-body">
                    <Index.Box className="auth-content-main">
                      <Index.Typography className="register-title-main">
                        User Type
                      </Index.Typography>
                      <Index.Box className="auth-radio-main">
                        <Index.FormControl className="radio-form-control">
                          <Index.RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Buyer"
                            name="radio-buttons-group"
                            className="radio-group-main"
                            value={radioValue}
                            onChange={handleRadioChange}
                          >
                            <Index.FormControlLabel
                              className="form-control-label"
                              value="buyer"
                              control={<Index.Radio />}
                              label="Buyer"
                            />
                          </Index.RadioGroup>
                        </Index.FormControl>
                      </Index.Box>
                      <Index.Box className="register-content-main">
                        <Index.Typography className="register-title-main">
                          Contact Information
                        </Index.Typography>
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                            className="login-row"
                          >
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="auth-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Full Name
                                    <span className="astric-sign">*</span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="test"
                                    name="fullName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.fullName}
                                  />
                                  {errors.fullName && touched.fullName && (
                                    <p className="error-text">
                                      {errors.fullName}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="auth-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Phone Number
                                    <span className="astric-sign">*</span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    type="number"
                                    className="comman-form-control"
                                    placeholder=""
                                    name="phoneNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                  />
                                  {errors.phoneNumber &&
                                    touched.phoneNumber && (
                                      <p className="error-text">
                                        {errors.phoneNumber}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="auth-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Email Address
                                    <span className="astric-sign">*</span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                  />
                                  {errors.email && touched.email && (
                                    <p className="error-text">
                                      {errors.email}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="register-content-main">
                        <Index.Typography className="register-title-main">
                          Access credentials
                        </Index.Typography>
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 4 }}
                            className="login-row"
                          >
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="auth-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Create password
                                  </Index.FormHelperText>

                                  <Index.Box className="form-group">
                                    <Index.OutlinedInput
                                      className="comman-form-control"
                                      autocomplete="off"
                                      id="outlined-adornment-password"
                                      type={showPassword ? "text" : "password"}
                                      name="password"
                                      value={values?.password}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      inputProps={{ maxlength: 16 }}
                                      endAdornment={
                                        <Index.InputAdornment position="end">
                                          <Index.IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                          >
                                            {showPassword ? (
                                              <Index.VisibilityOff />
                                            ) : (
                                              <Index.Visibility />
                                            )}
                                          </Index.IconButton>
                                        </Index.InputAdornment>
                                      }
                                    />
                                    {errors.password && touched.password && (
                                      <p className="error-text">
                                        {errors.password}
                                      </p>
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="auth-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Confirm new password
                                  </Index.FormHelperText>

                                  <Index.Box className="form-group">
                                    <Index.OutlinedInput
                                      className="comman-form-control"
                                      autocomplete="off"
                                      id="outlined-adornment-password"
                                      type={
                                        showConfirmPassword
                                          ? "text"
                                          : "password"
                                      }
                                      name="confirmPassword"
                                      value={values?.confirmPassword}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      inputProps={{ maxlength: 16 }}
                                      endAdornment={
                                        <Index.InputAdornment position="end">
                                          <Index.IconButton
                                            aria-label="toggle password visibility"
                                            onClick={
                                              handleClickShowConfirmPassword
                                            }
                                            onMouseDown={
                                              handleMouseDownConfirmPassword
                                            }
                                            edge="end"
                                          >
                                            {showConfirmPassword ? (
                                              <Index.VisibilityOff />
                                            ) : (
                                              <Index.Visibility />
                                            )}
                                          </Index.IconButton>
                                        </Index.InputAdornment>
                                      }
                                    />
                                    {errors.confirmPassword &&
                                      touched.confirmPassword && (
                                        <p className="error-text">
                                          {errors.confirmPassword}
                                        </p>
                                      )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="modal-auth-btn-main">
                      <PagesIndex.OrangeFillButton
                        btnLabel={
                          !isLoading ? (
                            "Submit"
                          ) : (
                            <>
                              <Index.CircularProgress
                                sx={{ color: "white", mr: 1 }}
                                size={24}
                              />
                            </>
                          )
                        }
                        className="orange-fill-btn auth-modal-btn"
                        type="submit"
                        disabled={isLoading}
                        disableRipple
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Form>
            )}
          </Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

import React, { useEffect, useState } from 'react'

import { Outlet } from 'react-router-dom';
import Index from '../../../Index';
import PagesIndex from '../../../PagesIndex';


export default function AdminLayout() {

  const [open, setOpen] = useState(true);

  return (
    <Index.Box className="admin-main">
      <Index.Box className="dashboard-main">
        <Index.Box className={`dashboard-left-main ${open ? "active" : "admin-sidebar-deactive"}`}>
          <PagesIndex.AdminSidebar open={open} setOpen={setOpen} />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <PagesIndex.AdminHeader setOpen={setOpen} open={open} />
          <Index.Box className="dashboard-containt-main">
            <Outlet />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

import React from "react";
import "./settings.css";
import Index from "../../../../Index";
import PagesIndex from "../../../../PagesIndex";
import "./settings.responsive.css";

export default function Settings() {
  return (
    <>
      <Index.Box className="setting-section my-account-sections">
        <Index.Box className="my-address-title-box">
          <Index.Box className="my-address-title-flex">
            <Index.Typography
              className="my-address-title"
              component="h3"
              variant="h3"
            >
              Settings
            </Index.Typography>
            <Index.Box className="my-address-btn-flex">
              <Index.Typography
                className="my-address-change-text"
                component="p"
                variant="p"
              >
                Undo changes
              </Index.Typography>
              <PagesIndex.OrangeFillButton
                btnLabel="Save"
                className="orange-fill-btn my-address-save-btn"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="setting-content-main">
          <Index.Typography
            className="profile-seting-text"
            component="h3"
            variant="h3"
          >
            Profile
          </Index.Typography>
          <Index.Box className="setting-profile-flex">
            <Index.Box className="user-img-main">
              {/* <img src={PagesIndex.Png.user} className='user-img' /> alt="user"*/}
              <Index.Typography
                className="user-name-small"
                component="h3"
                variant="h3"
              >
                AM
              </Index.Typography>
            </Index.Box>
            <Index.Typography
              className="setting-edit-text"
              component="h3"
              variant="h3"
            >
              Edit
            </Index.Typography>
          </Index.Box>
          <Index.Box className="my-setting-content-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box className="setting-row">
                <Index.Box className="grid-column">
                  <Index.Box className="my-setting-input-main">
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Name
                      </Index.FormHelperText>
                      <Index.TextField
                        className="comman-form-control seeting-name-control"
                        placeholder=""
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box className="setting-row">
                <Index.Box className="grid-column">
                  <Index.Box className="my-setting-input-main">
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Email
                      </Index.FormHelperText>
                      <Index.TextField
                        className="comman-form-control"
                        placeholder=""
                      />
                      <Index.Typography
                        className="input-edit-text"
                        component="p"
                        variant="p"
                      >
                        Edit
                      </Index.Typography>
                    </Index.Box>
                    <Index.Typography className="setting-validation-text">
                      We will send you an email to verify your updated email
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="my-setting-input-main">
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Secondary Email
                      </Index.FormHelperText>
                      <Index.TextField
                        className="comman-form-control"
                        placeholder=""
                      />
                      <Index.Typography
                        className="input-edit-text"
                        component="p"
                        variant="p"
                      >
                        Edit
                      </Index.Typography>
                    </Index.Box>
                    <Index.Typography className="setting-validation-text"></Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="my-setting-input-main">
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Phone
                      </Index.FormHelperText>
                      <Index.TextField
                        className="comman-form-control"
                        placeholder=""
                      />
                      <Index.Typography
                        className="input-edit-text"
                        component="p"
                        variant="p"
                      >
                        Edit
                      </Index.Typography>
                    </Index.Box>
                    <Index.Typography className="setting-validation-text">
                      We will send you a code to verify your updated phone
                      number
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="my-setting-input-main">
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Password
                      </Index.FormHelperText>
                      <Index.TextField
                        className="comman-form-control"
                        placeholder=""
                      />
                      <Index.Typography
                        className="input-edit-text"
                        component="p"
                        variant="p"
                      >
                        Edit
                      </Index.Typography>
                    </Index.Box>
                    <Index.Typography
                      className="setting-validation-text"
                      component="p"
                      variant="p"
                    ></Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="seting-account-content-main">
              <Index.Typography
                className="setting-account-text"
                component="h3"
                variant="h3"
              >
                Account
              </Index.Typography>
              <Index.Box className="setting-logout-box">
                <Index.Box className="setting-logout-flex">
                  <Index.Button className="setting-logout-text">
                    Log Out
                    <img
                      src={PagesIndex.Svg.logouticon}
                      className="logout-icon"
                      alt="logout"
                    />
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

import React from 'react'
import Index from '../../Index'
import PagesIndex from '../../PagesIndex'

export default function Breadcrumb() {

  return (
    <Index.Box className="cus-container">
      <Index.Box className="breadcrumb">
        <Index.Link to="/" className='breadcrumb-text'>
          Home
        </Index.Link>
        <img src={PagesIndex.Svg.arrowBackIosNew} alt='Arrow Back Icon' className='breadcrumb-icon' />
        <Index.Typography className='breadcrumb-text'>
          Blogs
        </Index.Typography>
      </Index.Box>
    </Index.Box>
  )
}
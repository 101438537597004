import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'

export default function SellerQuotesShared() {

    return (

        <Index.Box className="seller-quotes-shared-main">
            <Index.TableContainer className="seller-quotes-shared-table-container common-table-container cus-scrollbar">
                <Index.Table aria-label="simple table" className='seller-quotes-shared-table common-table'>
                    <Index.TableHead className='seller-quotes-shared-table-head common-table-head'>
                        <Index.TableRow className='seller-quotes-shared-table-row common-table-row'>
                            <Index.TableCell className='seller-quotes-shared-table-th common-table-th' width="15%" align='left'>Order ID</Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-th common-table-th' width="20%" align='left'>Product Details</Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-th common-table-th' width="15%" align='center'>Date</Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-th common-table-th' width="20%" align='center'>Qty</Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-th common-table-th' width="15%" align='center'>Package value</Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-th common-table-th' width="15%" align='center'>Action</Index.TableCell>
                        </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className='seller-quotes-shared-table-body common-table-body'>
                        <Index.TableRow className='seller-quotes-shared-table-row common-table-row'>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="15%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="20%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quote-be-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Box className='seller-date'>
                                    24/08/2023
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='seller-qty'>
                                    6 X 6 - 50 pipes <br />
                                    Loerm ipsum..
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Box class="bid-value">
                                    INR 6,00,000
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Button className='action-btn'>
                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                </Index.Button>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='seller-quotes-shared-table-row common-table-row'>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="15%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="20%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quote-be-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Box className='seller-date'>
                                    24/08/1523
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='seller-qty'>
                                    6 X 6 - 50 pipes <br />
                                    Loerm ipsum..
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Box class="value-added">
                                    INR 6,00,000
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Button className='action-btn'>
                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                </Index.Button>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='seller-quotes-shared-table-row common-table-row'>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="15%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="20%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quote-be-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Box className='seller-date'>
                                    24/08/1523
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='seller-qty'>
                                    6 X 6 - 50 pipes <br />
                                    Loerm ipsum..
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Box class="bid-value">
                                    INR 6,00,000
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotes-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Button className='action-btn'>
                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                </Index.Button>
                            </Index.TableCell>
                        </Index.TableRow>
                    </Index.TableBody>
                </Index.Table>
            </Index.TableContainer>
        </Index.Box>

    )

}
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { useNavigate } from "react-router-dom";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import moment from "moment";
import { toast } from "react-toastify";
import PagesIndex from "../../../PagesIndex";
import DeleteIcon from "@mui/icons-material/Delete";

const BlogsList = () => {
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);

  // pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const addBlog = () => {
    navigate("/admin/blogs-add");
  };
  const handleView = (row) => {
    navigate("/admin/blogs-view", { state: { row: row } });
  };
  const handleEdit = (row) => {
    navigate("/admin/blogs-add", { state: { row: row } });
  };
  const handleApprove = async (row) => {};
  const handleDelete = async (row) => {
    console.log(row, 56);
    // id,auth,isDeleted
    const data = {
      id: row?._id,
      isDeleted: !row?.isDeleted,
    };
    await DataService.post(Api.Admin.DELETE_BLOG, data)
      .then((res) => {
        console.log(res, 62);
        getBlogList();
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        console.log(error, 64);
      });
  };

  const handleStatus = async (row) => {};

  const getBlogList = async () => {
    await DataService.get(Api.Admin.GET_BLOG)
      .then((res) => {
        console.log(res, 62);
        setRows(res?.data?.data);
      })
      .catch((error) => {
        console.log(error, 64);
      });
  };
  useEffect(() => {
    getBlogList();
  }, [page, rowsPerPage]);

  return (
    <>
      <Index.Box className="flex-all admin-title-box">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            className="admin-sub-title admin-buyer-title"
            component="h3"
            variant="h3"
          >
            Blog List
          </Index.Typography>
        </Index.Box>
        <Index.Box className="view-btn-main border-btn-main">
          <PagesIndex.BlueFillButton
            className="view-order-btn blue-fill-btn"
            btnLabel="Add Blog"
            // onClick={handleOpenAddNewBlogModal}
            onClick={addBlog}
          />
        </Index.Box>
      </Index.Box>
      <Index.Box className="page-buyer-table-main">
        <Index.TableContainer
          component={Index.Paper}
          className="table-container"
        >
          <Index.Table
            sx={{ minWidth: 1000 }}
            aria-label="simple table"
            className="table"
          >
            <Index.TableHead className="table-head">
              <Index.TableRow className="table-row">
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  style={{ fontWeight: 700 }}
                  width="20%"
                >
                  Blog Name
                </Index.TableCell>
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  style={{ fontWeight: 700 }}
                  width="20%"
                >
                  Categories
                </Index.TableCell>
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  style={{ fontWeight: 700 }}
                  width="20%"
                >
                  Tags
                </Index.TableCell>
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  style={{ fontWeight: 700 }}
                  width="20%"
                >
                  Blog Post Date
                </Index.TableCell>
                <Index.TableCell
                  component="th"
                  variant="th"
                  className="table-th"
                  align="center"
                  style={{ fontWeight: 700 }}
                  width="20%"
                >
                  Action
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody className="table-body">
              {rows?.length > 0 ? (
                rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <Index.TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                        width="20%"
                      >
                        {row?.title ? row?.title : "-"}
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                        width="20%"
                      >
                        {row?.categoryId?.map((row, index) => {
                          return <>{row?.title}</>;
                        })}
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                        width="20%"
                      >
                        {row?.tagId?.map((row, index) => {
                          return <>{row?.title}</>;
                        })}
                      </Index.TableCell>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                        width="20%"
                      >
                        {row?.createdAt
                          ? moment(row?.createdAt).format("DD-MM-YYYY HH:mm:ss")
                          : "-"}
                      </Index.TableCell>

                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                        // align="right"
                        align="center"
                        width="20%"
                      >
                        <Index.Box sx={{ padding: "0px", margin: "0px" }}>
                          <Index.IconButton
                            onClick={() => {
                              handleEdit(row);
                            }}
                          >
                            <img
                              src={PagesIndex.Svg.editingIcon}
                              alt="edit icon"
                              width="20"
                              height="20"
                            />
                          </Index.IconButton>
                          <Index.IconButton
                            onClick={() => {
                              handleView(row);
                            }}
                          >
                            <PagesIndex.VisibilityIcon
                              style={{ color: "black" }}
                            />
                          </Index.IconButton>

                          <Index.IconButton
                            onClick={() => {
                              handleDelete(row);
                            }}
                          >
                            <DeleteIcon style={{ color: "black" }} />
                          </Index.IconButton>
                        </Index.Box>
                      </Index.TableCell>
                    </Index.TableRow>
                  ))
              ) : (
                <Index.TableRow>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    scope="row"
                    className="table-td"
                    colSpan={6}
                  >
                    No Record found
                  </Index.TableCell>
                </Index.TableRow>
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
        <Index.TablePagination
          component="div"
          page={page}
          count={rows?.length}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 15, 20]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Index.Box>
    </>
  );
};

export default BlogsList;

import React from 'react'

export default function MyOrderIcons() {
    return (

        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.16667 15.1876V9.47925L2.16667 6.58341V12.2917L7.16667 15.1876ZM8.83333 15.1876L13.8333 12.2917V6.58341L8.83333 9.47925V15.1876ZM7.16667 17.1042L1.33333 13.7501C1.06944 13.5973 0.864583 13.3959 0.71875 13.1459C0.572917 12.8959 0.5 12.6181 0.5 12.3126V5.68758C0.5 5.38203 0.572917 5.10425 0.71875 4.85425C0.864583 4.60425 1.06944 4.40286 1.33333 4.25008L7.16667 0.895915C7.43056 0.743137 7.70833 0.666748 8 0.666748C8.29167 0.666748 8.56944 0.743137 8.83333 0.895915L14.6667 4.25008C14.9306 4.40286 15.1354 4.60425 15.2813 4.85425C15.4271 5.10425 15.5 5.38203 15.5 5.68758V12.3126C15.5 12.6181 15.4271 12.8959 15.2813 13.1459C15.1354 13.3959 14.9306 13.5973 14.6667 13.7501L8.83333 17.1042C8.56944 17.257 8.29167 17.3334 8 17.3334C7.70833 17.3334 7.43056 17.257 7.16667 17.1042ZM11.3333 6.10425L12.9375 5.18758L8 2.33341L6.375 3.27091L11.3333 6.10425ZM8 8.04175L9.625 7.10425L4.6875 4.25008L3.0625 5.18758L8 8.04175Z" fill="#333333" />
        </svg>

    )
}

import React from 'react'
import Index from '../../Index'

function BannerImage({
    bannerImage,
    bannerTitle,
    bannerContent,
}) {
    return (
        (bannerImage || bannerTitle) && (
            <Index.Box component="section" className="banner-image" sx={{ backgroundImage: ` linear-gradient(90deg, rgba(0, 0, 0, 0.81) 6.49%, rgba(0, 0, 0, 0.00) 111.75%), url(${bannerImage})` }}>
                <Index.Box className="cus-container">
                    <Index.Box className="banner-image-content">
                        <Index.Typography variant="h1" component="h1" className="banner-title">
                            {bannerTitle}
                        </Index.Typography>
                        <Index.Typography className="banner-content">
                            {bannerContent}
                        </Index.Typography>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        )
    )
}

export default BannerImage
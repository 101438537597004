import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import './recentBlogs.css'
import './recentBlogs.responsive.css'

export default function RecentBlogs() {

    const recentBlogsList = [
        {
            image: PagesIndex.Jpg.blogImg2,
            title: "Lorem ipsum",
            content: "Lorem ipsum dolor sit amet consectetur. Et nunc viverra tempus leo mauris diam tellus donec.",
        },
        {
            image: PagesIndex.Jpg.blogImg3,
            title: "Lorem ipsum",
            content: "Lorem ipsum dolor sit amet consectetur. Et nunc viverra tempus leo mauris diam tellus donec.",
        },
        {
            image: PagesIndex.Jpg.blogImg4,
            title: "Lorem ipsum",
            content: "Lorem ipsum dolor sit amet consectetur. Et nunc viverra tempus leo mauris diam tellus donec.",
        },
    ]

    return (
        <Index.Box className="recent-blogs">
            <Index.Box className="cus-container">
                <Index.Box className="blogs-title">
                    Blogs
                </Index.Box>
                <Index.Box className="recent-blogs-wrapper">
                    <Index.Box className="recent-blogs-item">
                        <Index.Link to="/blog-details" className='blog-image-box'>
                            <img src={PagesIndex.Jpg.blogImg1} alt='blog post' className='blog-image' />
                        </Index.Link>
                        <Index.Link to="/blog-details" className="blogs-item-title">
                            Lorem ipsum
                        </Index.Link>
                        <Index.Box className="blogs-item-content">
                            Lorem ipsum dolor sit amet consectetur. Et nunc viverra tempus leo mauris diam tellus donec. Lorem ipsum dolor sit amet consectetur.
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="recent-blogs-list">
                        <Index.Box className="blogs-list-title">
                            Recent Blogs
                            <img src={PagesIndex.Svg.keyboardArrowRight} alt='Arrow Back Icon' className='blogs-list-icon' />
                        </Index.Box>
                        <Index.Box className="blogs-list-item-box">
                            {recentBlogsList.map((item, key) => (
                                <Index.Box key={key} className="blogs-list-item">
                                    <img src={item.image} alt='blog post' className='blog-image' />
                                    <Index.Box key={key} className="blogs-list-item-right">
                                        <Index.Box key={key} className="blogs-list-item-title">
                                            {item.title}
                                        </Index.Box>
                                        <Index.Box key={key} className="blogs-list-item-content">
                                            {item.content}
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            ))}
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </Index.Box>
    )

}

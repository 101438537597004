import React from 'react'
import Index from '../../Index';
import PagesIndex from '../../PagesIndex';
import './pageNotFound.css'

export default function PageNotFound() {

  return (
    <Index.Box className="main-page-not-found">
      <Index.Box className="main-content">
        <Index.Box className="page-not-found-wrapper">
          <Index.Box className="page-not-found-img">
            <PagesIndex.ErrorImage />
          </Index.Box>
          <Index.Typography className="page-not-found-subtitle">
            You didn't break the internet, <br/> but we can't find what you are looking for.
          </Index.Typography>
          <Index.Box className="page-not-found-btn-box blue-fill-btn-main">
            <Index.Link to="/" className="blue-fill-btn">
              Back To Home
            </Index.Link>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

import React from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PagesIndex";
import "./userInterface.css";
import "./userInterface.responsive.css";

function Userinterface() {
  return (
    <Index.Box className="user-interface-wrape" component="section">
      <Index.Box className="cus-container">
        <Index.Box className="user-interface-title">
          STELMART USER INTERFACE
        </Index.Box>
        <Index.Box className="user-interface-box-wrapper">
          <Index.Box className="user-interface-box">
            <img src={PageIndex.Png.equity} alt="user-interface" className="user-interface-img" />
            <Index.Box className="bottom-info">
              <Index.Box className="detail">
                Enquiry and quote requests
              </Index.Box>
              <Index.Link to="/coming-soon" className="link">
                Learn more{" "}
                <img
                  src={PageIndex.Svg.bluearrow}
                  className="arrow"
                  alt="arrow-icon"
                />{" "}
              </Index.Link>
            </Index.Box>
          </Index.Box>
          <Index.Box className="user-interface-box">
            <img src={PageIndex.Png.multiple} alt="user-interface" className="user-interface-img" />
            <Index.Box className="bottom-info">
              <Index.Box className="detail">
                Multiple sellers and comparative sheet
              </Index.Box>
              <Index.Link to="/coming-soon" className="link">
                Learn more{" "}
                <img
                  src={PageIndex.Svg.bluearrow}
                  className="arrow"
                  alt="arrow-icon"
                />{" "}
              </Index.Link>
            </Index.Box>
          </Index.Box>
          <Index.Box className="user-interface-box">
            <img src={PageIndex.Png.client} alt="user-interface" className="user-interface-img" />
            <Index.Box className="bottom-info">
              <Index.Box className="detail">
                Client choice and negotiation
              </Index.Box>
              <Index.Link to="/coming-soon" className="link">
                Learn more{" "}
                <img
                  src={PageIndex.Svg.bluearrow}
                  className="arrow"
                  alt="arrow-icon"
                />{" "}
              </Index.Link>
            </Index.Box>
          </Index.Box>
          <Index.Box className="user-interface-box">
            <img src={PageIndex.Png.automated} alt="user-interface" className="user-interface-img" />
            <Index.Box className="bottom-info">
              <Index.Box className="detail">
                Automated communication
              </Index.Box>
              <Index.Link to="/coming-soon" className="link">
                Learn more{" "}
                <img
                  src={PageIndex.Svg.bluearrow}
                  className="arrow"
                  alt="arrow-icon"
                />{" "}
              </Index.Link>
            </Index.Box>
          </Index.Box>
          <Index.Box className="user-interface-box">
            <img src={PageIndex.Png.delivery} alt="user-interface" className="user-interface-img" />
            <Index.Box className="bottom-info">
              <Index.Box className="detail">
                Delivery updates
              </Index.Box>
              <Index.Link to="/coming-soon" className="link">
                Learn more{" "}
                <img
                  src={PageIndex.Svg.bluearrow}
                  className="arrow"
                  alt="arrow-icon"
                />{" "}
              </Index.Link>
            </Index.Box>
          </Index.Box>
          <Index.Box className="user-interface-box">
            <img src={PageIndex.Png.involvment} alt="user-interface" className="user-interface-img" />
            <Index.Box className="bottom-info">
              <Index.Box className="detail">
                Involvements of Banks
              </Index.Box>
              <Index.Link to="/coming-soon" className="link">
                Learn more{" "}
                <img
                  src={PageIndex.Svg.bluearrow}
                  className="arrow"
                  alt="arrow-icon"
                />{" "}
              </Index.Link>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

export default Userinterface
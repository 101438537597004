import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'

export default function SellerLost() {

    return (

        <Index.Box className="seller-lost-main">
            <Index.TableContainer className="seller-lost-container common-table-container cus-scrollbar">
                <Index.Table aria-label="simple table" className='seller-lost common-table'>
                    <Index.TableHead className='seller-lost-head common-table-head'>
                        <Index.TableRow className='seller-lost-row common-table-row'>
                            <Index.TableCell className='seller-lost-table-th common-table-th' width="18%" align='left'>Order ID</Index.TableCell>
                            <Index.TableCell className='seller-lost-table-th common-table-th' width="22%" align='left'>Product Details</Index.TableCell>
                            <Index.TableCell className='seller-lost-table-th common-table-th' width="20%" align='center'>Qty</Index.TableCell>
                            <Index.TableCell className='seller-lost-table-th common-table-th' width="20%" align='center'>Date</Index.TableCell>
                            <Index.TableCell className='seller-lost-table-th common-table-th' width="20%" align='center'>Status</Index.TableCell>
                        </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className='seller-lost-body common-table-body'>
                        <Index.TableRow className='seller-lost-row common-table-row'>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="18%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="22%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='seller-qty'>
                                    6 X 6 - 50 pipes
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="20%" align='center'>
                                <Index.Box class="seller-date">
                                    24/08/2023
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='expried-title-action'>
                                    Somebody got <br/>
                                    the deal
                                </Index.Box>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='seller-lost-row common-table-row'>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="18%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="22%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='seller-qty'>
                                    6 X 6 - 50 pipes
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="20%" align='center'>
                                <Index.Box class="seller-date">
                                    24/08/2023
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='expried-title-action'>
                                    Not <br/>
                                    participated
                                </Index.Box>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='seller-lost-row common-table-row'>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="18%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="22%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='seller-qty'>
                                    6 X 6 - 50 pipes
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="20%" align='center'>
                                <Index.Box class="seller-date">
                                    24/08/2023
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-lost-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='expried-title-action'>
                                    Somebody got <br/>
                                    the deal
                                </Index.Box>
                            </Index.TableCell>
                        </Index.TableRow>
                    </Index.TableBody>
                </Index.Table>
            </Index.TableContainer>
        </Index.Box>

    )

}
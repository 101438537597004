import React, { useState } from 'react'
import Index from '../../../../Index'
import './myOrder.css'
import './myOrder.responsive.css'
import PagesIndex from '../../../../PagesIndex'
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function MyOrder({ handleNumChange }) {

  const [value, setValve] = useState("UTR Number")
  const handleValue = (e) => setValve(e.target.value)

  const [openProof, setOpenProof] = useState(false);
  const handleOpenProof = () => setOpenProof(true);
  const handleCloseProof = () => {
    setOpenProof(false)
    setValve("UTR Number")
  };

  const [openViewPo, setOpenViewPo] = useState(false);
  const handleOpenViewPo = () => setOpenViewPo(true);
  const handleCloseViewPo = () => setOpenViewPo(false);

  const [openProductPreview, setOpenProductPreview] = useState(false);
  const handleOpenProductPreview = () => setOpenProductPreview(true);
  const handleCloseProductPreview = () => setOpenProductPreview(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // RatingAndReviewModal modal
  const [openRatingAndReviewModal, setOpenRatingAndReviewModal] = useState(false);
  const handleOpenRatingAndReviewModal = () => setOpenRatingAndReviewModal(true);
  const handleCloseRatingAndReviewModal = () => setOpenRatingAndReviewModal(false);

  return (
    <>

      <Index.Box className="my-order-table-details">
        <Index.TableContainer className="myorder-table-container common-table-container cus-scrollbar">
          <Index.Table aria-label="simple table" className='myorder-table common-table'>
            <Index.TableHead className='myorder-table-head common-table-head'>
              <Index.TableRow className='myorder-table-row common-table-row'>
                <Index.TableCell className='myorder-table-th common-table-th' width="11%" align="left">Order ID</Index.TableCell>
                <Index.TableCell className='myorder-table-th common-table-th' width="22%" align="left">Product Details</Index.TableCell>
                <Index.TableCell className='myorder-table-th common-table-th' width="15%" align="center">Brand Name</Index.TableCell>
                <Index.TableCell className='myorder-table-th common-table-th' width="18%" align="center">Pending Amount</Index.TableCell>
                <Index.TableCell className='myorder-table-th common-table-th' width="14%" align="center">Payment</Index.TableCell>
                <Index.TableCell className='myorder-table-th common-table-th' width="10%" align="center">Status</Index.TableCell>
                <Index.TableCell className='myorder-table-th common-table-th' width="12%" align="center">Action</Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody className='myorder-table-body common-table-body'>
              <Index.TableRow
                className='myorder-table-row common-table-row'
              >
                <Index.TableCell className='myorder-table-td common-table-td' align="left">
                  <Index.Box className="odrer-id-hash">
                    #1234
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="left">
                  <Index.Box className="order-product-details">
                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="brand-name">
                    TATA Steel
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center" >
                  <Index.Box className="pendding-amount-list">
                    INR <span>8,00,000</span>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="proof-btn-order">
                    <Index.Box className="proof-advance">Advance</Index.Box>
                    <Index.Button component="label" variant="contained" className="upload-file-modal" onClick={handleOpenProof}>
                      <img src={PagesIndex.Svg.uploadfilemodal} className="upload-icon" alt="upload-icon"></img>Upload Proof

                    </Index.Button>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="confirmed-order">
                    Not <br />
                    Confirmed
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="myorder-btn-action">
                    <Index.Button
                      className='action-btn'
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                    </Index.Button>
                    <Index.Menu
                      className='action-btn-menu'
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      transformOrigin={{
                        horizontal: 'center',
                      }}
                    >
                      <Index.MenuItem onClick={handleOpenProductPreview}>Preview</Index.MenuItem>
                      <Index.MenuItem className="view-po-order" onClick={handleOpenViewPo}>View PO</Index.MenuItem>
                      <Index.MenuItem onClick={handleClose}>Cancel</Index.MenuItem>
                    </Index.Menu>
                  </Index.Box>
                </Index.TableCell>
              </Index.TableRow>
              <Index.TableRow
                className='myorder-table-row common-table-row'
              >
                <Index.TableCell className='myorder-table-td common-table-td' align="left">
                  <Index.Box className="odrer-id-hash">
                    #1234
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="left">
                  <Index.Box className="order-product-details">
                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="brand-name">
                    TATA Steel
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center" >
                  <Index.Box className="pendding-amount-list">
                    INR <span>8,00,000</span>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="proof-btn-order">
                    <Index.Box className="proof-advance">Advance</Index.Box>
                    <Index.Button component="label" variant="contained" className="upload-file-modal" onClick={handleOpenProof}>
                      <img src={PagesIndex.Svg.uploadfilemodal} className="upload-icon" alt="upload-icon"></img>Upload Proof

                    </Index.Button>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="confirmed-order order-yellow-text">
                    Confirmation
                    pending
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="myorder-btn-action">
                    <Index.Button
                      className='action-btn'
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                    </Index.Button>
                    <Index.Menu
                      className='action-btn-menu'
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      transformOrigin={{
                        horizontal: 'center',
                      }}
                    >
                      <Index.MenuItem onClick={handleOpenProductPreview}>Preview</Index.MenuItem>
                      <Index.MenuItem className="view-po-order" onClick={handleOpenViewPo}>View PO</Index.MenuItem>
                      <Index.MenuItem onClick={handleClose}>Cancel</Index.MenuItem>
                    </Index.Menu>
                  </Index.Box>
                </Index.TableCell>
              </Index.TableRow>
              <Index.TableRow
                className='myorder-table-row common-table-row'
              >
                <Index.TableCell className='myorder-table-td common-table-td' align="left">
                  <Index.Box className="odrer-id-hash">
                    #1234
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="left">
                  <Index.Box className="order-product-details">
                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="brand-name">
                    TATA Steel
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center" >
                  <Index.Box className="pendding-amount-list">
                    INR <span>8,00,000</span>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="proof-btn-order">
                    <Index.Box className="proof-advance">Advance</Index.Box>
                    <Index.Button component="label" variant="contained" className="upload-file-modal" onClick={handleOpenProof}>
                      <img src={PagesIndex.Svg.uploadfilemodal} className="upload-icon" alt="upload-icon"></img>Upload Proof

                    </Index.Button>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="confirmed-order order-green-text">
                    Confirmed
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="myorder-btn-action">
                    <Index.Button
                      className='action-btn'
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                    </Index.Button>
                    <Index.Menu
                      className='action-btn-menu'
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      transformOrigin={{
                        horizontal: 'center',
                      }}
                    >
                      <Index.MenuItem onClick={handleOpenProductPreview}>Preview</Index.MenuItem>
                      <Index.MenuItem className="view-po-order" onClick={handleOpenViewPo}>View PO</Index.MenuItem>
                      <Index.MenuItem onClick={handleClose}>Cancel</Index.MenuItem>
                    </Index.Menu>
                  </Index.Box>
                </Index.TableCell>
              </Index.TableRow>
              <Index.TableRow
                className='myorder-table-row common-table-row'
              >
                <Index.TableCell className='myorder-table-td common-table-td' align="left">
                  <Index.Box className="odrer-id-hash">
                    #1234
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="left">
                  <Index.Box className="order-product-details">
                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="brand-name">
                    TATA Steel
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center" >
                  <Index.Box className="pendding-amount-list">
                    INR <span>8,00,000</span>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="proof-btn-order">
                    <Index.Box className="proof-advance">Advance</Index.Box>
                    <Index.Button component="label" variant="contained" className="upload-file-modal" onClick={handleOpenProof}>
                      <img src={PagesIndex.Svg.uploadfilemodal} className="upload-icon" alt="upload-icon"></img>Upload Proof

                    </Index.Button>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="confirmed-order">
                    Manufacturing
                    <Index.Link className="track-order-link" onClick={() => handleNumChange("8")} >Track Order</Index.Link>
                  </Index.Box>
                  <Index.Box className="rating-track-order-list">
                    <Index.Box className="ratinig-track-content">
                      <PagesIndex.Rating />
                    </Index.Box>
                    <Index.Box className="rat-box-edit" onClick={handleOpenRatingAndReviewModal}>
                      <Index.Button className="edit-rate-icon"><img src={PagesIndex.Svg.editrat} alt="edit" className="edit-rat"></img></Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.TableCell>
                <Index.TableCell className='myorder-table-td common-table-td' align="center">
                  <Index.Box className="myorder-btn-action">
                    <Index.Button
                      className='action-btn'
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                    </Index.Button>
                    <Index.Menu
                      className='action-btn-menu'
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      transformOrigin={{
                        horizontal: 'center',
                      }}
                    >
                      <Index.MenuItem onClick={handleOpenProductPreview}>Preview</Index.MenuItem>
                      <Index.MenuItem className="view-po-order" onClick={handleOpenViewPo}>View PO</Index.MenuItem>
                      <Index.MenuItem onClick={handleClose}>Cancel</Index.MenuItem>
                    </Index.Menu>
                  </Index.Box>
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box >

      {/* upload payment proof start */}
      <Index.Box className="modal-upload-payment">
        <Index.Modal
          className="modal-common-main modal-proof-details"
          open={openProof}
          onClose={handleCloseProof}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Index.Box sx={style} className="modal-common-style proof-payment-style ">
            <Index.Box className="cus-scrollbar modal-hgt-scroll">
              <Index.Box className="comman-modal-inner-title">
                <Index.Typography component='p' variant='p' className="comman-modal-title">Upload Payment Proof</Index.Typography>
                <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseProof} />
              </Index.Box>
              <Index.Box className="proof-payment-content cus-scrollbar">
                <Index.Box className="select-payment-main">
                  <Index.Typography component='p' variant='p' className='select-method-title'>Select method</Index.Typography>
                  <Index.Box className="select-method-list">
                    <Index.FormControl className="radio-form-control">
                      <Index.RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        className="radio-group-main"
                        value={value}
                        onChange={handleValue}
                      >
                        <Index.FormControlLabel className="form-control-label" value="UTR Number" control={<Index.Radio />} label="UTR Number" />
                        <Index.FormControlLabel className="form-control-label" value="Upload payment image" control={<Index.Radio />} label="Upload payment image" />
                      </Index.RadioGroup>
                    </Index.FormControl>
                  </Index.Box>
                  {/* Radio Show UTR start */}
                  {value === "UTR Number" ? (<Index.Box className="utr-details-show-main">
                    <Index.Box className="utr-content-details">
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className='comman-form-label'>Enter UTR Number</Index.FormHelperText>
                        <Index.TextField className="comman-form-control" placeholder='Enter UTR Number' />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>)
                    :
                    (<Index.Box className="payment-upload-details">
                      <Index.Box className="payment-upload-title">
                        <Index.Typography className="upload-pay-title">Upload payment image</Index.Typography>
                      </Index.Box>
                      <Index.Box className="upload-img-file-modal">
                        <Index.Button component="label" variant="contained" className='upload-label-details' >
                          <Index.Box className="upload-main-content">
                            <img src={PagesIndex.Png.cloud} className='upload-cloud' alt="cloud"></img>
                          </Index.Box>
                          <Index.Typography className="browse-cloud-text">Browse Files to upload</Index.Typography>
                          <Index.Typography className="browse-cloud-accepted">(accepted JPG, PNG & PDF only)</Index.Typography>
                          <VisuallyHiddenInput type="file" />
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="upload-mt-details">
                        <Index.Box className="upload-document-show">
                          <Index.Box className="upload-document-card">
                            <Index.Box className="file-icon-name">
                              <img src={PagesIndex.Png.descriptionupload} className='description-upload' alt="desc"></img>
                              <Index.Typography className="file-payment-name">File 001 Google pay</Index.Typography>
                            </Index.Box>
                            <Index.Button className="btn-delete-file" disableRipple>
                              <img src={PagesIndex.Png.deleteupload} className="delete-details" alt="delete"></img>
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>)}

                  {/* Radio Show UTR end */}

                  {/* Radio show upload payment image start */}

                  {/* Radio show upload payment image end */}

                </Index.Box>
              </Index.Box>
              <Index.Box className="btnupload-payment-footer">
                <Index.Box className="orangefill-btn-confirm btn-comman-mrl">
                  <PagesIndex.OrangeFillButton btnLabel="Confirm" className="orange-fill-btn" />
                </Index.Box>
                <Index.Box className="orange-outline-payment btn-comman-mrl">
                  <PagesIndex.OrangeOutlineButton btnLabel="Cancel" className="orange-outline-inner cancel-payment" />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
      </Index.Box>
      {/* upload payment proof end */}

      {/* View PO Details start */}
      <Index.Box className="modal-viewpo-payment">
        <Index.Modal
          className="modal-common-main modal-viewpo-details"
          open={openViewPo}
          onClose={handleCloseViewPo}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Index.Box sx={style} className="modal-common-style proof-viewpo-style ">
            <Index.Box className="cus-scrollbar modal-hgt-scroll">
              <Index.Box className="comman-modal-inner-title">
                <Index.Typography component='p' variant='p' className="comman-modal-title">View PO Details</Index.Typography>
                <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseViewPo} />
              </Index.Box>
              <Index.Box className="po-details-content">
                <Index.Box className='grid-row'>
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}>
                      <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                        <Index.Box className="common-view-details-group po-view-mb-details">
                          <Index.Typography className='common-label-title'>PO No.</Index.Typography>
                          <Index.Box className="common-view-description-show">
                            <Index.Typography className='common-details-show'>#12562</Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                        <Index.Box className="common-view-details-group po-view-mb-details">
                          <Index.Typography className='common-label-title'>PO Date</Index.Typography>
                          <Index.Box className="common-view-description-show">
                            <Index.Typography className='common-details-show'>20/10/2023</Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                        <Index.Box className="common-view-details-group po-view-mb-details">
                          <Index.Typography className='common-label-title'>Upload PO</Index.Typography>
                        </Index.Box>
                        <Index.Box className="po-file-view-details">
                          <Index.Box className="upload-document-show">
                            <Index.Box className="upload-document-card">
                              <Index.Box className="file-icon-name">
                                <img src={PagesIndex.Png.descriptionupload} className='description-upload' alt="desc"></img>
                                <Index.Typography className="file-payment-name">File 001 Google pay</Index.Typography>
                              </Index.Box>
                              <Index.Button className="btn-delete-file" disableRipple>
                                <img src={PagesIndex.Svg.filedownload} className="download-details" alt="delete"></img>
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="upload-document-show">
                            <Index.Box className="upload-document-card">
                              <Index.Box className="file-icon-name">
                                <img src={PagesIndex.Png.descriptionupload} className='description-upload' alt="desc"></img>
                                <Index.Typography className="file-payment-name">File 001 Google pay</Index.Typography>
                              </Index.Box>
                              <Index.Button className="btn-delete-file" disableRipple>
                                <img src={PagesIndex.Svg.filedownload} className="download-details" alt="delete"></img>
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="btnupload-payment-footer">
                <Index.Box className="orangefill-btn-confirm btn-comman-mrl">
                  <PagesIndex.OrangeFillButton btnLabel="Confirm" className="orange-fill-btn" />
                </Index.Box>
                <Index.Box className="orange-outline-payment btn-comman-mrl">
                  <PagesIndex.OrangeOutlineButton btnLabel="Cancel" className="orange-outline-inner cancel-payment" />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
      </Index.Box>
      {/* View PO Details end */}

      {/* product preview start */}
      <Index.Modal
        className="modal-common-main product-preview-modal"
        open={openProductPreview}
        onClose={handleCloseProductPreview}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box sx={style} className="modal-common-style">
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseProductPreview} />
            <Index.Box className="product-preview-top">
              <img src={PagesIndex.Png.cart} alt='product' className='product-preview-img' />
              <Index.Box className="product-preview-desc">
                <Index.Box className="product-preview-title">
                  Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection
                </Index.Box>
                <Index.Box className="product-preview-content">
                  Discription <br />
                  orem ipsum dolor sit amet consectetur. Amet tincidunt fringilla erat varius massa tempus velit hac dolor. Pellentesque praesent viverra at quis vitae id. Eros ultrices nunc ipsum sit tincidunt nisl egestas nibh. Id enim purus consectetur in
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="product-preview-bottom">
              <Index.Box className="product-preview-detail common-view-details-group">
                <Index.Box className="product-preview-label common-label-title">
                  Enquiry no.
                </Index.Box>
                <Index.Box className="product-preview-label common-details-show">
                  Enquiry no.
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-preview-detail common-view-details-group">
                <Index.Box className="product-preview-label common-label-title">
                  Vendor
                </Index.Box>
                <Index.Box className="product-preview-label common-details-show">
                  TATA steel
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-preview-detail common-view-details-group">
                <Index.Box className="product-preview-label common-label-title">
                  Specifications
                </Index.Box>
                <Index.Box className="product-preview-label common-details-show">
                  Dimensions (6×6)
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-preview-detail common-view-details-group">
                <Index.Box className="product-preview-label common-label-title">
                  Date
                </Index.Box>
                <Index.Box className="product-preview-label common-details-show">
                  24/10/2023
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-preview-detail common-view-details-group">
                <Index.Box className="product-preview-label common-label-title">
                  Payment
                </Index.Box>
                <Index.Box className="product-preview-label common-details-show">
                  Advance
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-preview-detail common-view-details-group">
                <Index.Box className="product-preview-label common-label-title">
                  Status
                </Index.Box>
                <Index.Box className="product-preview-label common-details-show">
                  Confirm
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-preview-detail common-view-details-group">
                <Index.Box className="product-preview-label common-label-title">
                  Quantity
                </Index.Box>
                <Index.Box className="product-preview-label common-details-show">
                  40,000 Kg
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-preview-detail common-view-details-group">
                <Index.Box className="product-preview-label common-label-title">
                  Material
                </Index.Box>
                <Index.Box className="product-preview-label common-details-show">
                  Steel
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-preview-detail common-view-details-group">
                <Index.Box className="product-preview-label common-label-title">
                  PO
                </Index.Box>
                <Index.Box className="product-preview-label common-details-show">
                  <Index.Box className="po-number-listing">PO Number: <span>123456</span></Index.Box>
                  <Index.Box className="po-number-listing">PO Date: <span>Apr 12, 2023</span></Index.Box>
                  <Index.Link className="view-po-order" onClick={handleOpenViewPo}>View PO</Index.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="product-review-grid-6">
              <Index.Box className="product-preview-detail common-view-details-group">
                <Index.Box className="product-preview-label common-label-title">
                  Invoice
                </Index.Box>
                <Index.Box className="product-preview-label common-details-show">
                  <Index.Box className="invoice-details-po">
                    <Index.Box className="invoice-details-content">
                      <Index.Box className="invoice-product-title">Invoice Number : <span>123456</span></Index.Box>
                      <Index.Box className="invoice-product-title">Invoice Date : <span>Apr 12, 2023</span></Index.Box>
                    </Index.Box>
                    <Index.Box className="download-invoice-list">
                      <Index.Typography className='download-invoice-content'>Download invoice </Index.Typography>
                      <Index.Button className='invoice-btn-down'>
                        <img src={PagesIndex.Svg.invoice} className='invoice-img' alt="invoice"></img>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="invoice-details-po">
                    <Index.Box className="invoice-details-content">
                      <Index.Box className="invoice-product-title">Invoice Number : <span>123456</span></Index.Box>
                      <Index.Box className="invoice-product-title">Invoice Date : <span>Apr 12, 2023</span></Index.Box>
                    </Index.Box>
                    <Index.Box className="download-invoice-list">
                      <Index.Typography className='download-invoice-content'>Download invoice </Index.Typography>
                      <Index.Button className='invoice-btn-down'>
                        <img src={PagesIndex.Svg.invoice} className='invoice-img' alt="invoice"></img>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-preview-detail common-view-details-group">
                <Index.Box className="product-preview-label common-label-title">
                  Pending
                </Index.Box>
                <Index.Box className="product-preview-label common-details-show">
                  $ 8,00,000
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      {/* product preview end */}
      <PagesIndex.RatingAndReviewModal openRatingAndReviewModal={openRatingAndReviewModal} handleCloseRatingAndReviewModal={handleCloseRatingAndReviewModal} />

    </>
  )
}

export default MyOrder
import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function EditProduct() {
  return (
    <Index.Box className="edit-product">
      <Index.Box className="flex-all admin-title-box">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            className="admin-sub-title admin-buyer-title"
            component="h3"
            variant="h3"
          >
            Edit Product
          </Index.Typography>
        </Index.Box>
      </Index.Box>
      <Index.Box className="common-grid-main">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 4 }}
            className="login-row"
          >
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Product Name
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <Index.TextField
                    className="comman-form-control"
                    placeholder=""
                    name="productName"
                    value="Mild Steel Sheet"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Quantity
                </Index.FormHelperText>
                <Index.Box className='product-select-qty'>
                  <Index.Button className='product-select-qty-btn'>
                    -
                  </Index.Button>
                  <Index.Input value="20" />
                  <Index.Button className='product-select-qty-btn'>
                    +
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Specification
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <CKEditor
                    editor={ClassicEditor}
                    name="description"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Description
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <CKEditor
                    editor={ClassicEditor}
                    name="description"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Brand
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <PagesIndex.CheckBoxDropdown />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Dimension
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <PagesIndex.CheckBoxDropdown />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Thickness
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <PagesIndex.CheckBoxDropdown />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Type
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <PagesIndex.CheckBoxDropdown />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Grad
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <PagesIndex.CheckBoxDropdown />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Application
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <PagesIndex.CheckBoxDropdown />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Payment
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <PagesIndex.CheckBoxDropdown />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Category
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <PagesIndex.CheckBoxDropdown />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Sub Category
                </Index.FormHelperText>
                <Index.Box className="auth-input-main">
                  <PagesIndex.CheckBoxDropdown />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Images
                </Index.FormHelperText>
                <Index.Box className="common-edit-image-box add-product">
                  <Index.Box className='common-edit-image'>
                    <img src={PagesIndex.Jpg.productImg1} alt='product' className='product-img' />
                    <img src={PagesIndex.Svg.closeicon} className="product-img-close" />
                  </Index.Box>
                  <Index.Box className='common-edit-image'>
                    <img src={PagesIndex.Jpg.productImg1} alt='product' className='product-img' />
                    <img src={PagesIndex.Svg.closeicon} className="product-img-close" />
                  </Index.Box>
                  <Index.Box className='common-edit-image'>
                    <img src={PagesIndex.Jpg.productImg1} alt='product' className='product-img' />
                    <img src={PagesIndex.Svg.closeicon} className="product-img-close" />
                  </Index.Box>
                  <Index.Box className='common-edit-image'>
                    <img src={PagesIndex.Jpg.productImg1} alt='product' className='product-img' />
                    <img src={PagesIndex.Svg.closeicon} className="product-img-close" />
                  </Index.Box>
                  <Index.Box className='common-edit-image'>
                    <img src={PagesIndex.Jpg.productImg1} alt='product' className='product-img' />
                    <img src={PagesIndex.Svg.closeicon} className="product-img-close" />
                  </Index.Box>
                  <Index.Box className="upload-img-file-modal">
                    <Index.Button component="label" variant="contained" className='upload-label-details' >
                      <Index.Box className="upload-main-content">
                        <img src={PagesIndex.Png.cloud} className='upload-cloud' alt="cloud"></img>
                      </Index.Box>
                      <Index.Typography className="browse-cloud-text">Browse Files to upload</Index.Typography>
                      <Index.Typography className="browse-cloud-accepted">(accepted JPG & PNG only)</Index.Typography>
                      <VisuallyHiddenInput type="file" />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="admin-footer-btn-box">
        <PagesIndex.BlueborderButton
          className="cancel-btn blue-border-btn"
          btnLabel="Cancel"
        />
        <PagesIndex.BlueFillButton
          className="view-order-btn blue-fill-btn"
          btnLabel="Save"
        />
      </Index.Box>
    </Index.Box >
  )
}

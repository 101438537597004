import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useLocation } from "react-router-dom";

// for custom checkbox design

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 0,
  border: "1px solid #114A65",
  width: 16,
  height: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function Login() {
  // for password eye hide and show
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = useLocation()
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  let initialValues = {
    email: "",
    password: "",
    isLoginPage: true,
    isSubmit: false,
    remember: false,
    remember: localStorage.getItem
      ? localStorage.getItem("remember") === "true"
      : false,
  };
  const handleSubmit = (values, { setFieldValue }) => {
    console.log(values, 12);
    setFieldValue("isSubmit", true);
    // remember me
    localStorage.setItem("remember", values.remember);
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", values.email);
    urlencoded.append("password", values.password);
    dispatch(PagesIndex.adminLogin(urlencoded)).then((res) => {
      console.log(res, 566);
      if (res?.payload?.status == 200) {
        setTimeout(() => {
          if (location?.pathname.includes("/admin/dev")) {
            navigate("/admin/dev/dashboard");
          } else {
            navigate("/admin/dashboard");
          }
        }, 1000);
      } else {
        setTimeout(() => setFieldValue("isSubmit", false), 3000);
      }
    });
  };

  return (
    <Index.Box className="admin-main">
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <PagesIndex.AuthBackground />
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Login
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  Please enter your credentials to Login
                </Index.Typography>
                <PagesIndex.Formik
                  initialValues={initialValues}
                  validationSchema={PagesIndex.adminLoginValidationSchema(
                    initialValues
                  )}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                  }) => (
                    <PagesIndex.Form>
                      {console.log(errors, 45)}
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Email
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            // type="email"
                            type="text"
                            className="form-control"
                            // placeholder="admin@mealon.com"
                            name="email"
                            value={values?.email}
                            // onChange={handleChange}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /\s+/g,
                                ""
                              );
                              setFieldValue("email", newValue);
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.email && touched.email && (
                            <p className="error-text">{errors.email}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.OutlinedInput
                            className="form-control-eye"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={values?.password}
                            // onChange={handleChange}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /\s+/g,
                                ""
                              );
                              setFieldValue("password", newValue);
                            }}
                            onBlur={handleBlur}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                          {errors.password && touched.password && (
                            <p className="error-text">{errors.password}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="flex-all forgot-row">
                        {/* <Index.Box className="checkbox-main">
                          <BpCheckbox />
                          <Index.Typography className="checkbox-lable">
                            Remember Me
                          </Index.Typography>
                         
                        </Index.Box> */}
                        <Index.Box className="checkbox-main">
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Remember Me"
                            checked={values.remember}
                            onChange={handleChange}
                            name="remember"
                          />
                          {errors.remember && touched.remember && (
                            <p className="error-text">{errors.remember}</p>
                          )}
                        </Index.Box>
                        <Index.Link
                          className="text-decoration-none admin-forgot-para common-para"
                          to="/admin/forgot-password"
                        >
                          Forgot Password?
                        </Index.Link>
                      </Index.Box>
                      {/* <Index.Box className="btn-main-primary login-btn-main">
                  <PagesIndex.BlueFillButton className="btn-primary login-btn" btnLabel=" Sign In"/>
                   
                  
                </Index.Box> */}
                      <Index.Box className="view-btn-main border-btn-main login-btn-main">
                        <PagesIndex.BlueFillButton
                          className={
                            values?.isSubmit
                              ? "view-order-btn blue-fill-btn login-btn disabled-btn disabled"
                              : "view-order-btn blue-fill-btn login-btn"
                          }
                          btnLabel="Login"
                          type="submit"
                          disabled={values?.isSubmit}
                          startIcon={
                            values?.isSubmit && (
                              <Index.CircularProgress
                                size={15}
                                color="inherit"
                              />
                            )
                          }
                        />
                      </Index.Box>
                    </PagesIndex.Form>
                  )}
                </PagesIndex.Formik>
                <Index.Box className="or-text-main">
                  <Index.Typography className="or-cocntent-text" component="p">
                    OR
                  </Index.Typography>
                </Index.Box>
                {/* <PagesIndex.AuthFooter /> */}
                <Index.Box className="condition-content">
                  <Index.Typography className="condition-text">
                    {" "}
                    Don't have an account?{" "}
                    <span className="condition-span">
                      <Index.Link className="condition-span">
                        Sign Up here
                      </Index.Link>
                    </span>
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

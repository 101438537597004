import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function BannerManagement() {

  return (
    <>
      <Index.Box className="banner-management">
        <Index.Box className="flex-all admin-title-box">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title admin-buyer-title"
              component="h3"
              variant="h3"
            >
              Banner Management
            </Index.Typography>
          </Index.Box>
          <Index.Box className="view-btn-main border-btn-main blue-fill-btn-main">
            <Index.Button
              className="view-order-btn blue-fill-btn"
              component="label"
            >
              Browse
              <VisuallyHiddenInput type="file" />
            </Index.Button>
          </Index.Box>
        </Index.Box>
        <Index.Box className="page-order-table-main">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container cus-scrollbar"
          >
            <Index.Table
              sx={{ minWidth: 700 }}
              aria-label="simple table"
              className="table"
            >
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="15%"
                  >
                    Sr. No.
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="35%"
                  >
                    Image
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="25%"
                  >
                    Priority
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    align="center"
                    style={{ fontWeight: 700 }}
                    width="25%"
                  >
                    Action
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody className="table-body">
                <Index.TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <Index.TableCell
                    component="td"
                    variant="td"
                    scope="row"
                    className="table-td"
                  >
                    1
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    <Index.Box className="common-table-img-box">
                      <img src={PagesIndex.Jpg.slide1} alt="banner" className="common-table-img" />
                    </Index.Box>
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    scope="row"
                    className="table-td"
                  >
                    <PagesIndex.DropDown />
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                    align="center"
                  >
                    <Index.Box sx={{ padding: "0px", margin: "0px" }}>
                      <Index.IconButton sx={{ width: "40px" }} className="toggle-btn" disableRipple>
                        <Index.Switch />
                      </Index.IconButton>
                    </Index.Box>
                  </Index.TableCell>
                </Index.TableRow>
                <Index.TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <Index.TableCell
                    component="td"
                    variant="td"
                    scope="row"
                    className="table-td"
                  >
                    2
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    <Index.Box className="common-table-img-box">
                      <img src={PagesIndex.Jpg.slide2} alt="banner" className="common-table-img" />
                    </Index.Box>
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    scope="row"
                    className="table-td"
                  >
                    <PagesIndex.DropDown />
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                    align="center"
                  >
                    <Index.Box sx={{ padding: "0px", margin: "0px" }}>
                      <Index.IconButton sx={{ width: "40px" }} className="toggle-btn" disableRipple>
                        <Index.Switch />
                      </Index.IconButton>
                    </Index.Box>
                  </Index.TableCell>
                </Index.TableRow>
                <Index.TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <Index.TableCell
                    component="td"
                    variant="td"
                    scope="row"
                    className="table-td"
                  >
                    3
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    <Index.Box className="common-table-img-box">
                      <img src={PagesIndex.Jpg.slide3} alt="banner" className="common-table-img" />
                    </Index.Box>
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    scope="row"
                    className="table-td"
                  >
                    <PagesIndex.DropDown />
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                    align="center"
                  >
                    <Index.Box sx={{ padding: "0px", margin: "0px" }}>
                      <Index.IconButton sx={{ width: "40px" }} className="toggle-btn" disableRipple>
                        <Index.Switch />
                      </Index.IconButton>
                    </Index.Box>
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
          <Index.TablePagination
            className="cus-scrollbar"
            component="div"
          />
        </Index.Box>
      </Index.Box>
    </>
  );
}

import React from 'react'
import Index from '../../Index';
import PagesIndex from '../../PagesIndex';
import './careerModal.css'
import './careerModal.responsive.css'
import { styled } from '@mui/material/styles';

// for modal design 

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function CareerModal(props) {

  const { openCareerModal, handleCloseCareerModal } = props

  return (
    <>
      <Index.Modal
        open={openCareerModal}
        onClose={handleCloseCareerModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='modal-common-main'
      >
        <Index.Box sx={style} className="contact-modal-inner-main modal-common-style">
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <Index.Typography className='contact-modal-header-title' component="h3" variant="h3" >Contact us</Index.Typography>
              <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseCareerModal} />
            </Index.Box>
            <Index.Box className="modal-body">
              <Index.Box className="contact-content-inner">
                <Index.Typography className='contact-modal-para' component="p" variant="p">You can directly call us on +91 7589025567 or drop a message. We will revert to your issue shortly.</Index.Typography>
                <Index.Box className="contact-content-main">
                  <Index.Box className="contact-content-box">
                    <Index.Box className="contact-content-flex comman-form-group">
                      <Index.Box className="contact-content-left-main">
                        <Index.FormHelperText className='comman-form-label'>Full Name<span className='astric-sign'>*</span></Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="contact-content-right-main">
                        <Index.Box className="contact-input-main">
                          <Index.Box className="comman-form-group">
                            <Index.TextField className="comman-form-control" placeholder='e.g., John' />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="contact-content-box">
                    <Index.Box className="contact-content-flex comman-form-group">
                      <Index.Box className="contact-content-left-main">
                        <Index.FormHelperText className='comman-form-label'>Email<span className='astric-sign'>*</span></Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="contact-content-right-main">
                        <Index.Box className="contact-input-main">
                          <Index.Box className="comman-form-group">
                            <Index.TextField className="comman-form-control" placeholder='name@company.com' />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="contact-content-box">
                    <Index.Box className="contact-content-flex comman-form-group">
                      <Index.Box className="contact-content-left-main">
                        <Index.FormHelperText className='comman-form-label'>Phone Number<span className='astric-sign'>*</span></Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="contact-content-right-main">
                        <Index.Box className="contact-input-main">
                          <Index.Box className="comman-form-group">
                            <Index.TextField className="comman-form-control" placeholder='+1 555 655 5656' />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="contact-content-box">
                    <Index.Box className="contact-content-flex comman-form-group">
                      <Index.Box className="contact-content-left-main">
                        <Index.FormHelperText className='comman-form-label'>Upload your resume<span className='astric-sign'>*</span></Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="contact-content-right-main">
                        <Index.Box className="upload-img-file-modal">
                          <Index.Button component="label" variant="contained" className='upload-label-details' >
                            <Index.Box className="upload-main-content">
                              <img src={PagesIndex.Png.cloud} className='upload-cloud' alt="cloud"></img>
                            </Index.Box>
                            <Index.Typography className="browse-cloud-text">Browse Files to upload</Index.Typography>
                            <Index.Typography className="browse-cloud-accepted">(accepted JPG, PNG & PDF only)</Index.Typography>
                            <VisuallyHiddenInput type="file" />
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="contact-content-box">
                    <Index.Box className="contact-content-flex comman-form-group">
                      <Index.Box className="contact-content-left-main"></Index.Box>
                      <Index.Box className="contact-content-right-main">
                        <Index.Box className="contact-submit-btn-main">
                          <PagesIndex.BlueFillButton className='blue-fill-btn' btnLabel="Submit" />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal >
    </>
  )
}

import Jpg from "../assets/Jpg";
import Png from "../assets/Png";
import Svg from '../assets/Svg';
import Header from './user/defaulLayout/Header'
import Footer from './user/defaulLayout/Footer'
import Loader from "./common/loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import BlueFillButton from "./common/buttons/BlueFillButton";
import BlueborderButton from "./common/buttons/BlueborderButton";
import CheckBoxDropdown from "./common/dropdown/CheckBoxDropdown";
import ProductCard from "./common/productCard/ProductCard";
import CatalogueCard from "./common/catalogueCard/CatalogueCard";
import OrangeFillButton from "./common/buttons/OrangeFillButton";
import GrayFillButton from "./common/buttons/GrayFillButton";
import DropDown from "./common/dropdown/DropDown";
import YouMayLike from "./common/youmaylike/YouMayLike";
import OrangeOutlineButton from "./common/buttons/OrangeOutlineButton";
import MyAccountContent from "./user/pages/myaccount/myaccountcontent/MyAccountContent";
import VerifyAccountModal from "./user/pages/myaccount/verifyaccountmodal/VerifyAccountModal";
import BusinessDetails from "./user/pages/myaccount/businessdetails/BusinessDetails";
import MyAddress from "./user/pages/myaccount/myaddress/MyAddress";
import Settings from "./user/pages/myaccount/settings/Settings";
import AnalyticsDashboard from "./user/pages/dashboard/dashboardanalytics/AnalyticsDashboard";
import Stepper from "./common/stepper/Stepper";
import AnalyticIcons from "./icons/AnalyticIcons";
import MyOrderIcons from "./icons/MyOrderIcons";
import MailIcons from "./icons/MailIcons";
import ErrorImage from "./icons/ErrorImage";
import Rating from "./common/rating/Rating";
import Login from "./user/auth/Login";
import Register from "./user/auth/Register";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "./user/auth/ForgotPassword";
import ResetPassword from "./user/auth/ResetPassword";
import UserOtp from "./user/auth/UserOtp";
import RatingAndReviewModal from "./common/ratingandreviewmodal/RatingAndReviewModal";
import ContactUsModal from "./common/contactusmodal/ContactUsModal";
import CareerModal from "./common/careerModal/CareerModal";
import SellerVerifyAccountModal from "./seller/pages/sellerMyAccount/sellerVerifyAccountModal/SellerVerifyAccountModal";

export default {
      Jpg,
      Png,
      Svg,
      Header,
      Footer,
      Loader,
      Swiper,
      SwiperSlide,
      Navigation,
      Pagination,
      Autoplay,
      BlueborderButton,
      BlueFillButton,
      CheckBoxDropdown,
      ProductCard,
      CatalogueCard,
      OrangeFillButton,
      GrayFillButton,
      DropDown,
      YouMayLike,
      OrangeOutlineButton,
      AnalyticsDashboard,
      MyAccountContent,
      VerifyAccountModal,
      BusinessDetails,
      MyAddress,
      Settings,
      Stepper,
      AnalyticIcons,
      MyOrderIcons,
      MailIcons,
      Rating,
      Login,
      Register,
      useDispatch,
      useNavigate,
      ForgotPassword,
      ResetPassword,
      UserOtp,
      RatingAndReviewModal,
      ContactUsModal,
      CareerModal,
      SellerVerifyAccountModal,
      ErrorImage,
};

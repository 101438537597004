import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function QueryManagement() {

  const [openQueryReply, setOpenQueryReply] = useState(false);
  const handleOpenQueryReply = () => setOpenQueryReply(true);
  const handleCloseQueryReply = () => setOpenQueryReply(false);

  return (
    <>
      <Index.Box className="query-management">
        <Index.Box className="flex-all admin-title-box">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title admin-buyer-title"
              component="h3"
              variant="h3"
            >
              Query Management
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="page-order-table-main">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container cus-scrollbar"
          >
            <Index.Table
              sx={{ minWidth: 1010 }}
              aria-label="simple table"
              className="table"
            >
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="8%"
                  >
                    Sr. No.
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="15%"
                  >
                    Name
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="24%"
                  >
                    Email ID
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="14%"
                  >
                    Phone Number
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="12%"
                  >
                    Date / Time
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="17%"
                  >
                    Query
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    align="center"
                    style={{ fontWeight: 700 }}
                    width="10%"
                  >
                    Action
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody className="table-body">
                <Index.TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <Index.TableCell
                    component="td"
                    variant="td"
                    scope="row"
                    className="table-td"
                  >
                    1
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    Anil Chouhan
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    anilchouhan123.vhits@gmail.com
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    8464548754
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    18-12-2023 16:32:55
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    <Index.Box className="common-table-para">
                      Mechanical tubing seems to be following a similar trend to structural tubing. Drawn Over Mandrel (DOM), Cold Drawn Seamless (CDS) and Hot Rolled Seamless (HRS) tubing are all ticking upward.
                    </Index.Box>
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                    align="center"
                  >
                    <Index.IconButton onClick={handleOpenQueryReply}>
                      <img
                        src={PagesIndex.Svg.replyIcon}
                        alt="reply icon"
                        width="30"
                        height="30"
                      />
                    </Index.IconButton>
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
          <Index.TablePagination
            className="cus-scrollbar"
            component="div"
          />
        </Index.Box>
      </Index.Box>
      <Index.Modal
        className="modal-common-main modal-viewpo-details"
        open={openQueryReply}
        onClose={handleCloseQueryReply}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box sx={style} className="modal-common-style proof-viewpo-style ">
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseQueryReply} />
            <Index.Box className="ra-comment-preview">
              <Index.Box className="textarea-common-control">
                <Index.TextareaAutosize className='common-textarea-autosize' minRows={8} placeholder='Add a comment...' />
              </Index.Box>
            </Index.Box>
            <Index.Box className="btnupload-payment-footer">
              <PagesIndex.BlueborderButton
                className="cancel-btn blue-border-btn btn-comman-mrl"
                btnLabel="Cancel"
                onClick={handleCloseQueryReply}
              />
              <PagesIndex.BlueFillButton
                className="view-order-btn blue-fill-btn btn-comman-mrl"
                btnLabel="Send"
                type="submit"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

import React, { useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import "./auth.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ForgotPassword({
  openForgotUser,
  handleCloseForgotUser,
}) {
  const [openUserOtp, setOpenUserOtp] = React.useState(false);
  const handleOpenUserOtp = () => setOpenUserOtp(true);
  const handleCloseUserOtp = () => setOpenUserOtp(false);
  // ForgotUser modal
  // const [openForgotUser, setOpenForgotUser] = React.useState(false);
  // const handleOpenForgotUser = () => setOpenForgotUser(true);
  // const handleCloseForgotUser = () => setOpenForgotUser(false);
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter your valid email")
      .matches(
        /^[a-z][a-z0-9]*(?:\.[a-z]+)*@[a-z]+\.[a-z]{2,}$/,
        "Please enter your valid email"
      )
      .required("Please enter your email address"),
  });
  const handleFormSubmit = async (values) => {
    // handleOpenUserOtp();
    console.log(values, 43);
    await DataService.post(Api.User.Auth.FORGOT_PASSWORD, values)
      .then((res) => {
        console.log(res?.data, 722);
        toast.success(res?.data?.message);
        if (res?.data?.status == 200) {
          handleOpenUserOtp();
          
          // setState({ ...state, email: values.email });
          // {state:{email:values}}
        }
      })
      .catch((errors) => {
        console.log(errors, 74);
        toast.error(errors?.response?.data?.message);
      });
  };
  return (
    <>
      {/* <PagesIndex.OrangeFillButton btnLabel="Go to cart" onClick={handleOpenForgotUser} className="orange-fill-btn go-tocart-btn" /> */}
      <Index.Modal
        open={openForgotUser}
        onClose={handleCloseForgotUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="login-modal-inner-main auth-modal-inner modal-common-style"
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Index.Box className="cus-scrollbar modal-hgt-scroll">
                  <Index.Box className="modal-header">
                    <Index.Typography className="auth-modal-title">
                      Forgot Password
                    </Index.Typography>
                    <img
                      src={PagesIndex.Svg.closeicon}
                      className="modal-close-icon"
                      onClick={handleCloseForgotUser}
                    />
                  </Index.Box>
                  <Index.Box className="modal-body">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xxs: 2, xs: 2, sm: 2, md: 3, lg: 4 }}
                        className="login-row"
                      >
                        <Index.Box
                          gridColumn={{
                            xxs: "span 12",
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="auth-input-main">
                            <Index.Box className="comman-form-group">
                              {/* <Index.TextField
                          className="comman-form-control"
                          placeholder="Email or Phone"
                        /> */}
                              <Index.TextField
                                className="comman-form-control"
                                placeholder="Email or Phone"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                // helperText={touched.email && errors.email}
                                // error={Boolean(errors.email && touched.email)}
                              />
                              {errors.email && touched.email && (
                                <p className="error-text">{errors.email}</p>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-footer">
                    <Index.Box className="forgot-btn-footer">
                      <Index.Box className="modal-auth-btn-main">
                        <PagesIndex.OrangeFillButton
                          btnLabel="Submit"
                          className="orange-fill-btn auth-modal-btn"
                          //   onClick={() => {
                          //     // handleOpenUserOtp();
                          //     // handleCloseForgotUser();
                          //   }}
                          type="submit"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <PagesIndex.UserOtp
                  openUserOtp={openUserOtp}
                  email={values}
                  handleCloseUserOtp={handleCloseUserOtp}
                  handleCloseForgotUser={handleCloseForgotUser}
                />
              </Form>
            )}
          </Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

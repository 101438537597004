import React from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PagesIndex";
import "./aboutSection.css";
import "./aboutSection.responsive.css";

function aboutSection() {
  return (
    <Index.Box className="about-us-wrape" component="section">
      <Index.Box className="cus-container">
        <Index.Box className="grid inner-wrape">
          <Index.Box className="left-section">
            <Index.Typography component="h2">
              About Stelmart{" "}
              <img src={PageIndex.Svg.whitelongarrow} className="arrow" />
            </Index.Typography>
            <Index.Typography component="p">
              The digital platform that revolutionizes the steel industry
            </Index.Typography>
          </Index.Box>
          <Index.Box className="right-section">
            <Index.Typography component="p">
              Our digital application is designed to make the purchasing and exploration of steel and polymer products easier and more convenient for individuals and businesses alike.
            </Index.Typography>
            <Index.Typography component="p">
              At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.
            </Index.Typography>
            <Index.Link className="link" to="/coming-soon">
              Learn more <img src={PageIndex.Svg.bluearrow} />
            </Index.Link>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

export default aboutSection

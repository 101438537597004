// service
import { createAsyncThunk } from "@reduxjs/toolkit";
import PageIndex from "../../../../container/PagesIndex";

export const adminLogin = createAsyncThunk("admin/adminLogin", async (data) => {
  try {
    const response = await PageIndex.doPost(
      PageIndex.Api.Admin.Auth.adminLogin,
      data
    );
    console.log(response,789)

    return response.data;
  } catch (error) {}
});
 
export const adminForgotPassword = createAsyncThunk(
  "admin/adminForgotPassword",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.Admin.Auth.forgotPassword,
        data
      );
      console.log(response)
      return response.data;
    } catch (error) {}
  }
);

export const adminVerifyOtp = createAsyncThunk(
  "admin/adminVerifyOtp",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.Admin.Auth.verifyOtp,
        data
      );
      return response.data;
    } catch (error) {}
  }
);
export const adminResetPassword = createAsyncThunk(
  "admin/adminResetPassword",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.Admin.Auth.resetPassword,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

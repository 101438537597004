import React from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PagesIndex";
import './applicationwork.css'
import './applicationwork.responsive.css'

function Applicationwork() {
  return (
    <Index.Box className="app-work-wrape" component="section">
      <Index.Box className="cus-container">
        <Index.Typography component="h4">
          How does the stelmart application work
        </Index.Typography>
      </Index.Box>
      <Index.Box className="flex-section">
        <Index.Box className="up-wrape">
          <Index.Box className="sec first">
            <img src={PageIndex.Svg.howto} alt="" />
            <Index.Typography component="h6">Registration</Index.Typography>
            <Index.Typography component="p">
              Users download the app, register, and set up their profiles.
            </Index.Typography>
          </Index.Box>

          <Index.Box className="sec second">
            <img src={PageIndex.Svg.exportnote} alt="" />
            <Index.Typography component="h6">Inquiries</Index.Typography>
            <Index.Typography component="p">
              Buyers send product inquiries or quote requests to sellers.
            </Index.Typography>
          </Index.Box>

          <Index.Box className="sec third">
            <img src={PageIndex.Svg.partnerexchange} />
            <Index.Typography component="h6">Negotiation</Index.Typography>
            <Index.Typography component="p">
              Buyers and sellers can negotiate terms if necessary
            </Index.Typography>
          </Index.Box>

          <Index.Box className="sec fourth">
            <img src={PageIndex.Svg.route} />
            <Index.Typography component="h6">
              Order Tracking
            </Index.Typography>
            <Index.Typography component="p">
              Real-time order tracking keeps buyers informed
            </Index.Typography>
          </Index.Box>

          <Index.Box className="sec fifth">
            <img src={PageIndex.Svg.verifyblack} />
            <Index.Typography component="h6">
              Order complete
            </Index.Typography>
            <Index.Typography component="p">
              Order successfully delivered
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="middle-wrape">
          <img src={PageIndex.Svg.appwork} />
        </Index.Box>
        <Index.Box className="down-wrape">
          <Index.Box className="sec first">
            <img src={PageIndex.Svg.mystery} />
            <Index.Typography component="h6">Browse</Index.Typography>
            <Index.Typography component="p">
              Buyers explore the catalogue, while sellers list their products
            </Index.Typography>
          </Index.Box>

          <Index.Box className="sec second">
            <img src={PageIndex.Svg.speaker} />
            <Index.Typography component="h6">Quotes</Index.Typography>
            <Index.Typography component="p">
              Sellers respond with quotes, and buyers can compare multiple offers
            </Index.Typography>
          </Index.Box>

          <Index.Box className="sec third">
            <img src={PageIndex.Svg.game} />
            <Index.Typography component="h6">Payment & Banking Assistance</Index.Typography>
            <Index.Typography component="p">
              Buyers securely complete payments within the app / using options offered by banks
            </Index.Typography>
          </Index.Box>

          <Index.Box className="sec fourth">
            <img src={PageIndex.Svg.delivery} />
            <Index.Typography component="h6">
              Delivery Updates
            </Index.Typography>
            <Index.Typography component="p">
              Sellers provide delivery status updates
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box >
  )
}

export default Applicationwork
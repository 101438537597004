import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
// import Store from './redux/Store';
import { persistor, store } from "./redux/app/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from 'react-toastify';
// import { PersistGate } from 'redux-persist/integration/react';
// const persistStore = Store();
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <React.StrictMode>
   <ToastContainer />
   <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      {/* </PersistGate> */}
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


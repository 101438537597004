import Routers from './routes/Routes';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import './assets/style/style.css';
import './assets/style/responsive.css';
import './component/user/defaulLayout/defaultLayout.css'
import './component/user/defaulLayout/defaultLayout.responsive.css'
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './component/admin/defaultlayout.css'
import './container/admin/pages/dashboard/admindashboard.css'
import './container/admin/pages/dashboard/admindashboard.responsive.css'
import './container/admin/auth/auth.css'
import './container/admin/auth/auth.responsive.css'
import './assets/style/admin.css'
import './assets/style/admin.responsive.css'
function App() {

  {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Inter, sans-serif",
        fontWeight: "400",
        lineHeight: "normal",
      },
    },
    breakpoints: {
      values: {
        xxs: 0,
        xs: 375,
        sm: 550,
        md: 768,
        lg: 1024,
        xl: 1250,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routers />
      </div>
    </ThemeProvider>
  );
}

export default App;

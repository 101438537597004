import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import './theTeam.css'
import './theTeam.responsive.css'

export default function TheTeam() {
    const theTeamItem = [
        {
            name: "Shankar Sundaraman",
            designation: "CEO",
        },
        {
            name: "Rahul Sharma",
            designation: "COO",
        },
        {
            name: "Pradeep Thakur",
            designation: "Finance",
        },
        {
            name: "Jade Warren",
            designation: "Founder and CEO",
        },
        {
            name: "Jade Warren",
            designation: "Founder and CEO",
        },
    ]
    return (
        <Index.Box className="cus-container" component="section">
            <Index.Box className="the-team">
                <Index.Box className="the-team-left">
                    <Index.Box className="the-team-title">
                        The A Team
                    </Index.Box>
                    <Index.Box className="the-team-name-box">
                        {theTeamItem.map((item, key) => (
                            <Index.Box key={key} className="the-team-name-item">
                                <Index.Box className="the-team-name">
                                    {item.name}
                                </Index.Box>
                                <Index.Box className="the-team-designation">
                                    {item.designation}
                                </Index.Box>
                            </Index.Box>
                        ))}
                    </Index.Box>
                </Index.Box>
                <Index.Box className="the-team-right">
                    <Index.Box className="the-team-img-top">
                        <Index.Box className="the-team-img-top-left">
                            <img src={PagesIndex.Jpg.teamImg1} alt='team member' className='team-member-img1 team-member-img' />
                            <img src={PagesIndex.Jpg.teamImg2} alt='team member' className='team-member-img2 team-member-img' />
                        </Index.Box>
                        <Index.Box className="the-team-img-top-right">
                            <img src={PagesIndex.Jpg.teamImg3} alt='team member' className='team-member-img3 team-member-img' />
                            <img src={PagesIndex.Jpg.teamImg4} alt='team member' className='team-member-img4 team-member-img' />
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="the-team-img-bottom">
                        <img src={PagesIndex.Jpg.teamImg5} alt='team member' className='team-member-img5 team-member-img' />
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </Index.Box>
    )
}
import React, { useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import { Form, Formik } from "formik";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
import { toast } from "react-toastify";
import * as Yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function EditBuyer(props) {
  const { openEditBuyerModal, handleCloseEditBuyerModal, rows, getBuyerList } =
    props;
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    fullName: rows ? rows?.fullName : "",
    phoneNumber: rows ? rows?.phoneNumber : "",
    email: rows ? rows?.email : "",
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .matches(/^[a-zA-Z\s-, ]+$/, "Accept only alphabetic and white space")
      .required("Please enter your full name"),
  });
  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    console.log(values, "values");
    const data = {
      fullName: values.fullName,
    };
    await DataService.post(`${Api.Admin.UPDATE_BUYER}/${rows?._id}`, data)
      .then((res) => {
        setIsLoading(false);
        console.log(res, "res85");
        toast.success(res?.data?.message);
        if (res?.data?.status === 200) {
          getBuyerList();
          handleCloseEditBuyerModal();
        }
      })
      .catch((error) => {
        console.log(error, "res225");
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Index.Modal
        open={openEditBuyerModal}
        onClose={handleCloseEditBuyerModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="register-modal-inner-main auth-modal-inner modal-common-style"
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={validationSchema}
          >
            {({
              handleSubmit,
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Index.Box className=" cus-scrollbar modal-hgt-scroll">
                  <Index.Box className="modal-header">
                    <Index.Typography className="auth-modal-title">
                      Edit buyer
                    </Index.Typography>
                    <img
                      src={PagesIndex.Svg.closeicon}
                      className="modal-close-icon"
                      onClick={handleCloseEditBuyerModal}
                    />
                  </Index.Box>
                  <Index.Box className="modal-body">
                    <Index.Box className="auth-content-main">
                      <Index.Box className="register-content-main">
                        <Index.Typography className="register-title-main">
                          Contact Information
                        </Index.Typography>
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                            className="login-row"
                          >
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="auth-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Full Name
                                    <span className="astric-sign">*</span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="test"
                                    name="fullName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.fullName}
                                  />
                                  {errors.fullName && touched.fullName && (
                                    <p className="error-text">
                                      {errors.fullName}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="auth-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Phone Number
                                    <span className="astric-sign">*</span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    type="number"
                                    className="comman-form-control"
                                    placeholder=""
                                    name="phoneNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    disabled={rows}
                                  />
                                  {errors.phoneNumber &&
                                    touched.phoneNumber && (
                                      <p className="error-text">
                                        {errors.phoneNumber}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="auth-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Email Address
                                    <span className="astric-sign">*</span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    disabled={rows}
                                  />
                                  {errors.email && touched.email && (
                                    <p className="error-text">
                                      {errors.email}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="modal-auth-btn-main">
                      <PagesIndex.OrangeFillButton
                        btnLabel={
                          !isLoading ? (
                            "Submit"
                          ) : (
                            <>
                              <Index.CircularProgress
                                sx={{ color: "white", mr: 1 }}
                                size={24}
                              />
                            </>
                          )
                        }
                        disabled={isLoading}
                        disableRipple
                        className="orange-fill-btn auth-modal-btn"
                        type="submit"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Form>
            )}
          </Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import "./dashboard.css";
import "./dashboard.responsive.css";
import PropTypes from "prop-types";
import PagesIndex from "../../../PagesIndex";
import { Pages } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box className="pd-tab-listing">
          <Index.Typography className="inner-dash-listing">
            {children}
          </Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Dashboard() {
  const location = useLocation();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [num, setNum] = useState("0");
  const handleNumChange = (data) => setNum(data);

  useEffect(() => {
    if (location?.state?.num) {
      setNum(location?.state?.num);
    }
  }, [location]);
  return (
    <>
      <Index.Box className="dashboard-bg-main">
        <Index.Box className="cus-container">
          <Index.Box className="bg-tab-main">
            <Index.Box className="dashboard-tab-row">
              <Index.Box className="dashboard-tab-col1">
                <Index.Box className="dash-tab-mains">
                  <Index.Box className="tab-custom-sidebar">
                    <PagesIndex.Sidebar
                      handleNumChange={handleNumChange}
                      num={num}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="dashboard-tab-col2">
                {num === "0" && (
                  <Index.Box className="analytics-components-main">
                    <PagesIndex.AnalyticsDashboard />
                  </Index.Box>
                )}
                {num == "1" && (
                  <Index.Box className="myorder-components-main">
                    <PagesIndex.MyOrder handleNumChange={handleNumChange} />
                  </Index.Box>
                )}
                {num == "2" && (
                  <Index.Box className="myquotations-components-main">
                    <PagesIndex.MyQuotations />
                  </Index.Box>
                )}
                {num == "3" && (
                  <Index.Box className="rfqSent-components-main">
                    <PagesIndex.RfqSent />
                  </Index.Box>
                )}
                {num == "4" && (
                  <Index.Box className="quotation-recieved-components-main">
                    <PagesIndex.QuotationRecieved />
                  </Index.Box>
                )}
                {num == "5" && (
                  <Index.Box className="ra-components-main">
                    <PagesIndex.Ra />
                  </Index.Box>
                )}
                {num == "6" && (
                  <Index.Box className="cancel-request-components-main">
                    <PagesIndex.CancelRequest />
                  </Index.Box>
                )}
                {num == "7" && (
                  <Index.Box className="expried-components-main">
                    <PagesIndex.Expried />
                  </Index.Box>
                )}

                {num == "8" && (
                  <Index.Box className="trackerorder-components-main">
                    <PagesIndex.TrackOrder />
                  </Index.Box>
                )}
                {/* <Index.Box className="trackerorder-components-main">
                                    <PagesIndex.TrackOrder />
                                </Index.Box> */}

                {/* <Index.Box className="myquotations-components-main">
                                    <PagesIndex.MyQuotations />
                                </Index.Box> */}

                {/* <Index.Box className="rfqSent-components-main">
                                    <PagesIndex.RfqSent />
                                </Index.Box> */}

                {/* <Index.Box className="quotation-recieved-components-main">
                                    <PagesIndex.QuotationRecieved />
                                </Index.Box> */}

                {/* <Index.Box className="ra-components-main">
                                    <PagesIndex.Ra />
                                </Index.Box> */}

                {/* <Index.Box className="cancel-request-components-main">
                                    <PagesIndex.CancelRequest />
                                </Index.Box> */}

                {/* <Index.Box className="expried-components-main">
                                    <PagesIndex.Expried />
                                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Dashboard;

import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'
import "./catalogue.css"
import './catalogue.responsive.css'

export default function Catalogue() {

  return (
    <Index.Box className="main-catalogue" component="main">
      <PagesIndex.BannerImage
        bannerImage={PagesIndex.Jpg.catalougeBg}
        bannerTitle="Catalogue"
        bannerContent="Lorem ipsum dolor sit amet consectetur."
      />
      <Index.Box className="cus-container">
        <Index.Box className="catalogue-title">
          Results
        </Index.Box>
        <Index.Box className="catalogue-wrapper">
          <PagesIndex.CatalogueCard />
          <PagesIndex.CatalogueCard />
          <PagesIndex.CatalogueCard />
          <PagesIndex.CatalogueCard />
          <PagesIndex.CatalogueCard />
          <PagesIndex.CatalogueCard />
          <PagesIndex.CatalogueCard />
          <PagesIndex.CatalogueCard />
          <PagesIndex.CatalogueCard />
          <PagesIndex.CatalogueCard />
          <PagesIndex.CatalogueCard />
          <PagesIndex.CatalogueCard />
        </Index.Box>
      </Index.Box>
      <PagesIndex.Helpsection />
    </Index.Box>
  )
}

import React from 'react'
import Index from '../../../../Index'
import './joinTeam.css'
import './joinTeam.responsive.css'

export default function JoinTeam() {

    return (
        <Index.Box className="join-team" component="section">
            <Index.Box className="cus-container">
                <Index.Box className="join-team-inner">
                    <Index.Box className="join-team-title">
                        Lorem ipsum dolor sit amet consectetur.
                    </Index.Box>
                    <Index.Box className="join-team-content">
                        Etiam lectus nibh sit felis eget justo vitae. Est eget leo sit lectus quisque accumsan in.
                    </Index.Box>
                    <Index.Button className="join-team-btn">
                        Join Our Team !
                        <Index.ArrowForwardIcon />
                    </Index.Button>
                </Index.Box>
            </Index.Box>
        </Index.Box>
    )
}
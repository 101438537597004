import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function RatingTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box>
          <Index.Box>{children}</Index.Box>
        </Index.Box>
      )}
    </div>
  );
}

RatingTabPanel.propTypes = {
  children: Index.PropTypes.node,
  index: Index.PropTypes.number.isRequired,
  value: Index.PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RatingAndReview() {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Index.Box className="rating-review">
        <Index.Box className="flex-all admin-title-box">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title admin-buyer-title"
              component="h3"
              variant="h3"
            >
              Rating & Review
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Tabs className="rating-tab-button common-tab-button" value={value} onChange={handleChange} aria-label="basic tabs example">
          <Index.Tab label="Buyers" {...a11yProps(0)} />
          <Index.Tab label="Sellers" {...a11yProps(1)} />
        </Index.Tabs>
        <RatingTabPanel value={value} index={0} className="buyer-tab common-tab">
          <Index.Box className="page-order-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container cus-scrollbar"
            >
              <Index.Table
                sx={{ minWidth: 1010 }}
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="15%"
                    >
                      Order ID
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="25%"
                    >
                      Buyer Name
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="20%"
                    >
                      Rating
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="25%"
                    >
                      Reviews
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="15%"
                    >
                      Date
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  <Index.TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="table-td"
                    >
                      #1234
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      Anil Chouhan
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      4/5
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      Awesome!
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      18-12-2023
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
            <Index.TablePagination
              className="cus-scrollbar"
              component="div"
            />
          </Index.Box>
        </RatingTabPanel>
        <RatingTabPanel value={value} index={1} className="buyer-tab common-tab">
          <Index.Box className="page-order-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container cus-scrollbar"
            >
              <Index.Table
                sx={{ minWidth: 1010 }}
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="15%"
                    >
                      Order ID
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="25%"
                    >
                      Seller Name
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="20%"
                    >
                      Rating
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="25%"
                    >
                      Reviews
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="15%"
                    >
                      Date
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  <Index.TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="table-td"
                    >
                      #1234
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      Anil Chouhan
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      4/5
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      Awesome!
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      18-12-2023
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
            <Index.TablePagination
              className="cus-scrollbar"
              component="div"
            />
          </Index.Box>
        </RatingTabPanel>
      </Index.Box>
    </>
  );
}

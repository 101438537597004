import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useNavigate } from "react-router-dom";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import moment from "moment";
import { toast } from "react-toastify";

export default function ProductManagement() {
  const navigate = useNavigate();
  const [allProduct, setAllProduct] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Start Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    const result = allProduct?.filter((item) => {
      return item?.name
        ?.toLowerCase()
        ?.toString()
        ?.includes(event?.target?.value.toLowerCase());
    });

    setFilterData(result);
  };

  const addProduct = () => {
    navigate("/admin/product-add-edit");
  };
  const viewProduct = (row) => {
    navigate("/admin/product-view", { state: { row: row } });
  };
  const editProduct = (row) => {
    navigate("/admin/product-add-edit", { state: { row: row } });
  };
  const deleteProduct = async (row) => {
    const data = {
      id: row,
    };
    await DataService.post(Api.Admin.DELETE_PRODUCT, data)
      .then((res) => {
        console.log(res, 22);
        toast.success(res?.data?.message);
        getAllProduct();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.responsive?.data?.message);
      });
  };

  const getAllProduct = async () => {
    setIsLoading(true);
    await DataService.get(Api.Admin.GET_PRODUCT)
      .then((res) => {
        console.log(res, 23);
        setAllProduct(res?.data?.data);
        setFilterData(res?.data?.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getAllProduct();
  }, [page, rowsPerPage]);
  if (isLoading) {
    return <PagesIndex.Loader />;
  } else {
    return (
      <>
        <Index.Box className="product-management">
          <Index.Box className="flex-all admin-title-box">
            <Index.Box className="admin-sub-title-main">
              <Index.Typography
                className="admin-sub-title admin-buyer-title"
                component="h3"
                variant="h3"
              >
                Product Management
              </Index.Typography>
            </Index.Box>
            <Index.Box className="view-btn-main border-btn-main">
              <Index.Box className="admin-search-common">
                <Index.TextField
                  fullWidth
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e)}
                />
                <img src={PagesIndex.Svg.search} className="search-icon" />
              </Index.Box>
              <PagesIndex.BlueFillButton
                className="view-order-btn blue-fill-btn"
                btnLabel="Add Product"
                onClick={addProduct}
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="page-buyer-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container cus-scrollbar"
            >
              <Index.Table
                sx={{ minWidth: 1000 }}
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="20%"
                    >
                      Product Name
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="15%"
                    >
                      Created Date
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="16%"
                    >
                      Categories
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="17%"
                    >
                      Type
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="17%"
                    >
                      Brands
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      align="center"
                      style={{ fontWeight: 700 }}
                      width="15%"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {filterData?.length > 0 ? (
                    filterData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row, index) => {
                        return (
                          <>
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              key={row?._id}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row ? row?.name : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row?.createdAt
                                  ? moment(row?.createdAt).format(
                                      "DD-MM-YYYY HH:mm:ss"
                                    )
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {/* Steel */}
                                {row
                                  ? row?.productCategoryId?.map(
                                      (row, index) => {
                                        return <>{row ? row?.name : "-"}</>;
                                      }
                                    )
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row
                                  ? row?.type?.map((row, index) => {
                                      return <>{row ? row?.name : "-"}</>;
                                    })
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row
                                  ? row?.brand?.map((row, index) => {
                                      return <>{row ? row?.name : "-"}</>;
                                    })
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                align="right"
                              >
                                <Index.Box
                                  sx={{ padding: "0px", margin: "0px" }}
                                >
                                  <Index.IconButton
                                    onClick={() => viewProduct(row?._id)}
                                  >
                                    <Index.Visibility
                                      style={{ color: "black" }}
                                    />
                                  </Index.IconButton>
                                  <Index.IconButton
                                    onClick={() => editProduct(row?._id)}
                                  >
                                    <img
                                      src={PagesIndex.Svg.editingIcon}
                                      alt="edit icon"
                                      width="20"
                                      height="20"
                                    />
                                  </Index.IconButton>
                                  <Index.IconButton
                                    onClick={() => deleteProduct(row?._id)}
                                  >
                                    <Index.DeleteOutlineIcon
                                      style={{ color: "black" }}
                                    />
                                  </Index.IconButton>
                                </Index.Box>
                              </Index.TableCell>
                            </Index.TableRow>
                          </>
                        );
                      })
                  ) : (
                    <>
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                          colSpan={6}
                        >
                          No Record found
                        </Index.TableCell>
                      </Index.TableRow>
                    </>
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
            {filterData?.length > 0 ? (
              <>
                <Index.TablePagination
                  className="cus-scrollbar"
                  component="div"
                  page={page}
                  count={filterData?.length}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 15, 20]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
        {/* <PagesIndex.AddProduct />
        <PagesIndex.EditProduct />
        <PagesIndex.ViewProduct /> */}
      </>
    );
  }
}

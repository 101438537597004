import React from 'react'
import './businessdetails.css'
import PagesIndex from '../../../../PagesIndex'
import Index from '../../../../Index'
import './businessdetails.responsive.css'

export default function BusinessDetails() {
  return (
    <>
      <Index.Box className="bussines-details-section my-account-sections">
        <Index.Box className="bussiness-title-box">
          <Index.Box className="bussiness-title-flex">
            <Index.Typography className="business-title" component="h3" variant="h3">Business Details</Index.Typography>
            <Index.Typography className="edit-details-title" component="p" variant="p">Edit Details</Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className='business-content-section'>
          <Index.Box className="grid-main bussines-details-main">
            <Index.Box className="verify-grid-row">
              <Index.Box className="grid-column">
                <Index.Box className="verify-input-main">
                  <Index.Box className="comman-form-group">
                    <Index.FormHelperText className='comman-form-label'>MSME<span className='astric-sign'>*</span></Index.FormHelperText>
                    <Index.TextField className='comman-form-control' type='text' placeholder='123455678' />
                    <Index.Box className="after-upload-flex">
                      <Index.Box className="after-upload-path-text"><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="grid-column">
                <Index.Box className="verify-input-main">
                  <Index.Box className="comman-form-group">
                    <Index.FormHelperText className='comman-form-label' >PAN of the company<span className='astric-sign'>*</span></Index.FormHelperText>
                    <Index.TextField className='comman-form-control' type='text' placeholder='123455678' />
                    <Index.Box className="after-upload-flex">
                      <Index.Box className="after-upload-path-text"><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="grid-column">
                <Index.Box className="verify-input-main">
                  <Index.Box className="comman-form-group">
                    <Index.FormHelperText className='comman-form-label'  >GST certificate<span className='astric-sign'>*</span></Index.FormHelperText>
                    <Index.TextField className='comman-form-control' type='text' placeholder='123455678' />
                    <Index.Box className="after-upload-flex">
                      <Index.Box className="after-upload-path-text"><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className='business-bank-section'>
            <Index.Typography className='business-bank-title'>Bank details</Index.Typography>
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box display="grid" className="verify-grid-row">
                <Index.Box className="grid-column">
                  <Index.Box className="verify-input-main">
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className='comman-form-label'  >Bank name</Index.FormHelperText>
                      <Index.TextField className='comman-form-control' type='text' placeholder='' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="verify-input-main">
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className='comman-form-label'  >Account number</Index.FormHelperText>
                      <Index.TextField className='comman-form-control' type='text' placeholder='' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="verify-input-main">
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className='comman-form-label'  >IFSC code</Index.FormHelperText>
                      <Index.TextField className='comman-form-control' type='text' placeholder='' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="verify-input-main comman-form-group">
                    <Index.FormHelperText className='comman-form-label'  >Cancelled cheque</Index.FormHelperText>
                    <Index.Box className="upload-file-main">

                      <Index.TextField className='upload-file-control' type='file' placeholder='Choose file' />
                      <Index.Box className="upload-file-cust-main">
                        <Index.Box className="upload-file-cust-flex">
                          <Index.Box className="upload-file-left-main">
                            <Index.Typography className="upload-file-text" component="p" variant="p" >Choose file</Index.Typography>
                          </Index.Box>
                          <Index.Box className="upload-file-right-main">
                            <Index.Typography className="upload-browse-text" component="p" variant="p" ><img src={PagesIndex.Svg.cloudupload} className='cloudupload-icons' alt='upload' />Upload</Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}

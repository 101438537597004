import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import "./myaccountcontent.css"
import "./myaccountcontent.responsive.css"

export default function MyAccountContent() {

      // VerifyAccountModal
      const [openVerifyAccountModal, setOpenVerifyAccountModal] = React.useState(false);
      const handleOpenVerifyAccountModal = () => setOpenVerifyAccountModal(true);
      const handleCloseVerifyAccountModal = () => setOpenVerifyAccountModal(false);
      return (
            <>
                  <Index.Box className="my-acc-content-section">
                        <Index.Box className="my-acc-content-row">
                              <Index.Box className="my-acc-content-main">
                                    <Index.Box className="my-acc-content-para"  component="p" variant="p" >Currently, you have no business details. <br/> Please verify
                                          your account !</Index.Box>
                                    <Index.Box className="my-verify-btn-main">
                                          <PagesIndex.OrangeFillButton btnLabel="Verify Account" className="orange-fill-btn veryfy-acc-btn" onClick={handleOpenVerifyAccountModal} />
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>

                  <PagesIndex.VerifyAccountModal openVerifyAccountModal={openVerifyAccountModal} handleCloseVerifyAccountModal={handleCloseVerifyAccountModal} />
            </>
      )
}

import React from "react";
import PagesIndex from "../../PagesIndex";
import Index from "../../Index";
import "./ratingandreviewmodal.css";
import { Form, Formik } from "formik";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function RatingAndReviewModal(props) {
  const { openRatingAndReviewModal, handleCloseRatingAndReviewModal } = props;
  const {userData} = useSelector((state) => state.user);
  console.log(userData, 22);

  const initialValues = {
    comment: "",
    rating: 0,
  };

  const handleFormSubmit = async (value) => {
    console.log(value, 25);
    const data = {
      review: value?.comment,
      rating: value?.rating,
    };
    await DataService.post(Api.User.RATING_BUYER_SELLER, data)
      .then((res) => {
        console.log(res, 29);
        toast.success(res?.data?.message)
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message)
      });
  };

  return (
    <Index.Modal
      open={openRatingAndReviewModal}
      onClose={handleCloseRatingAndReviewModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-common-main"
    >
      <Index.Box
        sx={style}
        className="review-modal-inner-main auth-modal-inner modal-common-style"
      >
        <Index.Box className=" cus-scrollbar modal-hgt-scroll">
          {" "}
          <Index.Box className="modal-header">
            <Index.Typography className="auth-modal-title">
              Share your experience with this order. We value your feedback!
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeicon}
              className="modal-close-icon"
              onClick={handleCloseRatingAndReviewModal}
            />
          </Index.Box>
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            //  validationSchema={validationSchema}
          >
            {({
              handleSubmit,
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Index.Box className="modal-body">
                  <Index.Box className="review-modal-content-main">
                    <Index.Box className="your-order-details">
                      <Index.Box className="your-order-id-details">
                        <Index.Typography className="your-orderide-title">
                          Your order <span>(#12345)</span>
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="order-purchase-show">
                        <Index.Box className="flex-odrer-show">
                          <Index.Box className="order-img-show-conteny">
                            <img
                              src={PagesIndex.Png.cart}
                              className="order-cart-show"
                              alt="cart-img"
                            ></img>
                          </Index.Box>
                          <Index.Box className="order-view-listing">
                            <Index.Typography className="description-order-title">
                              Fastgear 24 inch 15mm Steel Black Wire Plumbing
                              Connection{" "}
                            </Index.Typography>
                            <Index.Box className="description-order-content">
                              <Index.List className="table-list order-listing-your">
                                <Index.ListItem className="table-list-item order-listitem-your">
                                  Dimensions (6 X 6)
                                </Index.ListItem>
                                <Index.ListItem className="table-list-item order-listitem-your">
                                  Qty (50 pieces)
                                </Index.ListItem>
                              </Index.List>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="review-star-main">
                    <PagesIndex.Rating
                      handleChange={handleChange}
                      valueData={values?.rating}
                      name="rating"
                    />
                  </Index.Box>
                  <Index.Box className="review-discription-main">
                    <Index.Box className="form-group">
                      <Index.TextareaAutosize
                        aria-label="minimum height"
                        minRows={7}
                        placeholder="Add a comment..."
                        className="form-control-textarea"
                        name="comment"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.comment}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-review-btn-main">
                    <PagesIndex.OrangeFillButton
                      btnLabel="Submit"
                      className="orange-fill-btn review-modal-btn"
                      type="submit"
                    />
                    <PagesIndex.OrangeOutlineButton
                      btnLabel="Cancel"
                      onClick={handleCloseRatingAndReviewModal}
                      className="orange-outline-inner review-modal-btn"
                    />
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-footer"></Index.Box>
              </Form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </Index.Modal>
  );
}

import React from 'react'
import Index from '../../../../Index'
import './trackOrder.css'
import './trackOrder.responsive.css'
import PagesIndex from '../../../../PagesIndex'

function TrackOrder() {
    return (
        <>

            <Index.Box className="track-order-main-content">
                <Index.Box className="track-order-flex-justify">
                    <Index.Box className="order-id-details">
                        <Index.Typography className='order-id-title'>Order ID: <span>#12345</span></Index.Typography>
                    </Index.Box>
                    <Index.Box className="estimated-time-details">
                        <Index.Typography className='estimated-time-title'>Estimated Time</Index.Typography>
                        <Index.Typography className='estimated-date-title'>30/11/23<span>(15 days)</span></Index.Typography>
                    </Index.Box>
                </Index.Box>

                <Index.Box className="stepper-details-check">
                    <PagesIndex.Stepper />
                </Index.Box>

                <Index.Box className="your-order-details">
                    <Index.Box className="your-order-id-details">
                        <Index.Typography className='your-orderide-title'>Your order <span>(#12345)</span></Index.Typography>
                    </Index.Box>
                    <Index.Box className="order-purchase-show">
                        <Index.Box className="flex-odrer-show">
                            <Index.Box className="order-img-show-conteny">
                                <img src={PagesIndex.Png.cart} className="order-cart-show" alt="cart-img"></img>
                            </Index.Box>
                            <Index.Box className="order-view-listing">
                                <Index.Typography className="description-order-title">Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                <Index.Box className="description-order-content">
                                    <Index.List className='table-list order-listing-your'>
                                        <Index.ListItem className='table-list-item order-listitem-your'>
                                            Dimensions (6 X 6)
                                        </Index.ListItem>
                                        <Index.ListItem className='table-list-item order-listitem-your'>
                                            Qty (50 pieces)
                                        </Index.ListItem>
                                    </Index.List>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>

                <Index.Box className="shipping-address-details">
                    <Index.Box className="shipping-address-title">
                        <Index.Typography className='ship-address-content'>Shipping Address</Index.Typography>
                    </Index.Box>
                    <Index.Box className="ship-address-show">
                        <Index.Typography className='ship-title-address'>B-44/B Saraswati Enclave opposite sector - 10A<br/>
                            Gurgaon, Haryana<br/>
                            122001</Index.Typography>
                    </Index.Box>
                </Index.Box>
            </Index.Box>


        </>
    )
}

export default TrackOrder
import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'

export default function SellerNotification() {
  return (
    <Index.Box className="notification-section my-account-sections">
      <Index.Box className="notification-title-box">
        <Index.Box className="notification-title">
          Notification
        </Index.Box>
      </Index.Box>
      <Index.Box className="notification-content-main">
        <Index.Box className="notification-content-box">
          <Index.Typography className='notification-date'>
            01/03/2023
          </Index.Typography>
          <Index.Typography className='notification-status delivered'>
            Order Delivered!
          </Index.Typography>
          <Index.Typography className='notification-content'>
            Your order has been successfully delivered. <br />
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </Index.Typography>
        </Index.Box>
        <Index.Box className="notification-content-box">
          <Index.Typography className='notification-date'>
            01/03/2023
          </Index.Typography>
          <Index.Typography className='notification-status cancelled'>
            Order Cancelled!
          </Index.Typography>
          <Index.Typography className='notification-content'>
            Your order has been successfully cancelled. <br />
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </Index.Typography>
        </Index.Box>
        <Index.Box className="notification-content-box">
          <Index.Typography className='notification-date'>
            01/03/2023
          </Index.Typography>
          <Index.Typography className='notification-status delivered'>
            Order Delivered!
          </Index.Typography>
          <Index.Typography className='notification-content'>
            Your order has been successfully delivered. <br />
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          </Index.Typography>
        </Index.Box>
      </Index.Box>
      <Index.Box className="notification-pagination">
        <Index.Button className='notification-pagination-btn disabled'>
          <img src={PagesIndex.Svg.arrowBackIosNew} alt='nav icon' className='nav-prev nav-btn' />
        </Index.Button>
        <Index.Button className='notification-pagination-btn active'>
          1
        </Index.Button>
        <Index.Button className='notification-pagination-btn'>
          2
        </Index.Button>
        <Index.Button className='notification-pagination-btn'>
          3
        </Index.Button>
        <Index.Button className='notification-pagination-btn'>
          4
        </Index.Button>
        <Index.Button className='notification-pagination-btn'>
          <img src={PagesIndex.Svg.arrowBackIosNew} alt='nav icon' className='nav-next nav-btn' />
        </Index.Button>
      </Index.Box>
    </Index.Box>
  )
}

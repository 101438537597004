import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import PageIndex from "../container/PagesIndex";
import DataService from "../config/DataService";
import { adminLogout } from "../redux/features/slices/admin/AdminSlice";

export default function AdminPrivateRoute() {
 
  const { adminToken } = PageIndex.useSelector((state) => state.admin);

 

  const isAuthenticate = (token) => {
    if (!token) return false;
    PageIndex.DataService.defaults.headers.common.auth = token;
    return true;
  };

 
  return isAuthenticate(adminToken) ? (
    <Outlet />
  ) : (
    <PageIndex.Navigate to="/admin" replace={true} />
  );
}

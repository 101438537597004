import React from "react";
import PagesIndex from "../../../PagesIndex";

export default function Home() {
  return (
    <>
      <PagesIndex.Herosection />
      <PagesIndex.Aboutsection />
      <PagesIndex.JoinUsSection />
      <PagesIndex.StatSection />
      <PagesIndex.Ourkeyfeaturesection />
      <PagesIndex.Userinterface />
      <PagesIndex.Applicationwork />
      <PagesIndex.Categoriessection />
      <PagesIndex.Blogssection />
      <PagesIndex.Testimonialsection />
      <PagesIndex.Helpsection />
    </>
  );
}

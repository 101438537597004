import React, { useState } from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import './sellerRa.css'
import './sellerRa.responsive.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

export default function SellerRa() {

    const [openPlaceBidModal, setOpenPlaceBidModal] = useState(false);
    const handleOpenPlaceBidModal = () => setOpenPlaceBidModal(true);
    const handleClosePlaceBidModal = () => setOpenPlaceBidModal(false);

    const [openBidSuccess, setOpenBidSuccess] = useState(false);
    const handleOpenBidSuccess = () => { setOpenBidSuccess(true); setOpenPlaceBidModal(false); }
    const handleCloseBidSuccess = () => setOpenBidSuccess(false);

    const [openBidSecured, setOpenBidSecured] = useState(false);
    const handleOpenBidSecured = () => { setOpenBidSecured(true); setOpenBidSuccess(false); }
    const handleCloseBidSecured = () => setOpenBidSecured(false);

    const [openBidLost, setOpenBidLost] = useState(false);
    const handleOpenBidLost = () => { setOpenBidLost(true); setOpenBidSecured(false); }
    const handleCloseBidLost = () => setOpenBidLost(false);

    return (

        <Index.Box className="seller-ra-main">
            <Index.TableContainer className="seller-ra-container common-table-container cus-scrollbar">
                <Index.Table aria-label="simple table" className='seller-ra common-table'>
                    <Index.TableHead className='seller-ra-head common-table-head'>
                        <Index.TableRow className='seller-ra-row common-table-row'>
                            <Index.TableCell className='seller-ra-table-th common-table-th' width="18%" align='left'>Order ID</Index.TableCell>
                            <Index.TableCell className='seller-ra-table-th common-table-th' width="22%" align='left'>Product Details</Index.TableCell>
                            <Index.TableCell className='seller-ra-table-th common-table-th' width="20%" align='center'>Qty</Index.TableCell>
                            <Index.TableCell className='seller-ra-table-th common-table-th' width="20%" align='center'>Reverse Auction</Index.TableCell>
                            <Index.TableCell className='seller-ra-table-th common-table-th' width="20%" align='center'>Value Added</Index.TableCell>
                        </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className='seller-ra-body common-table-body'>
                        <Index.TableRow className='seller-ra-row common-table-row'>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="18%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="22%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='seller-qty'>
                                    6 X 6 - 50 pipes <br />
                                    Loerm ipsum..
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <Index.Box class="seller-confirm-pending">
                                    Will shortly <br />
                                    conveyed
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='value-added'>
                                    INR 6,00,000
                                </Index.Box>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='seller-ra-row common-table-row'>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="18%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="22%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='seller-qty'>
                                    6 X 6 - 50 pipes <br />
                                    Loerm ipsum..
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <Index.Box class="ra-value scheduled-color-text">
                                    Scheduled on <br />
                                    24/10/23
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='value-added'>
                                    INR 6,00,000
                                </Index.Box>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='seller-ra-row common-table-row'>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="18%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="22%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='seller-qty'>
                                    6 X 6 - 50 pipes <br />
                                    Loerm ipsum..
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <Index.Box class="order-green-text">
                                    Secured
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='value-added'>
                                    INR 6,00,000
                                </Index.Box>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='seller-ra-row common-table-row'>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="18%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="22%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='seller-qty'>
                                    6 X 6 - 50 pipes <br />
                                    Loerm ipsum..
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <PagesIndex.BlueFillButton btnLabel="Bid Now" onClick={handleOpenPlaceBidModal} className="blue-fill-btn bid-btn" />
                            </Index.TableCell>
                            <Index.TableCell className='seller-ra-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='value-added'>
                                    INR 6,00,000
                                </Index.Box>
                            </Index.TableCell>
                        </Index.TableRow>
                    </Index.TableBody>
                </Index.Table>
            </Index.TableContainer>

            <Index.Modal
                open={openPlaceBidModal}
                onClose={handleClosePlaceBidModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modal-common-main place-bid-modal'
            >
                <Index.Box sx={style} className="place-bid-modal-inner modal-common-style">
                    <Index.Box className="cus-scrollbar modal-hgt-scroll">
                        <Index.Box className="place-bid-modal-wrapper">
                        <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleClosePlaceBidModal} />
                            <Index.Box className="bid-left">
                                <Index.Box className="product-img-box">
                                    <img src={PagesIndex.Png.cart} alt='product' className='product-img' />
                                </Index.Box>
                                <Index.Box className="lowest-bid">
                                    <Index.Box className="lowest-bid-label">
                                        Lowest Bid
                                    </Index.Box>
                                    <Index.Box className="lowest-bid-value">
                                        $ 60,00,000
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="lowest-bid-low">
                                    <Index.Box className="lowest-bid-low-label">
                                        $
                                    </Index.Box>
                                    <Index.Box className="lowest-bid-low-value">
                                        60,00,000 or low
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="bid-minus-btn-box">
                                    <Index.Box className="bid-minus-value">
                                        -$50,000
                                    </Index.Box>
                                    <Index.Button className="bid-minus-btn">
                                        <img src={PagesIndex.Svg.dropdownarrow} className='cust-dropdown-arrow-icon' />
                                    </Index.Button>
                                </Index.Box>
                                <Index.Box className="bid-place-btn-box">
                                    <PagesIndex.BlueborderButton btnLabel="Cancel Bid" onClick={handleClosePlaceBidModal} className="blue-border-btn cancel-bid-btn" />
                                    <PagesIndex.BlueFillButton btnLabel="Bid Now" onClick={handleOpenBidSuccess} className="blue-fill-btn bid-now-btn" />
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="bid-right">
                                <Index.Box className="product-img-box-mobile">
                                    <img src={PagesIndex.Png.cart} alt='product' className='product-img' />
                                </Index.Box>
                                <Index.Box className="product-title-live-box">
                                    <Index.Box className="product-title-box">
                                        <Index.Box className="product-title">
                                            Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection
                                        </Index.Box>
                                        <Index.Box className="product-label">
                                            6 X 6 - 50 pipes . Loerm ipsum . Loerm ipsum
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="product-live-box">
                                        <Index.Typography variant='span' component="span" className='product-live-light'></Index.Typography>
                                        <Index.Typography variant='span' component="span" className='product-live-text'>
                                            Live
                                        </Index.Typography>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="product-bid-details">
                                    <Index.Box className="product-bid-details-item">
                                        <Index.Box className="product-details-label">
                                            Time Left
                                        </Index.Box>
                                        <Index.Box className="product-details-value time-left">
                                            29min 59sec
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="product-bid-details-item">
                                        <Index.Box className="product-details-label">
                                            Auction Ending
                                        </Index.Box>
                                        <Index.Box className="product-details-value">
                                            August 23,2024
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="product-bid-details-item">
                                        <Index.Box className="product-details-label">
                                            Participants
                                        </Index.Box>
                                        <Index.Box className="product-details-value">
                                            04
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="product-bid-details-item">
                                        <Index.Box className="product-details-label">
                                            Lowest Bid
                                        </Index.Box>
                                        <Index.Box className="product-details-value">
                                            $60,00,000
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="product-order-details">
                                    <Index.Box className="product-order-details-item">
                                        <Index.Box className="product-order-label">
                                            Order ID
                                        </Index.Box>
                                        <Index.Box className="product-order-value">
                                            #12341234
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="product-order-details-item">
                                        <Index.Box className="product-order-label">
                                            Loerm ipsum
                                        </Index.Box>
                                        <Index.Box className="product-order-value">
                                            Loerm ipsum
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="product-order-details-item">
                                        <Index.Box className="product-order-label">
                                            Loerm ipsum
                                        </Index.Box>
                                        <Index.Box className="product-order-value">
                                            Loerm ipsum
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="product-order-details-item">
                                        <Index.Box className="product-order-label">
                                            Loerm ipsum
                                        </Index.Box>
                                        <Index.Box className="product-order-value">
                                            Loerm ipsum
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal >

            <Index.Modal
                open={openBidSuccess}
                onClose={handleCloseBidSuccess}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modal-common-main bid-success-modal'
            >
                <Index.Box sx={style} className="bid-success-modal-inner modal-common-style">
                    <Index.Box className="cus-scrollbar modal-hgt-scroll">
                    <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseBidSuccess} />
                        <Index.Box className="modal-logo-main">
                            <img src={PagesIndex.Svg.logo} className='modal-logo' />
                        </Index.Box>
                        <Index.Typography className='bid-success-content' component="p" variant='p'>
                            Bidding has been ended. Thanks for participating <br /> we will update you soon on the status!
                        </Index.Typography>
                        <PagesIndex.BlueFillButton btnLabel="Done" onClick={handleOpenBidSecured} className="blue-fill-btn bid-success-btn" />
                    </Index.Box>
                </Index.Box>
            </Index.Modal>

            <Index.Modal
                open={openBidSecured}
                onClose={handleCloseBidSecured}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modal-common-main bid-secured-modal'
            >
                <Index.Box sx={style} className="bid-secured-modal-inner modal-common-style">
                    <Index.Box className="cus-scrollbar modal-hgt-scroll">
                    <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseBidSecured} />
                        <Index.Box className="thumbs-up-header success">
                            <img src={PagesIndex.Svg.thumbsUp} className='thumbs-up-logo' />
                        </Index.Box>
                        <Index.Box className='bid-secured-content'>
                            <Index.Box className='bid-secured-text success'>
                                Congratulations!
                            </Index.Box>
                            <Index.Box className="bid-secured-para">
                                Your bid amount was the lowest, <br />
                                so you secured the deal.
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className='bid-secured-btn-box'>
                            <PagesIndex.BlueborderButton btnLabel="Check Details" className="blue-border-btn bid-secured-btn" />
                            <PagesIndex.BlueFillButton btnLabel="Done" onClick={handleOpenBidLost} className="blue-fill-btn bid-secured-btn" />
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>

            <Index.Modal
                open={openBidLost}
                onClose={handleCloseBidLost}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modal-common-main bid-secured-modal'
            >
                <Index.Box sx={style} className="bid-secured-modal-inner modal-common-style">
                    <Index.Box className="cus-scrollbar modal-hgt-scroll">
                    <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseBidLost} />
                        <Index.Box className="thumbs-up-header lost">
                            <img src={PagesIndex.Svg.thumbsUp} className='thumbs-up-logo' />
                        </Index.Box>
                        <Index.Box className='bid-secured-content'>
                            <Index.Box className='bid-secured-text lost'>
                                Opps!
                            </Index.Box>
                            <Index.Box className="bid-secured-para">
                                Unfortunately, your bid amount wasn't the lowest, <br />
                                resulting in the loss of the deal.
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className='bid-secured-btn-box'>
                            <PagesIndex.BlueborderButton btnLabel="Check Details" className="blue-border-btn bid-secured-btn" />
                            <PagesIndex.BlueFillButton btnLabel="Done" onClick={handleCloseBidLost} className="blue-fill-btn bid-secured-btn" />
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>
        </Index.Box>

    )

}
import React from "react";
import Index from "../../../Index";

function Comingsoon() {
  return (
    <Index.Box className="coming-soon-wrape">
      <Index.Box className="cus-container">
        <Index.Typography component="h2">Coming <span>soon</span></Index.Typography>
      </Index.Box>
    </Index.Box>
  )
}

export default Comingsoon
import React, { useState } from 'react'
import Index from '../../../../Index'
import './sidebar.css'
import './sidebar.responsive.css'
import PagesIndex from '../../../../PagesIndex'

function Sidebar({ handleNumChange, num }) {
    const [openSidebarMenu, setOpenSidebarMenu] = useState(false)

    return (
        <>

            <Index.Box className="sidebar-dash-details">
                <Index.Box className="sidebar-tab-custom">
                    <Index.List className="sidebardash-main-list">
                        <Index.ListItem className={num == "0" ? "sidebardash-main-listitem active" : "sidebardash-main-listitem"}>
                            <Index.Box className="sidebar-tabs-inner-list" >
                                <Index.Box className="sidebar-tabs-link" onClick={() => handleNumChange("0")}>
                                    <Index.Box className="sidebar-icon-tabs">
                                        <PagesIndex.AnalyticIcons />
                                    </Index.Box>
                                    <Index.Box className="side-tab-title">
                                        <Index.Typography className='dash-side-title'>Analytics</Index.Typography>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.ListItem>
                        <Index.ListItem className={num == "1" ? "sidebardash-main-listitem active" : "sidebardash-main-listitem"}>
                            <Index.Box className="sidebar-tabs-inner-list" >
                                <Index.Box className="sidebar-tabs-link" onClick={() => handleNumChange("1")}>
                                    <Index.Box className="sidebar-icon-tabs">
                                        <PagesIndex.MyOrderIcons />
                                    </Index.Box>
                                    <Index.Box className="side-tab-title" >
                                        <Index.Typography className='dash-side-title'>My Orders</Index.Typography>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="order-list-number">
                                    <Index.Box className="num-show-order">3</Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.ListItem>
                        <Index.ListItem className={num == "2" ? "sidebardash-main-listitem active" : "sidebardash-main-listitem"}>
                            <Index.Box className="sidebar-tabs-inner-list" >
                                <Index.Box className="sidebar-tabs-link" onClick={() => handleNumChange("2")}>
                                    <Index.Box className="sidebar-icon-tabs">
                                        <PagesIndex.MailIcons />
                                    </Index.Box>
                                    <Index.Box className="side-tab-title">
                                        <Index.Typography className='dash-side-title'>My Quotations</Index.Typography>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="drop-side-tab-arrow">
                                    <Index.Button className="drop-btn-arrow" disableFocusRipple disableRipple onClick={() => setOpenSidebarMenu(!openSidebarMenu)}>
                                        <img src={PagesIndex.Svg.arrowup} alt="arrowup" className={openSidebarMenu ? 'arrowup-drop sidebar-menu-open' : 'arrowup-drop'}></img>
                                    </Index.Button>
                                </Index.Box>
                            </Index.Box>
                            {openSidebarMenu ? (<Index.Box className="inner-side-tab-listing">
                                <Index.List className="sidebar-dash-inner-list">
                                    <Index.ListItem className={num == "3" ? "sidebar-dash-inner-listitem active" : "sidebar-dash-inner-listitem"} onClick={() => handleNumChange("3")}>
                                        <Index.Link className="inner-refrence-sidelink">RFQ sent</Index.Link>
                                    </Index.ListItem>
                                    <Index.ListItem className={num == "4" ? "sidebar-dash-inner-listitem active" : "sidebar-dash-inner-listitem"} onClick={() => handleNumChange("4")}>
                                        <Index.Link className="inner-refrence-sidelink">Quotation received</Index.Link>
                                    </Index.ListItem>
                                    <Index.ListItem className={num == "5" ? "sidebar-dash-inner-listitem active" : "sidebar-dash-inner-listitem"} onClick={() => handleNumChange("5")}>
                                        <Index.Link className="inner-refrence-sidelink">Reverse Auction</Index.Link>
                                    </Index.ListItem>
                                    <Index.ListItem className={num == "6" ? "sidebar-dash-inner-listitem active" : "sidebar-dash-inner-listitem"} onClick={() => handleNumChange("6")}>
                                        <Index.Link className="inner-refrence-sidelink">Cancelled requests</Index.Link>
                                    </Index.ListItem>
                                    <Index.ListItem className={num == "7" ? "sidebar-dash-inner-listitem active" : "sidebar-dash-inner-listitem"} onClick={() => handleNumChange("7")}>
                                        <Index.Link className="inner-refrence-sidelink">Expired requests</Index.Link>
                                    </Index.ListItem>
                                </Index.List>
                            </Index.Box>) : ""}

                        </Index.ListItem>
                    </Index.List>
                </Index.Box>
            </Index.Box>

        </>
    )
}

export default Sidebar
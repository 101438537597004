import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function SellerBusinessDetails() {
  return (
    <>
      <Index.Box className="bussines-details-section my-account-sections">
        <Index.Box className="bussiness-title-box">
          <Index.Box className="bussiness-title-flex">
            <Index.Typography className="business-title" component="h3" variant="h3">Business Details</Index.Typography>
            <Index.Typography className="edit-details-title" component="p" variant="p">Edit Details</Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className='business-content-section'>
          <Index.Box className="grid-main bussines-details-main">
            <Index.Box className="verify-grid-row">
              <Index.Box className="grid-column">
                <Index.Box className="verify-input-main">
                  <Index.Box className="comman-form-group">
                    <Index.FormHelperText className='comman-form-label'>MSME<span className='astric-sign'>*</span></Index.FormHelperText>
                    <Index.TextField className='comman-form-control' type='text' placeholder='123455678' />
                    <Index.Box className="after-upload-flex">
                      <Index.Box className="after-upload-path-text"><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="grid-column">
                <Index.Box className="verify-input-main">
                  <Index.Box className="comman-form-group">
                    <Index.FormHelperText className='comman-form-label' >PAN of the company<span className='astric-sign'>*</span></Index.FormHelperText>
                    <Index.TextField className='comman-form-control' type='text' placeholder='123455678' />
                    <Index.Box className="after-upload-flex">
                      <Index.Box className="after-upload-path-text"><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="grid-column">
                <Index.Box className="verify-input-main">
                  <Index.Box className="comman-form-group">
                    <Index.FormHelperText className='comman-form-label'  >GST certificate<span className='astric-sign'>*</span></Index.FormHelperText>
                    <Index.TextField className='comman-form-control' type='text' placeholder='123455678' />
                    <Index.Box className="after-upload-flex">
                      <Index.Box className="after-upload-path-text"><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="grid-column">
                <Index.Box className="verify-input-main">
                  <Index.Box className="comman-form-group">
                    <Index.FormHelperText className='comman-form-label'  >Factory License<span className='astric-sign'>*</span></Index.FormHelperText>
                    <Index.TextField className='comman-form-control' type='text' placeholder='123455678' />
                    <Index.Box className="after-upload-flex">
                      <Index.Box className="after-upload-path-text"><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="grid-column">
                <Index.Box className="verify-input-main">
                  <Index.Box className="comman-form-group">
                    <Index.FormHelperText className='comman-form-label'  >ISO certificate<span className='astric-sign'>*</span></Index.FormHelperText>
                    <Index.TextField className='comman-form-control' type='text' placeholder='123455678' />
                    <Index.Box className="after-upload-flex">
                      <Index.Box className="after-upload-path-text"><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="grid-column">
                <Index.Box className="verify-input-main">
                  <Index.Box className="comman-form-group">
                    <Index.FormHelperText className='comman-form-label'  >BIS license<span className='astric-sign'>*</span></Index.FormHelperText>
                    <Index.TextField className='comman-form-control' type='text' placeholder='123455678' />
                    <Index.Box className="after-upload-flex">
                      <Index.Box className="after-upload-path-text"><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="grid-column">
                <Index.Box className="verify-input-main">
                  <Index.Box className="comman-form-group">
                    <Index.FormHelperText className='comman-form-label'  >API certificate<span className='astric-sign'>*</span></Index.FormHelperText>
                    <Index.TextField className='comman-form-control' type='text' placeholder='123455678' />
                    <Index.Box className="after-upload-flex">
                      <Index.Box className="after-upload-path-text"><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className='business-bank-section'>
            <Index.Typography className='business-bank-title'>Bank details</Index.Typography>
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box display="grid" className="verify-grid-row">
                <Index.Box className="grid-column">
                  <Index.Box className="verify-input-main">
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className='comman-form-label'  >Bank name</Index.FormHelperText>
                      <Index.TextField className='comman-form-control' type='text' placeholder='' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="verify-input-main">
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className='comman-form-label'  >Account number</Index.FormHelperText>
                      <Index.TextField className='comman-form-control' type='text' placeholder='' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="verify-input-main">
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className='comman-form-label'  >IFSC code</Index.FormHelperText>
                      <Index.TextField className='comman-form-control' type='text' placeholder='' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="verify-input-main comman-form-group">
                    <Index.FormHelperText className='comman-form-label'  >Cancelled cheque</Index.FormHelperText>
                    <Index.Box className="upload-file-main">

                      <Index.TextField className='upload-file-control' type='file' placeholder='Choose file' />
                      <Index.Box className="upload-file-cust-main">
                        <Index.Box className="upload-file-cust-flex">
                          <Index.Box className="upload-file-left-main">
                            <Index.Typography className="upload-file-text" component="p" variant="p" >Choose file</Index.Typography>
                          </Index.Box>
                          <Index.Box className="upload-file-right-main">
                            <Index.Typography className="upload-browse-text" component="p" variant="p" ><img src={PagesIndex.Svg.cloudupload} className='cloudupload-icons' alt='upload' />Upload</Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className='upload-catalogue'>
            <Index.Typography className='upload-catalogue-title'>Upload catalogue</Index.Typography>
            <Index.Box className="upload-img-file-modal">
              <Index.Button component="label" variant="contained" className='upload-label-details' >
                <Index.Box className="upload-main-content">
                  <img src={PagesIndex.Png.cloud} className='upload-cloud' alt="cloud"></img>
                </Index.Box>
                <Index.Typography className="browse-cloud-text">Browse Files to upload</Index.Typography>
                <Index.Typography className="browse-cloud-accepted">(accepted JPG, PNG & PDF only)</Index.Typography>
                <VisuallyHiddenInput type="file" />
              </Index.Button>
            </Index.Box>
            <Index.Box className="upload-mt-details">
              <Index.Box className="upload-document-show">
                <Index.Box className="upload-document-card">
                  <Index.Box className="file-icon-name">
                    <img src={PagesIndex.Png.descriptionupload} className='description-upload' alt="desc"></img>
                    <Index.Typography className="file-payment-name">File 001 PO</Index.Typography>
                  </Index.Box>
                  <Index.Button className="btn-delete-file" disableRipple>
                    <img src={PagesIndex.Png.deleteupload} className="delete-details" alt="delete"></img>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
              <Index.Box className="upload-document-show">
                <Index.Box className="upload-document-card">
                  <Index.Box className="file-icon-name">
                    <img src={PagesIndex.Png.descriptionupload} className='description-upload' alt="desc"></img>
                    <Index.Typography className="file-payment-name">File 001 PO</Index.Typography>
                  </Index.Box>
                  <Index.Button className="btn-delete-file" disableRipple>
                    <img src={PagesIndex.Png.deleteupload} className="delete-details" alt="delete"></img>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}

import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import './latestPosts.css'
import './latestPosts.responsive.css'

export default function LatestPosts() {

    return (
        <Index.Box className="latest-posts">
            <Index.Box className="cus-container">
                <Index.Box className="latest-posts-title">
                    Latest posts
                </Index.Box>
                <Index.Box className="latest-posts-bottom">
                    <Index.Box className="latest-posts-left">
                        <Index.Box className="blog-image-box">
                            <img src={PagesIndex.Jpg.blogImg5} alt='blog post' className='blog-image' />
                            <img src={PagesIndex.Svg.playCircle} alt='blog video play' className='blog-video-play' />
                        </Index.Box>
                        <Index.Box className="posts-title">
                            Lorem ipsum
                        </Index.Box>
                        <Index.Box className="posts-content">
                            Lorem ipsum dolor sit amet consectetur. Et nunc viverra tempus leo mauris diam tellus donec. Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Et nunc viverra tempus leo mauris diam tellus donec. Lorem ipsum dolor sit amet consectetu
                        </Index.Box>
                        <Index.Button className="posts-btn">
                            Read More
                        </Index.Button>
                    </Index.Box>
                    <Index.Box className="latest-posts-right">
                        <Index.Box className="latest-posts-item">
                            <img src={PagesIndex.Jpg.blogImg6} alt='blog post' className='blog-image' />
                            <Index.Box className="posts-content">
                                Lorem ipsum dolor sit amet consectetur. Et nunc viverra tempus leo mauris diam tellus donec.
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="latest-posts-item">
                            <img src={PagesIndex.Jpg.blogImg7} alt='blog post' className='blog-image' />
                            <Index.Box className="posts-content">
                                Et nunc viverra tempus leo mauris diam tellus donec. Lorem ipsum dolor sit amet consectetur.
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </Index.Box>
    )

}

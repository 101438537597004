import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
export default function ViewOrder() {
  const location = useLocation();
  const row = location?.state?.row;
  console.log(row, 9);
  const navigate = useNavigate();
  const backBtn = () => {
    navigate("/admin/order-management");
  };
  return (
    <Index.Box className="view-order">
      <Index.Box className="flex-all admin-title-box">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            className="admin-sub-title admin-buyer-title"
            component="h3"
            variant="h3"
          >
            View Order
          </Index.Typography>
        </Index.Box>
        <Index.Box className="view-btn-main border-btn-main">
          <PagesIndex.BlueborderButton
            className="cancel-btn blue-border-btn"
            btnLabel="Back"
            onClick={backBtn}
          />
        </Index.Box>
      </Index.Box>
      <Index.Box className="common-grid-main">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 4 }}
            className="login-row"
          >
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Buyer Name :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {row ? row?.buyerName?.fullName : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Order ID :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {row ? row?.orderId : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Amount :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {row ? row?.amount : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Status :
                </Index.Typography>
                <Index.Typography
                  className="comman-view-detail-value"
                  sx={{ color: "green" }}
                >
                  {row ? row?.status : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Date / Time :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {row?.createdAt
                    ? moment(row?.createdAt).format("DD-MM-YYYY HH:mm:ss")
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

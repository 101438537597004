import React from "react";
import Index from "../../Index";

export default function GrayFillButton(props) {
  return (
    <>
      <Index.Box className="gray-fill-btn-main">
        <Index.Button className={props.className} onClick={props.onClick}>
          {props.btnLabel}
        </Index.Button>
      </Index.Box>

      {/* use this button like below demo */}
      {/* <PagesIndex.GrayFillButton btnLabel="View Button" claasName="gray-fill-btn"/> */}
    </>
  );
}

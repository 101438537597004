import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import PropTypes from "prop-types";
import "./myaccount.css";
import "./myaccount.responsive.css";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box className="pd-tab-listing">
          <Index.Typography className="inner-dash-listing">
            {children}
          </Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MyAccount() {
  const [value, setValue] = React.useState();
  const [verify, setVerify] = React.useState(true);
  const handleVerify = () => {
    setVerify(true);
    setValue("");
  };
  const handleChange = (event, newValue) => {
    console.log("first");
    setValue(newValue);
    setVerify(false);
  };

  return (
    <>
      <Index.Box className="my-account-main">
        <Index.Box className="cus-container">
          <Index.Box className="my-account-flex-main">
            <Index.Box className="my-account-left-main">
              <Index.Box className="account-prfile-main">
                <Index.Box className="user-profile-flex">
                  <Index.Box className="user-img-main">
                    <Index.Box className="user-verify-bedge-main">
                      <img
                        src={PagesIndex.Svg.erroricon}
                        className="erroricon"
                        alt="arror"
                      />
                    </Index.Box>
                    {/* <img src={PagesIndex.Png.user} className='user-img'alt="user" /> */}
                    <Index.Typography
                      className="user-name-small"
                      component="h3"
                      variant="h3"
                    >
                      AM
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="user-content-box">
                    <Index.Typography
                      className="user-name-text"
                      component="h2"
                      variant="h2"
                    >
                      Alex Morgan
                    </Index.Typography>
                    <Index.Typography
                      className="user-number-text"
                      component="p"
                      variant="p"
                    >
                      +91 12345432
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                {/* <Index.Box className="user-verify-icon-flex">
                                                      <img src={PagesIndex.Svg.verifyicon} className='user-verify-icon' alt="verify/>
                                                      <Index.Typography className='user-verify-text'  component="p" variant="p" >Verified</Index.Typography>
                                                </Index.Box> */}
                <Index.Box className="account-veryfy-btn-main">
                  <PagesIndex.OrangeFillButton
                    btnLabel="Verify Account"
                    className="orange-fill-btn account-user-verify"
                    onClick={handleVerify}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box className="account-tabs-main">
                <Index.Tabs
                  value={value}
                  onChange={handleChange}
                  className="dash-tabs-content account-tabs"
                  aria-label="basic tabs example"
                  orientation="vertical"
                >
                  <Index.Tab
                    icon={
                      <Index.Box className="user-dash-icons">
                        <PagesIndex.WorkIcon />
                      </Index.Box>
                    }
                    className="dash-tab-inner-details"
                    label="My Business details"
                    {...a11yProps(0)}
                  />
                  <Index.Tab
                    icon={
                      <Index.Box className="user-dash-icons">
                        <PagesIndex.LocationIcon />
                      </Index.Box>
                    }
                    label="My Address"
                    {...a11yProps(1)}
                    className="dash-tab-inner-details"
                  />
                  <Index.Tab
                    icon={
                      <Index.Box className="user-dash-icons">
                        <PagesIndex.SettingIcon />
                      </Index.Box>
                    }
                    label="Settings"
                    {...a11yProps(2)}
                    className="dash-tab-inner-details"
                  />
                  <Index.Tab
                    icon={
                      <Index.Box className="user-dash-icons">
                        <PagesIndex.NotificationIcon />
                      </Index.Box>
                    }
                    label="Notifications"
                    {...a11yProps(3)}
                    className="dash-tab-inner-details"
                  />
                </Index.Tabs>
              </Index.Box>
            </Index.Box>
            <Index.Box className="my-account-right-main">
              {verify ? <PagesIndex.MyAccountContent /> : ""}
              <CustomTabPanel value={value} index={0}>
                <Index.Box className="my-account-content-components-main">
                  <PagesIndex.BusinessDetails />
                </Index.Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <PagesIndex.MyAddress />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <PagesIndex.Settings />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <PagesIndex.Notification />
              </CustomTabPanel>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import './rfqSent.css'

export default function RfqSent() {

    return (
        <Index.Box className="rfq-sent-main">
            <Index.Box className="rfq-sent-header dashboard-common-header">
                <Index.Box className="header-title">
                    RFQ sent
                </Index.Box>
                <Index.Box className="header-search">
                    <Index.Input
                        autoFocus
                        className="header-search-input"
                        placeholder="Search quotations"
                    />
                    <img src={Index.Svg.greysearch} className='search-icon' alt='search icon' />
                </Index.Box>
            </Index.Box>
            <Index.TableContainer className="rfq-table-container common-table-container cus-scrollbar">
                <Index.Table aria-label="simple table" className='rfq-table common-table common-table-small'>
                    <Index.TableHead className='rfq-table-head common-table-head'>
                        <Index.TableRow className='rfq-table-row common-table-row'>
                            <Index.TableCell className='rfq-table-th common-table-th' width="14%" align='left'>Enquiry no.</Index.TableCell>
                            <Index.TableCell className='rfq-table-th common-table-th' width="22%" align='center'>Suppliers</Index.TableCell>
                            <Index.TableCell className='rfq-table-th common-table-th' width="22%" align='center'>Bid</Index.TableCell>
                            <Index.TableCell className='rfq-table-th common-table-th' width="22%" align='center'>Date</Index.TableCell>
                            <Index.TableCell className='rfq-table-th common-table-th' width="20%" align='center'>Action</Index.TableCell>
                        </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className='rfq-table-body common-table-body'>
                        <Index.TableRow className='rfq-table-row common-table-row'>
                            <Index.TableCell className='rfq-table-td common-table-td' width="14%" align='left'>
                                00123
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="22%" align='center'>
                                <Index.List className='rfq-table-list'>
                                    <Index.ListItem className='rfq-table-list-item'>
                                        Jindal steel
                                    </Index.ListItem>
                                    <Index.ListItem className='rfq-table-list-item'>
                                        JSW steel
                                    </Index.ListItem>
                                    <Index.ListItem className='rfq-table-list-item'>
                                        TATA steel
                                    </Index.ListItem>
                                </Index.List>
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="22%" align='center'>
                                <Index.Box className="rfq-bid-value">
                                    Wait for quote <br />
                                    from Stelmart
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="22%" align='center'>
                                10/08/2023
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="20%" align='center'>
                                <Index.Button className='action-btn'>
                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                </Index.Button>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='rfq-table-row common-table-row'>
                            <Index.TableCell className='rfq-table-td common-table-td' width="14%" align='left'>
                                00123
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="22%" align='center'>
                                <Index.List className='rfq-table-list'>
                                    <Index.ListItem className='rfq-table-list-item'>
                                        Jindal steel
                                    </Index.ListItem>
                                    <Index.ListItem className='rfq-table-list-item'>
                                        JSW steel
                                    </Index.ListItem>
                                    <Index.ListItem className='rfq-table-list-item'>
                                        TATA steel
                                    </Index.ListItem>
                                </Index.List>
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="22%" align='center'>
                                <Index.Box className="rfq-bid-value">
                                    Wait for quote <br />
                                    from Stelmart
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="22%" align='center'>
                                10/08/2023
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="20%" align='center'>
                                <Index.Button className='action-btn'>
                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                </Index.Button>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='rfq-table-row common-table-row'>
                            <Index.TableCell className='rfq-table-td common-table-td' width="14%" align='left'>
                                00123
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="22%" align='center'>
                                <Index.List className='rfq-table-list'>
                                    <Index.ListItem className='rfq-table-list-item'>
                                        Jindal steel
                                    </Index.ListItem>
                                    <Index.ListItem className='rfq-table-list-item'>
                                        JSW steel
                                    </Index.ListItem>
                                    <Index.ListItem className='rfq-table-list-item'>
                                        TATA steel
                                    </Index.ListItem>
                                </Index.List>
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="22%" align='center'>
                                <Index.Box className="rfq-bid-value">
                                    Wait for quote <br />
                                    from Stelmart
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="22%" align='center'>
                                10/08/2023
                            </Index.TableCell>
                            <Index.TableCell className='rfq-table-td common-table-td' width="20%" align='center'>
                                <Index.Button className='action-btn'>
                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                </Index.Button>
                            </Index.TableCell>
                        </Index.TableRow>
                    </Index.TableBody>
                </Index.Table>
            </Index.TableContainer>
        </Index.Box>

    )

}

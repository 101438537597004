import React from 'react'
import Index from '../../Index'
import PagesIndex from '../../PagesIndex'

export default function Loader() {
    return (
        <Index.Box className="spinner-box">
            <Index.Box class="spinner"></Index.Box>
        </Index.Box>
    )
}

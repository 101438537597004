import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import './latestPostsAll.css'
import './latestPostsAll.responsive.css'

export default function LatestPostsAll() {

    return (
        <Index.Box className="latest-posts-all">
            <Index.Box className="cus-container">
                <Index.Box className="latest-posts-title">
                    Latest posts
                </Index.Box>
                <Index.Box className="latest-posts-content">
                    Lorem ipsum dolor sit amet consectetur. Et nunc viverra <br /> tempus leo mauris diam tellus donec.
                </Index.Box>
                <Index.Box className="all-posts-box">
                    <Index.Box className="all-posts-item">
                        <img src={PagesIndex.Jpg.blogImg8} alt='blog post' className='blog-image' />
                        <Index.Typography className='all-posts-content'>
                            Lorem ipsum dolor sit amet consectetur.
                        </Index.Typography>
                    </Index.Box>
                    <Index.Box className="all-posts-item">
                        <img src={PagesIndex.Jpg.blogImg9} alt='blog post' className='blog-image' />
                        <Index.Typography className='all-posts-content'>
                            Lorem ipsum dolor sit amet consectetur.
                        </Index.Typography>
                    </Index.Box>
                    <Index.Box className="all-posts-item">
                        <img src={PagesIndex.Jpg.blogImg10} alt='blog post' className='blog-image' />
                        <Index.Typography className='all-posts-content'>
                            Lorem ipsum dolor sit amet consectetur.
                        </Index.Typography>
                    </Index.Box>
                    <Index.Box className="all-posts-item">
                        <img src={PagesIndex.Jpg.blogImg11} alt='blog post' className='blog-image' />
                        <Index.Typography className='all-posts-content'>
                            Lorem ipsum dolor sit amet consectetur.
                        </Index.Typography>
                    </Index.Box>
                    <Index.Box className="all-posts-item">
                        <img src={PagesIndex.Jpg.blogImg12} alt='blog post' className='blog-image' />
                        <Index.Typography className='all-posts-content'>
                            Lorem ipsum dolor sit amet consectetur.
                        </Index.Typography>
                    </Index.Box>
                    <Index.Box className="all-posts-item">
                        <img src={PagesIndex.Jpg.blogImg13} alt='blog post' className='blog-image' />
                        <Index.Typography className='all-posts-content'>
                            Lorem ipsum dolor sit amet consectetur.
                        </Index.Typography>
                    </Index.Box>
                </Index.Box>
                <Index.Box className="pagination">
                    <Index.Button className="pagination-btn">
                        <img src={PagesIndex.Svg.keyboardArrowRight} alt='Arrow Back Icon' className='pagination-left' />
                    </Index.Button>
                    <Index.Box className="pagination-number">
                        <Index.Typography variant='span' component="span" className="pagination-active">
                            1
                        </Index.Typography>
                        /
                        <Index.Typography variant='span' component="span">
                            3
                        </Index.Typography>
                    </Index.Box>
                    <Index.Button className="pagination-btn">
                        <img src={PagesIndex.Svg.keyboardArrowRight} alt='Arrow Back Icon' className='pagination-right' />
                    </Index.Button>
                </Index.Box>
            </Index.Box>
        </Index.Box>
    )

}

import React from 'react'

export default function LocationIcon() {
  return (

    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.66667 10.3333C10.125 10.3333 10.5174 10.1701 10.8438 9.84375C11.1701 9.51736 11.3333 9.125 11.3333 8.66667C11.3333 8.20833 11.1701 7.81597 10.8438 7.48958C10.5174 7.16319 10.125 7 9.66667 7C9.20833 7 8.81597 7.16319 8.48958 7.48958C8.16319 7.81597 8 8.20833 8 8.66667C8 9.125 8.16319 9.51736 8.48958 9.84375C8.81597 10.1701 9.20833 10.3333 9.66667 10.3333ZM9.66667 16.4583C11.3611 14.9028 12.6181 13.4896 13.4375 12.2187C14.2569 10.9479 14.6667 9.81944 14.6667 8.83333C14.6667 7.31944 14.184 6.07986 13.2187 5.11458C12.2535 4.14931 11.0694 3.66667 9.66667 3.66667C8.26389 3.66667 7.07986 4.14931 6.11458 5.11458C5.14931 6.07986 4.66667 7.31944 4.66667 8.83333C4.66667 9.81944 5.07639 10.9479 5.89583 12.2187C6.71528 13.4896 7.97222 14.9028 9.66667 16.4583ZM9.66667 18.6667C7.43056 16.7639 5.76042 14.9965 4.65625 13.3646C3.55208 11.7326 3 10.2222 3 8.83333C3 6.75 3.67014 5.09028 5.01042 3.85417C6.35069 2.61806 7.90278 2 9.66667 2C11.4306 2 12.9826 2.61806 14.3229 3.85417C15.6632 5.09028 16.3333 6.75 16.3333 8.83333C16.3333 10.2222 15.7812 11.7326 14.6771 13.3646C13.5729 14.9965 11.9028 16.7639 9.66667 18.6667Z" fill="#333333" />
    </svg>

  )
}

import axios from "axios";
import { toast } from "react-toastify";

const DataService = axios.create({
  // baseURL: process.env.REACT_APP_API_BASE_URL,

  // live
  baseURL:"http://194.233.77.156:3042/api",

  // preeti
  // baseURL: "http://192.168.29.208:3042/api",
  // shubham
  // baseURL: "http://192.168.29.136:3042/api",

  // shubham

  // baseURL:"http://192.168.29.136:3042/api",

  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded',
  // },
});

//Post
export const doPost = async (url, data) => {
  try {
    const response = await DataService.post(url, data);
    console.log(response.data.status, 456);
    if (response?.data?.status == 200 || 201) {
      toast.success(response?.data?.message);

      return response;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export default DataService;

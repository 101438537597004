import React, { useState } from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import './myQuotations.css'
import './myQuotations.responsive.css'

export default function MyQuotations() {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Index.Box className="my-quotations-main">
            <Index.Box className="my-quotations-header dashboard-common-header">
                <Index.Box className="header-title">
                    My quotations
                </Index.Box>
                <Index.Box className="header-search">
                    <Index.Input
                        autoFocus
                        className="header-search-input"
                        placeholder="Search quotations"
                    />
                    <img src={Index.Svg.greysearch} className='search-icon' alt='search icon' />
                </Index.Box>
            </Index.Box>
            <Index.Box className="download-btn-box">
                <Index.Button className="download-btn">
                    Download
                </Index.Button>
            </Index.Box>
            <Index.TableContainer className="table-top-container common-table-container cus-scrollbar">
                <Index.Table aria-label="simple table" className='table-top common-table'>
                    <Index.TableHead className='table-top-head common-table-head'>
                        <Index.TableRow className='table-top-row common-table-row'>
                            <Index.TableCell className='table-top-th common-table-th' width="5%" align='left'></Index.TableCell>
                            <Index.TableCell className='table-top-th common-table-th' width="15%" align='left'>Enquiry no.</Index.TableCell>
                            <Index.TableCell className='table-top-th common-table-th' width="15%" align='left'>Brand Name</Index.TableCell>
                            <Index.TableCell className='table-top-th common-table-th' width="25%" align='center'>Specification</Index.TableCell>
                            <Index.TableCell className='table-top-th common-table-th' width="15%" align='center'>Date</Index.TableCell>
                            <Index.TableCell className='table-top-th common-table-th' width="15%" align='center'>Bid</Index.TableCell>
                            <Index.TableCell className='table-top-th common-table-th' width="10%" align='center'>Action</Index.TableCell>
                        </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className='table-top-body common-table-body'>
                        <Index.TableRow className='table-top-row common-table-row'>
                            <Index.TableCell className='table-top-td common-table-td' width="5%" align='left'>
                                <Index.Box className='action-select'>
                                    <Index.Checkbox />
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="15%" align='left'>
                                00123
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="15%" align='left'>
                                India expo steels pvt
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="25%" align='center'>
                                <Index.List className='table-list'>
                                    <Index.ListItem className='table-list-item'>
                                        Dimensions (6 X 6)
                                    </Index.ListItem>
                                    <Index.ListItem className='table-list-item'>
                                        Qty (50 pieces)
                                    </Index.ListItem>
                                </Index.List>
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="15%" align='center'>
                                10/08/2023
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="15%" align='center'>
                                <Index.Box className="bid-value">
                                    INR 50,0000
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="10%" align='center'>
                                <Index.Button
                                    className='action-btn'
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                </Index.Button>
                                <Index.Menu
                                    className='action-btn-menu'
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    transformOrigin={{
                                        horizontal: 'center',
                                    }}
                                >
                                    <Index.MenuItem onClick={handleClose}>Preview</Index.MenuItem>
                                    <Index.MenuItem onClick={handleClose}>Add comment</Index.MenuItem>
                                    <Index.MenuItem onClick={handleClose}>Cancel</Index.MenuItem>
                                </Index.Menu>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='table-top-row common-table-row'>
                            <Index.TableCell className='table-top-td common-table-td' width="5%" align='left'>
                                <Index.Box className='action-select'>
                                    <Index.Checkbox />
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="15%" align='left'>
                                00123
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="15%" align='left'>
                                India expo steels pvt
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="25%" align='center'>
                                <Index.List className='table-list'>
                                    <Index.ListItem className='table-list-item'>
                                        Dimensions (6 X 6)
                                    </Index.ListItem>
                                    <Index.ListItem className='table-list-item'>
                                        Qty (50 pieces)
                                    </Index.ListItem>
                                </Index.List>
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="15%" align='center'>
                                10/08/2023
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="15%" align='center'>
                                <Index.Box className="bid-value">
                                    INR 50,0000
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='table-top-td common-table-td' width="10%" align='center'>
                                <Index.Button className='action-btn'>
                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                </Index.Button>
                            </Index.TableCell>
                        </Index.TableRow>
                    </Index.TableBody>
                </Index.Table>
            </Index.TableContainer>
            <Index.TableContainer className="table-bottom-container common-table-container cus-scrollbar">
                <Index.Table aria-label="simple table" className='table-bottom common-table action-common-table'>
                    <Index.TableHead className='table-bottom-head common-table-head'>
                        <Index.TableRow className='table-bottom-row common-table-row'>
                            <Index.TableCell className='table-bottom-th common-table-th' width="5%" align='left'></Index.TableCell>
                            <Index.TableCell className='table-bottom-th common-table-th' width="12%" align='left'>Enquiry no.</Index.TableCell>
                            <Index.TableCell className='table-bottom-th common-table-th' width="14%" align='left'>Brand Name</Index.TableCell>
                            <Index.TableCell className='table-bottom-th common-table-th' width="20%" align='center'>Requirements</Index.TableCell>
                            <Index.TableCell className='table-bottom-th common-table-th' width="10%" align='center'>Date</Index.TableCell>
                            <Index.TableCell className='table-bottom-th common-table-th' width="14%" align='center'>Bid</Index.TableCell>
                            <Index.TableCell className='table-bottom-th common-table-th' width="17%" align='center'>Reverse Auction</Index.TableCell>
                            <Index.TableCell className='table-bottom-th common-table-th' width="8%" align='center'>Action</Index.TableCell>
                        </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className='table-bottom-body common-table-body'>
                        <Index.TableRow>
                            <Index.TableCell width="100%" colSpan="8" sx={{ padding: 0 }} className='action-common-table-td'>
                                <Index.Table>
                                    <Index.TableRow className='table-bottom-row common-table-row'>
                                        <Index.TableCell className='table-bottom-td common-table-td' width="5%" align='left'>
                                            <Index.Box className='action-select'>
                                                <Index.Checkbox />
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='table-bottom-td common-table-td' width="12%" align='left'>
                                            00123
                                        </Index.TableCell>
                                        <Index.TableCell className='table-bottom-td common-table-td' width="14%" align='left'>
                                            India expo steels pvt
                                        </Index.TableCell>
                                        <Index.TableCell className='table-bottom-td common-table-td' width="20%" align='center'>
                                            <Index.List className='table-list'>
                                                <Index.ListItem className='table-list-item'>
                                                    Dimensions (6 X 6)
                                                </Index.ListItem>
                                                <Index.ListItem className='table-list-item'>
                                                    Qty (50 pieces)
                                                </Index.ListItem>
                                            </Index.List>
                                        </Index.TableCell>
                                        <Index.TableCell className='table-bottom-td common-table-td' width="10%" align='center'>
                                            10/08/2023
                                        </Index.TableCell>
                                        <Index.TableCell className='table-bottom-td common-table-td' width="14%" align='center'>
                                            <Index.Box className="bid-value">
                                                INR 6,00,0000
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='table-bottom-td common-table-td' width="17%" align='center'>
                                            <Index.Box className="ra-value">
                                                TATA steel <br />
                                                INR  49,00,0000
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='table-bottom-td common-table-td' width="8%" align='center'>
                                            <Index.Button className='action-btn'>
                                                <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                            </Index.Button>
                                        </Index.TableCell>
                                    </Index.TableRow>
                                    <Index.TableRow>
                                        <Index.TableCell className='table-bottom-td common-table-td table-row-action-td' width="100%" colSpan="8">
                                            <Index.Box className="table-row-action">
                                                <PagesIndex.OrangeFillButton btnLabel="Accept" className="orange-fill-btn" />
                                                <PagesIndex.OrangeOutlineButton btnLabel="Add Comment" className="orange-outline-inner" />
                                            </Index.Box>
                                        </Index.TableCell>
                                    </Index.TableRow>
                                </Index.Table>
                            </Index.TableCell>
                        </Index.TableRow>
                    </Index.TableBody>
                </Index.Table>
            </Index.TableContainer>
        </Index.Box>

    )

}

import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'
import { useNavigate } from 'react-router-dom';

export default function ViewInvoice() {

  const navigate = useNavigate();

  const invoiceManagement = () => {
    navigate("/admin/invoice-management");
  };

  return (
    <>
      <Index.Box className="view-invoice">
        <Index.Box className="flex-all admin-title-box">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title admin-buyer-title"
              component="h3"
              variant="h3"
            >
              View Invoice
            </Index.Typography>
          </Index.Box>
          <Index.Box className="view-btn-main border-btn-main">
            <PagesIndex.BlueborderButton
              className="cancel-btn blue-border-btn"
              btnLabel="Back"
              onClick={() => invoiceManagement()}
            />
            <PagesIndex.BlueFillButton
              className="view-order-btn blue-fill-btn"
              btnLabel="Download"
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="common-grid-main">
          <Index.Box className="invoice-box">
            <img src={PagesIndex.Jpg.invoiceSample} alt="Invoice" />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}

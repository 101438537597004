import React from 'react'

export default function PlusIcon() {
    return (

        <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3362 18.128H0.355469V13.8011H13.3362V0.820312H17.6632V13.8011H30.6439V18.128H17.6632V31.1088H13.3362V18.128Z" fill="#27447C" />
        </svg>

    )
}

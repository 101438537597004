import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useLocation, useNavigate } from "react-router-dom";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

export default function ViewProduct() {
  const location = useLocation();
  const row = location?.state?.row;
  const navigate = useNavigate();

  const [singleProduct, setSingleProduct] = useState({});
  console.log(singleProduct, 9);

  const getSingleProduct = async () => {
    await DataService.get(`${Api.Admin.GET_SINGLE_PRODUCT}?id=${row}`)
      .then((res) => {
        console.log(res, 18);
        setSingleProduct(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getSingleProduct();
  }, []);

  const removeImageTags = (html) => {
    console.log(html, 31);
    return html?.replace(/<(table|figure|[^>]+)>/g, "");
  };

  const backBtn = () => {
    navigate("/admin/product-management");
  };

  return (
    <Index.Box className="view-product">
      <Index.Box className="flex-all admin-title-box">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            className="admin-sub-title admin-buyer-title"
            component="h3"
            variant="h3"
          >
            View Product
          </Index.Typography>
        </Index.Box>
        <Index.Box className="view-btn-main border-btn-main">
          <PagesIndex.BlueborderButton
            className="cancel-btn blue-border-btn"
            btnLabel="Back"
            onClick={backBtn}
          />
        </Index.Box>
      </Index.Box>
      <Index.Box className="common-grid-main">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 4 }}
            className="login-row"
          >
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Product Name :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {singleProduct ? singleProduct?.name : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Quantity :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {singleProduct ? singleProduct?.quantity : 0}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Brand :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {/* {singleProduct ? singleProduct?.brand : "-"} */}
                  {singleProduct
                    ? singleProduct?.brand?.map((row, index) => {
                        return <>{row ? row?.name : "-"}</>;
                      })
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Dimension :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {singleProduct ? singleProduct?.dimention : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Thickness :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {/* {singleProduct ? singleProduct?.thickness : 0} */}
                  {singleProduct
                    ? singleProduct?.thickness?.map((row, index) => {
                        return <>{row ? row?.name : "-"}</>;
                      })
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Type :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {/* {singleProduct ? singleProduct?.type : "-"} */}
                  {singleProduct
                    ? singleProduct?.type?.map((row, index) => {
                        return <>{row ? row?.name : "-"}</>;
                      })
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Grad :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {/* {singleProduct ? singleProduct?.grad : "-"} */}

                  {singleProduct
                    ? singleProduct?.grad?.map((row, index) => {
                        return <>{row ? row?.name : "-"}</>;
                      })
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Application :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {/* {singleProduct ? singleProduct?.application : "-"} */}
                  {singleProduct
                    ? singleProduct?.application?.map((row, index) => {
                        return <>{row ? row?.name : "-"}</>;
                      })
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Payment :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {singleProduct ? singleProduct?.payment : "-"}
                  {/* {singleProduct
                    ? singleProduct?.payment?.map((row, index) => {
                        return <>{row ? row?.name : "-"}</>;
                      })
                    : "-"} */}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Category :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {/* {singleProduct ? singleProduct?.productCategoryId : "-"} */}
                  {singleProduct
                    ? singleProduct?.productCategoryId?.map((row, index) => {
                        return <>{row ? row?.name : "-"}</>;
                      })
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Sub Category :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {/* {singleProduct ? singleProduct?.productSubCategoryId : "-"} */}
                  {singleProduct
                    ? singleProduct?.productSubCategoryId?.map((row, index) => {
                        return <>{row ? row?.name : "-"}</>;
                      })
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Specification :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {singleProduct
                    ? removeImageTags(singleProduct?.specification)
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-view-detail-group">
                <Index.Typography className="comman-view-detail-label">
                  Description :
                </Index.Typography>
                <Index.Typography className="comman-view-detail-value">
                  {singleProduct
                    ? removeImageTags(singleProduct?.description)
                    : "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xxs: "span 12",
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="comman-form-group">
                <Index.FormHelperText className="comman-form-label">
                  Images :
                </Index.FormHelperText>
                <Index.Box className="common-edit-image-box">
                  {singleProduct
                    ? singleProduct?.image?.map((row, index) => {
                        return (
                          <Index.Box className="common-edit-image">
                            {console.log(row, "image")}

                            <img
                              // src={PagesIndex.Jpg.productImg1}
                              // http://192.168.29.136:3042/images/1702384812253AutoBattler.jpg
                              // src={`http://194.233.77.156:3042/images/${singleProduct?.image}`}
                              src={`http://194.233.77.156:3042/images/${row}`}
                              alt="product"
                              className="product-img"
                            />
                          </Index.Box>
                        );
                      })
                    : ""}

                  {/* <Index.Box className="common-edit-image">
                    <img
                      src={PagesIndex.Jpg.productImg1}
                      alt="product"
                      className="product-img"
                    />
                  </Index.Box>
                  <Index.Box className="common-edit-image">
                    <img
                      src={PagesIndex.Jpg.productImg1}
                      alt="product"
                      className="product-img"
                    />
                  </Index.Box>
                  <Index.Box className="common-edit-image">
                    <img
                      src={PagesIndex.Jpg.productImg1}
                      alt="product"
                      className="product-img"
                    />
                  </Index.Box>
                  <Index.Box className="common-edit-image">
                    <img
                      src={PagesIndex.Jpg.productImg1}
                      alt="product"
                      className="product-img"
                    />
                  </Index.Box> */}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

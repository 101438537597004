import React from 'react'
import Index from '../../Index';
import PagesIndex from '../../PagesIndex';
import './contactusmodal.css'
import './contactusmodal.responsive.css'

// for modal design 

const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
};


export default function ContactUsModal(props) {
      const { openContactUsModal, handleCloseContactUsModal } = props


      // ContactUsModal modal
      // const [openContactUsModal, setOpenContactUsModal] = React.useState(false);
      // const handleOpenContactUsModal = () => setOpenContactUsModal(true);
      // const handleCloseContactUsModal = () => setOpenContactUsModal(false);

      return (
            <>

                  {/* <Index.Button onClick={handleOpenContactUsModal}>Open modal</Index.Button> */}
                  <Index.Modal
                        open={openContactUsModal}
                        onClose={handleCloseContactUsModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className='modal-common-main'
                  >
                        <Index.Box sx={style} className="contact-modal-inner-main modal-common-style">
                              <Index.Box className="cus-scrollbar modal-hgt-scroll">
                                    <Index.Box className="modal-header">
                                          <Index.Typography className='contact-modal-header-title' component="h3" variant="h3" >Contact us</Index.Typography>
                                          <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseContactUsModal} />
                                    </Index.Box>
                                    <Index.Box className="modal-body">
                                          <Index.Box className="contact-content-inner">
                                                <Index.Typography className='contact-modal-para' component="p" variant="p">You can directly call us on +91 7589025567 or drop a message. We will revert to your issue shortly.</Index.Typography>
                                                <Index.Box className="contact-content-main">
                                                      <Index.Box className="contact-content-box">
                                                            <Index.Box className="contact-content-flex comman-form-group">
                                                                  <Index.Box className="contact-content-left-main">
                                                                        <Index.FormHelperText className='comman-form-label'>Full Name<span className='astric-sign'>*</span></Index.FormHelperText>
                                                                  </Index.Box>
                                                                  <Index.Box className="contact-content-right-main">
                                                                        <Index.Box className="contact-input-main">
                                                                              <Index.Box className="comman-form-group">
                                                                                    <Index.TextField className="comman-form-control" placeholder='e.g., John' />
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="contact-content-box">
                                                            <Index.Box className="contact-content-flex comman-form-group">
                                                                  <Index.Box className="contact-content-left-main">
                                                                        <Index.FormHelperText className='comman-form-label'>Email<span className='astric-sign'>*</span></Index.FormHelperText>
                                                                  </Index.Box>
                                                                  <Index.Box className="contact-content-right-main">
                                                                        <Index.Box className="contact-input-main">
                                                                              <Index.Box className="comman-form-group">
                                                                                    <Index.TextField className="comman-form-control" placeholder='name@company.com' />
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="contact-content-box">
                                                            <Index.Box className="contact-content-flex comman-form-group">
                                                                  <Index.Box className="contact-content-left-main">
                                                                        <Index.FormHelperText className='comman-form-label'>Phone Number<span className='astric-sign'>*</span></Index.FormHelperText>
                                                                  </Index.Box>
                                                                  <Index.Box className="contact-content-right-main">
                                                                        <Index.Box className="contact-input-main">
                                                                              <Index.Box className="comman-form-group">
                                                                                    <Index.TextField className="comman-form-control" placeholder='+1 555 655 5656' />
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="contact-content-box">
                                                            <Index.Box className="contact-content-flex comman-form-group">
                                                                  <Index.Box className="contact-content-left-main">
                                                                        <Index.FormHelperText className='comman-form-label'>What is your concern<span className='astric-sign'>*</span></Index.FormHelperText>
                                                                  </Index.Box>
                                                                  <Index.Box className="contact-content-right-main">
                                                                        <Index.Box className="contact-input-main">
                                                                              <Index.Box className="form-group">
                                                                                    <Index.TextareaAutosize
                                                                                          aria-label="minimum height"
                                                                                          placeholder="Tell us about your concern and provide some details which can help us serve you in a better way."
                                                                                          className="form-control-textarea"
                                                                                    />
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                      <Index.Box className="contact-content-box">
                                                            <Index.Box className="contact-content-flex comman-form-group">
                                                                  <Index.Box className="contact-content-left-main"></Index.Box>
                                                                  <Index.Box className="contact-content-right-main">
                                                                        <Index.Box className="contact-submit-btn-main">
                                                                              <PagesIndex.BlueFillButton className='blue-fill-btn' btnLabel="Submit" />
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                                    {/* <Index.Box className="modal-footer-main"></Index.Box> */}
                              </Index.Box>
                        </Index.Box>
                  </Index.Modal >
            </>
      )
}

import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

export default function Faq() {
  return (
    <Index.Box className="main-faq" component="main">
      <PagesIndex.BannerImage
        bannerImage={PagesIndex.Jpg.aboutBanner}
        bannerTitle="FAQ"
        bannerContent="Lorem ipsum dolor sit amet consectetur."
      />
      <PagesIndex.FaqMain />
    </Index.Box>
  )
}

import React from 'react'
import Index from '../../Index'

export default function BlueborderButton(props) {
  return (
      <>
      <Index.Box className='blue-border-btn-main'>
        <Index.Button className={props.className} onClick={props.onClick}>{props.btnLabel}</Index.Button>
      </Index.Box>


      {/* use this button like below demo */}
      {/* <PagesIndex.BlueborderButton btnLabel="View Button" claasName="blue-border-btn"/> */}
    </>
  )
}

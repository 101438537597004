import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

export default function About() {
  return (
    <Index.Box className="main-about" component="main">
      <PagesIndex.BannerImage
        bannerImage={PagesIndex.Jpg.aboutBanner}
        bannerTitle="About us"
        bannerContent="Lorem ipsum dolor sit amet consectetur."
      />
      <PagesIndex.WhoWeAre />
      <PagesIndex.Blogssection />
      <PagesIndex.NumbersTalk />
      <PagesIndex.TheTeam />
      <PagesIndex.JoinTeam />
    </Index.Box>
  );
}

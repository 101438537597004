const Api = {
  Admin: {
    Auth: {
      adminLogin: "/admin/login",
      forgotPassword: "/admin/forgot-password",
      verifyOtp: "admin/verify-otp",
      resetPassword: "admin/reset-password",
    },
    GET_ALL_SELLER: "admin/get-all-seller-list",
    GET_ALL_BUYER: "admin/get-all-buyer-list",
    APPROVE_REJECT_BUYER: "/admin/approve-reject-buyer",
    APPROVE_REJECT_SELLER: "/admin/approve-reject-seller",
    ACTIVE_DEACTIVE_SELLER_BUYER: "/admin/active-deactive-seller-buyer",
    UPDATE_SELLER: "/admin/update-seller-by-admin",
    UPDATE_BUYER: "/admin/update-buyer-by-admin",
    CREATE_BUYER_SELLER: "/admin/create-seller-buyer",
    GET_SINGLE_BUYER: "/admin/get-buyer",
    GET_SINGLE_SELLER: "/admin/get-seller",
    GET_BLOG: "/admin/get-all-blog",
    ADD_BLOG: "/admin/create-blog",
    GET_ALL_SUB_CATEGORY: "/admin/get-all-subcategory",
    GET_ALL_CATEGORY: "/admin/get-all-category",
    GET_ALL_TAGS: "/admin/get-all-tags",
    CREATE_CATEGORY: "/admin/create-category",
    CREATE_SUB_CATEGORY: "/admin/create-subcategory",
    CREATE_TAGS: "/admin/create-tags",
    DELETE_BLOG: "/admin/delete-blog",
    UPDATE_BLOG: "/admin/update-blog",
    GET_SINGLE_DATA: "/admin/get-blog",
    GET_PRODUCT: "/admin/get-all-product",
    GET_SINGLE_PRODUCT: "/admin/get-single-product",
    DELETE_PRODUCT: "/admin/delete-product",
    CREATE_PRODUCT: "/admin/product-create",
    GET_ALL_ORDER: "/admin/get-all-order-detail",
    DELETE_ORDER: "/admin/delete-order-detail",
    UPDATE_ORDER_DETAIL: "/admin/add-edit-order-detail",
    GET_SINGLE_ORDER: "/admin/get-Single-order-detail",

    // drop down
    GET_BRANDS: "/admin/get-brand",
    GET_TYPES: "/admin/get-type",
    GET_GRADS: "/admin/get-grad",
    GET_THICKNESS: "/admin/get-thickness",
    GET_APPLICATION: "/admin/get-application",
    GET_PRODUCT_CATEGORY: "/admin/get-all-productCategory",
    GET_PRODUCT_SUB_CATEGORY: "/admin/get-all-product-subcategory",
  },
  User: {
    Auth: {
      VERIFY_NUMBER: "/user/send-verification-sms",
      MATCH_VERIFY_NUMBER: "/user/verify-phonenumber",
      MATCH_VERIFY_EMAIL: "/user/email-otp-verify",
      VERIFY_MAIL: "/user/verify-email",
      REGISTER_USER: "/user/create-update-role",
      USER_LOGIN: "/user/login",
      FORGOT_PASSWORD: "/user/forgot-password",
      RESET_PASSWORD: "/user/reset-password",
      SEND_OTP: "user/verify-otp",
    },
    VERIFICATION_BUYER_SELLER: "/user/seller-or-buyer-details-verify-web",
    RATING_BUYER_SELLER: "/user/add-buyer-edit-rating-review",
  },
};
export { Api };

import React, { useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import "./auth.css";
import "./auth.responsive.css";
import { Form, Formik } from "formik";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
import { toast } from "react-toastify";
import * as Yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function Register({
  handleCloseRegisterModal,
  openRegisterModal,
  handleOpenLoginModal,
}) {
  const [radioValue, setRadioValue] = useState("buyer");
  const handleRadioChange = (newValue, e) => {
    setRadioValue(e);
  };
  const [emailDisable, setEmailDisable] = useState(true);
  // password
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Confirm password
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const [verifyNum, setVerifyNum] = useState({});
  const [verifyEmail, setVerifyEmail] = useState({});
  const initialValues = {
    fullName: "",
    phoneNumber: "",
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
    termsAndConditions: false,
    hasGST: false,
    numVerify: false,
    matchNumVerify: false,
    mailVerify: false,
    emailVerify: false,
    matchEmailVerify: false,
  };
  const verifyNumber = async (values) => {
    const data = {
      phoneNumber: values,
    };
    await DataService.post(Api.User.Auth.VERIFY_NUMBER, data)
      .then((res) => {
        setVerifyNum(res?.data?.data?.data);
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const verifyMail = async (values, setFieldValue) => {
    const data = {
      email: values,
      id: verifyNum?.id,
    };
    await DataService.post(Api.User.Auth.VERIFY_MAIL, data)
      .then((res) => {
        toast.success(res?.data?.message);
        setVerifyEmail(res?.data?.data);
        setFieldValue("otp", "");
        setFieldValue("matchNumVerify", "");
        setFieldValue("emailVerify", true);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  const matchNumber = async (values, setFormValue) => {
    const data = {
      otp: values.otp,
      id: verifyNum?.id,
    };
    const data2 = {
      otp: values.otp,
      email: values.email,
      id: verifyNum?.id,
    };

    if (values.email) {
      await DataService.post(Api.User.Auth.MATCH_VERIFY_EMAIL, data2)
        .then((res) => {
          setEmailDisable(false);
          toast.success(res?.data?.message);
          setFormValue("numVerify", true);
          if (values.email) {
            setFormValue("matchEmailVerify", true);
          }
        })
        .catch((error) => {
          console.log(error, 86);
        });
    } else {
      await DataService.post(Api.User.Auth.MATCH_VERIFY_NUMBER, data)
        .then((res) => {
          setEmailDisable(false);
          toast.success(res?.data?.message);
          setFormValue("numVerify", true);
          setFormValue("matchNumVerify", true);
        })
        .catch((error) => {
          console.log(error, 86);
        });
    }
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .matches(/^[a-zA-Z\s-, ]+$/, "Accept only alphabetic and white space")
      .required("Please enter your full name"),
    email: Yup.string()
      .email("Please enter your valid email")
      .matches(
        /^[a-z][a-z0-9]*(?:\.[a-z]+)*@[a-z]+\.[a-z]{2,}$/,
        "Please enter your valid email"
      )
      .required("Please enter your email address"),
    password: Yup.string()
      .required("Please enter password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password")],
        "Password and Confirmed passwords do not match"
      ),
    otp: Yup.string().required("OTP is required"),
    phoneNumber: Yup.string().required("Please enter your phone number"),

    termsAndConditions: Yup.boolean().oneOf(
      [true],
      "Terms and conditions is required"
    ),
    hasGST: Yup.boolean().oneOf([true], "GST is required"),
  });
  const handleFormSubmit = async (values) => {
    const data = {
      id: verifyNum?.id,
      fullName: values.fullName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      password: values.password,
      confirmPassword: values.confirmPassword,
      role: radioValue,
      termsAndConditions: values.termsAndConditions,
      hasGST: values.hasGST,
    };
    await DataService.post(Api.User.Auth.REGISTER_USER, data)
      .then((res) => {
        toast.success(res?.data?.message);
        if (res?.data?.status === 200) {
          handleCloseRegisterModal();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Index.Modal
        open={openRegisterModal}
        onClose={handleCloseRegisterModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="register-modal-inner-main auth-modal-inner modal-common-style"
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={validationSchema}
          >
            {({
              handleSubmit,
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Index.Box className=" cus-scrollbar modal-hgt-scroll">
                  <Index.Box className="modal-header">
                    <Index.Typography className="auth-modal-title">
                      Registration
                    </Index.Typography>
                    <img
                      src={PagesIndex.Svg.closeicon}
                      className="modal-close-icon"
                      onClick={handleCloseRegisterModal}
                    />
                  </Index.Box>
                  <Index.Box className="modal-body">
                    <Index.Box className="auth-content-main">
                      <Index.Typography className="register-title-main">
                        User Type
                      </Index.Typography>
                      <Index.Box className="auth-radio-main">
                        <Index.FormControl className="radio-form-control">
                          <Index.RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Buyer"
                            name="radio-buttons-group"
                            className="radio-group-main"
                            value={radioValue}
                            onChange={handleRadioChange}
                          >
                            <Index.FormControlLabel
                              className="form-control-label"
                              value="buyer"
                              control={<Index.Radio />}
                              label="Buyer"
                            />
                            <Index.FormControlLabel
                              className="form-control-label"
                              value="seller"
                              control={<Index.Radio />}
                              label="Seller"
                            />
                          </Index.RadioGroup>
                        </Index.FormControl>
                      </Index.Box>
                      <Index.Box className="register-content-main">
                        <Index.Typography className="register-title-main">
                          Contact Information
                        </Index.Typography>
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                            className="login-row"
                          >
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  Full Name
                                  <span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="auth-input-main">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="test"
                                    name="fullName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.fullName}
                                  />
                                </Index.Box>
                                {errors.fullName && touched.fullName && (
                                  <p className="error-text">
                                    {errors.fullName}
                                  </p>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  Phone Number
                                  <span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="auth-input-main">
                                  <Index.TextField
                                    type="number"
                                    className="comman-form-control"
                                    placeholder=""
                                    name="phoneNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                  />
                                  <Index.Typography
                                    className="comman-verify-link"
                                    onClick={() => {
                                      !verifyNum?.otp &&
                                        verifyNumber(values.phoneNumber);
                                    }}
                                  >
                                    {values.numVerify ? "Verified" : "Verify"}
                                  </Index.Typography>
                                </Index.Box>
                                {errors.phoneNumber && touched.phoneNumber && (
                                  <p className="error-text">
                                    {errors.phoneNumber}
                                  </p>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  Email Address
                                  <span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="auth-input-main">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    disabled={emailDisable}
                                  />
                                  <Index.Typography
                                    className="comman-verify-link"
                                    onClick={() => {
                                      if (!values.matchEmailVerify) {
                                        verifyMail(values.email, setFieldValue);
                                      }
                                    }}
                                  >
                                    {values.matchEmailVerify
                                      ? "Verified"
                                      : "Verify"}
                                  </Index.Typography>
                                </Index.Box>
                                {errors.email && touched.email && (
                                  <p className="error-text">{errors.email}</p>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  Enter OTP
                                </Index.FormHelperText>
                                <Index.Box className="auth-input-main">
                                  <Index.OutlinedInput
                                    type="number"
                                    className="comman-form-control"
                                    placeholder=""
                                    name="otp"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    value={values.otp}
                                    endAdornment={
                                      <Index.InputAdornment position="end">
                                        {values.matchNumVerify ||
                                        values.matchEmailVerify ? (
                                          <img
                                            src={PagesIndex.Svg.grentick}
                                            className="otp-gren-tick"
                                            alt="verify"
                                          />
                                        ) : (
                                          <Index.Typography
                                            className="comman-verify-otp-link"
                                            onClick={() => {
                                              if (
                                                !values.matchNumVerify ||
                                                !values.matchEmailVerify
                                              ) {
                                                matchNumber(
                                                  values,
                                                  setFieldValue
                                                );
                                              }
                                            }}
                                          >
                                            Verify
                                          </Index.Typography>
                                        )}
                                      </Index.InputAdornment>
                                    }
                                  />
                                </Index.Box>
                                {errors.otp && touched.otp && (
                                  <p className="error-text">{errors.otp}</p>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="register-content-main">
                        <Index.Typography className="register-title-main">
                          Access credentials
                        </Index.Typography>
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                            className="login-row"
                          >
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  Create password
                                </Index.FormHelperText>
                                <Index.Box className="auth-input-main">
                                  <Index.OutlinedInput
                                    className="comman-form-control"
                                    autocomplete="off"
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    value={values?.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    inputProps={{ maxlength: 16 }}
                                    endAdornment={
                                      <Index.InputAdornment position="end">
                                        <Index.IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showPassword ? (
                                            <Index.VisibilityOff />
                                          ) : (
                                            <Index.Visibility />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    }
                                  />
                                </Index.Box>
                                {errors.password && touched.password && (
                                  <p className="error-text">
                                    {errors.password}
                                  </p>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xxs: "span 12",
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  Confirm new password
                                </Index.FormHelperText>
                                <Index.Box className="auth-input-main">
                                  <Index.OutlinedInput
                                    className="comman-form-control"
                                    autocomplete="off"
                                    id="outlined-adornment-password"
                                    type={
                                      showConfirmPassword ? "text" : "password"
                                    }
                                    name="confirmPassword"
                                    value={values?.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    inputProps={{ maxlength: 16 }}
                                    endAdornment={
                                      <Index.InputAdornment position="end">
                                        <Index.IconButton
                                          aria-label="toggle password visibility"
                                          onClick={
                                            handleClickShowConfirmPassword
                                          }
                                          onMouseDown={
                                            handleMouseDownConfirmPassword
                                          }
                                          edge="end"
                                        >
                                          {showConfirmPassword ? (
                                            <Index.VisibilityOff />
                                          ) : (
                                            <Index.Visibility />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    }
                                  />
                                </Index.Box>
                                {errors.confirmPassword &&
                                  touched.confirmPassword && (
                                    <p className="error-text">
                                      {errors.confirmPassword}
                                    </p>
                                  )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="terms-gst-checkbox comman-form-group">
                          <Index.Box className="terms-checkbox">
                            <Index.FormControlLabel
                              control={<Index.Checkbox />}
                              label="Terms and conditions"
                              checked={values.termsAndConditions}
                              onChange={handleChange}
                              name="termsAndConditions"
                            />
                            {errors.termsAndConditions &&
                              touched.termsAndConditions && (
                                <p className="error-text">
                                  {errors.termsAndConditions}
                                </p>
                              )}
                          </Index.Box>
                          <Index.Box className="gst-checkbox comman-form-group">
                            <Index.FormControlLabel
                              control={<Index.Checkbox />}
                              label="Do you have a GST available?"
                              checked={values.hasGST}
                              onChange={handleChange}
                              name="hasGST"
                            />
                            {errors.hasGST && touched.hasGST && (
                              <p className="error-text">{errors.hasGST}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="modal-auth-btn-main">
                      <PagesIndex.OrangeFillButton
                        btnLabel="Create account"
                        className="orange-fill-btn auth-modal-btn"
                        type="submit"
                      />
                    </Index.Box>
                    <Index.Box className="dont-para-main">
                      <Index.Typography
                        className="dont-modal-para"
                        component="p"
                        variant="p"
                      >
                        Already have an account?{" "}
                        <span
                          className="auth-redirect-link"
                          onClick={() => {
                            handleOpenLoginModal();
                            handleCloseRegisterModal();
                          }}
                        >
                          Login
                        </span>
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-footer"></Index.Box>
                </Index.Box>
              </Form>
            )}
          </Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

import React, { useState } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'
import { useNavigate } from 'react-router-dom';

function InvoiceTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box>
          <Index.Box>{children}</Index.Box>
        </Index.Box>
      )}
    </div>
  );
}

InvoiceTabPanel.propTypes = {
  children: Index.PropTypes.node,
  index: Index.PropTypes.number.isRequired,
  value: Index.PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function InvoiceManagement() {

  const navigate = useNavigate();

  const generateInvoice = () => {
    navigate("/admin/generate-invoice");
  };

  const viewInvoice = () => {
    navigate("/admin/view-invoice");
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Index.Box className="invoice-management">
        <Index.Box className="flex-all admin-title-box">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title admin-buyer-title"
              component="h3"
              variant="h3"
            >
              Invoice Management
            </Index.Typography>
          </Index.Box>
          <Index.Box className="view-btn-main border-btn-main">
            <PagesIndex.BlueFillButton
              className="view-order-btn blue-fill-btn"
              btnLabel="Generate"
              onClick={() => generateInvoice()}
            />
          </Index.Box>
        </Index.Box>
        <Index.Tabs className="rating-tab-button common-tab-button" value={value} onChange={handleChange} aria-label="basic tabs example">
          <Index.Tab label="Buyers" {...a11yProps(0)} />
          <Index.Tab label="Sellers" {...a11yProps(1)} />
        </Index.Tabs>
        <InvoiceTabPanel value={value} index={0} className="buyer-tab common-tab">
          <Index.Box className="page-order-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container cus-scrollbar"
            >
              <Index.Table
                sx={{ minWidth: 1000 }}
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="20%"
                    >
                      Invoice No.
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="25%"
                    >
                      Invoice Date
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="20%"
                    >
                      Status
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="15%"
                      align="center"
                    >
                      Download
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="20%"
                      align="center"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  <Index.TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="table-td"
                    >
                      #1234
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      20-12-2023
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      <Index.Box
                        sx={{ color: "white", backgroundColor: "darkorange" }}
                        className="buyer-list-status"
                      >
                        Pending
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      align='center'
                      className="table-td"
                    >
                      <Index.Button className='table-download-icon'>
                        <img src={PagesIndex.Svg.downloadicon} className='download-icon' alt='download sample' />
                      </Index.Button>
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                      align="center"
                    >
                      <Index.Box sx={{ padding: "0px", margin: "0px" }}>
                        <Index.IconButton onClick={() => viewInvoice()}>
                          <Index.Visibility
                            style={{ color: "black" }}
                          />
                        </Index.IconButton>
                        <Index.IconButton>
                          <img
                            src={PagesIndex.Svg.editingIcon}
                            alt="edit icon"
                            width="20"
                            height="20"
                          />
                        </Index.IconButton>
                        <Index.IconButton>
                          <Index.DeleteOutlineIcon
                            style={{ color: "black" }}
                          />
                        </Index.IconButton>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="table-td"
                    >
                      #1234
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      20-12-2023
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      <Index.Box
                        sx={{ color: "white", backgroundColor: "green" }}
                        className="buyer-list-status"
                      >
                        Pending
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      align='center'
                      className="table-td"
                    >
                      <Index.Button className='table-download-icon'>
                        <img src={PagesIndex.Svg.downloadicon} className='download-icon' alt='download sample' />
                      </Index.Button>
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                      align="center"
                    >
                      <Index.Box sx={{ padding: "0px", margin: "0px" }}>
                        <Index.IconButton onClick={() => viewInvoice()}>
                          <Index.Visibility
                            style={{ color: "black" }}
                          />
                        </Index.IconButton>
                        <Index.IconButton>
                          <img
                            src={PagesIndex.Svg.editingIcon}
                            alt="edit icon"
                            width="20"
                            height="20"
                          />
                        </Index.IconButton>
                        <Index.IconButton>
                          <Index.DeleteOutlineIcon
                            style={{ color: "black" }}
                          />
                        </Index.IconButton>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
            <Index.TablePagination
              className="cus-scrollbar"
              component="div"
            />
          </Index.Box>
        </InvoiceTabPanel>
        <InvoiceTabPanel value={value} index={1} className="buyer-tab common-tab">
          <Index.Box className="page-order-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container cus-scrollbar"
            >
              <Index.Table
                sx={{ minWidth: 1000 }}
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="20%"
                    >
                      Invoice No.
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="25%"
                    >
                      Invoice Date
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="20%"
                    >
                      Status
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="15%"
                      align="center"
                    >
                      Download
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      style={{ fontWeight: 700 }}
                      width="20%"
                      align="center"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  <Index.TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="table-td"
                    >
                      #1234
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      20-12-2023
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                    >
                      <Index.Box
                        sx={{ color: "white", backgroundColor: "green" }}
                        className="buyer-list-status"
                      >
                        Pending
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      align='center'
                      className="table-td"
                    >
                      <Index.Button className='table-download-icon'>
                        <img src={PagesIndex.Svg.downloadicon} className='download-icon' alt='download sample' />
                      </Index.Button>
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      className="table-td"
                      align="center"
                    >
                      <Index.Box sx={{ padding: "0px", margin: "0px" }}>
                        <Index.IconButton onClick={() => viewInvoice()}>
                          <Index.Visibility
                            style={{ color: "black" }}
                          />
                        </Index.IconButton>
                        <Index.IconButton>
                          <img
                            src={PagesIndex.Svg.editingIcon}
                            alt="edit icon"
                            width="20"
                            height="20"
                          />
                        </Index.IconButton>
                        <Index.IconButton>
                          <Index.DeleteOutlineIcon
                            style={{ color: "black" }}
                          />
                        </Index.IconButton>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
            <Index.TablePagination
              className="cus-scrollbar"
              component="div"
            />
          </Index.Box>
        </InvoiceTabPanel>
      </Index.Box>
    </>
  )
}

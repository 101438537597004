import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'

export default function SellerMyAddress() {
      return (
            <>
                  <Index.Box className="my-address-section my-account-sections">
                        <Index.Box className="my-address-title-box">
                              <Index.Box className="my-address-title-flex">
                                    <Index.Typography className="my-address-title" component="h4" variant="h4" >Add address</Index.Typography>
                                    <Index.Box className="my-address-btn-flex">
                                          <Index.Typography className="my-address-change-text" component="p" variant="p" >Undo changes</Index.Typography>
                                          <PagesIndex.OrangeFillButton btnLabel="Save" className="orange-fill-btn my-address-save-btn" />
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                        <Index.Box className='my-address-content-row'>
                              <Index.Box className="grid-main">
                                    <Index.Box className="verify-grid-row">
                                          <Index.Box gridColumn={{ md: "span 2", xxs: "span 1" }} className="grid-column">
                                                <Index.Box className="my-address-input-main">
                                                      <Index.Box className="comman-form-group">
                                                            <Index.TextField className="comman-form-control" placeholder='Flat, House, No., Building, Comapany*' />
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="grid-column">
                                                <Index.Box className="my-address-input-main">
                                                      <Index.Box className="comman-form-group">
                                                            <Index.TextField className="comman-form-control" placeholder='Area, Street, Sector, Village' />
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="grid-column">
                                                <Index.Box className="my-address-input-main">
                                                      <Index.Box className="comman-form-group">
                                                            <Index.TextField className="comman-form-control" placeholder='Landmark' />
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="grid-column">
                                                <Index.Box className="my-address-input-main">
                                                      <Index.Box className="comman-form-group">
                                                            <Index.TextField className="comman-form-control" placeholder='Pincode' />
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="grid-column">
                                                <Index.Box className="my-address-input-main">
                                                      <Index.Box className="comman-form-group">
                                                            <Index.TextField className="comman-form-control" placeholder='City/District/Town*' />
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="grid-column">
                                                <Index.Box className="my-address-input-main">
                                                      <Index.Box className="comman-form-group">
                                                            <Index.FormHelperText className='comman-form-label' >State</Index.FormHelperText>
                                                            <Index.TextField className="comman-form-control" placeholder='' />
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="grid-column">
                                                <Index.Box className="my-address-input-main">
                                                      <Index.Box className="comman-form-group">
                                                            <Index.FormHelperText className='comman-form-label' >Country</Index.FormHelperText>
                                                            <Index.TextField className="comman-form-control" placeholder='' />
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>

            </>
      )
}

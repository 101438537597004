import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'
import "./productlisting.css"
import './productlisting.responsive.css'
import { useNavigate } from 'react-router-dom'

// for modal design 

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
};



export default function ProductListing() {

  // GotoVerification modal
  const [openGotoVerification, setOpenGotoVerification] = React.useState(false);
  const handleOpenGotoVerification = () => setOpenGotoVerification(true);
  const handleCloseGotoVerification = () => setOpenGotoVerification(false);

  const navigate = useNavigate()

  return (
    <>
      <Index.Box className="main-product-listing" component="main">
        <PagesIndex.BannerImage
          bannerImage={PagesIndex.Jpg.aboutBanner}
          bannerTitle="Steel"
          bannerContent="Lorem ipsum dolor sit amet consectetur."
        />
        <Index.Box className="product-listing-section">
          <Index.Box className="cus-container">
            <Index.Box className="product-listing-row">
              <Index.Box className="product-col-left">
                <Index.Box className="product-left-content-main">
                  <Index.Box className="product-listing-title-flex">
                    <Index.Typography className='filter-title-text' component="h2" variant='h2'>Filters</Index.Typography>
                    <Index.Typography className='filter-clear-text' component="h3" variant='h3'>Clear filters</Index.Typography>
                  </Index.Box>
                  <Index.Box className="product-drop-flex">
                    <Index.List className='product-drop-ul'>
                      <Index.ListItem className='prodcut-drop-li'>
                        <PagesIndex.CheckBoxDropdown />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-drop-li'>
                        <PagesIndex.CheckBoxDropdown />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-drop-li'>
                        <PagesIndex.CheckBoxDropdown />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-drop-li'>
                        <PagesIndex.CheckBoxDropdown />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-drop-li'>
                        <PagesIndex.CheckBoxDropdown />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-drop-li'>
                        <PagesIndex.CheckBoxDropdown />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-drop-li'>
                        <PagesIndex.CheckBoxDropdown />
                      </Index.ListItem>
                    </Index.List>
                  </Index.Box>
                  <Index.Box className="product-brand-main">
                    <Index.List className='product-brand-ul'>
                      <Index.ListItem className='prodcut-brand-li'>
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-brand-li'>
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-brand-li'>
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-brand-li'>
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-brand-li'>
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-brand-li'>
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-brand-li'>
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-brand-li'>
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className='prodcut-brand-li'>
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                    </Index.List>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-col-right">
                <Index.Box className="product-right-content-main">
                  <Index.Box className="product-added-card common-card">
                    <Index.Typography className='product-added-text'>Items added</Index.Typography>
                    <Index.Box className="product-added-flex">
                      <Index.Typography className='product-item-name' component="h4" variant="h4">Round steel pipe 6”...</Index.Typography>
                      <Index.Typography className='product-item-remove-text' component="p" variant="p">Remove</Index.Typography>
                    </Index.Box>
                    <Index.Box className="product-added-flex">
                      <Index.Typography className='product-item-name' component="h4" variant="h4">Stainless steel pipe 10”...</Index.Typography>
                      <Index.Typography className='product-item-remove-text' component="p" variant="p">Remove</Index.Typography>
                    </Index.Box>
                    <Index.Box className="product-added-flex">
                      <Index.Typography className='product-item-name' component="h4" variant="h4">Raw steel pipe 9”...</Index.Typography>
                      <Index.Typography className='product-item-remove-text' component="p" variant="p">Remove</Index.Typography>
                    </Index.Box>
                    <Index.Box className="product-added-flex">
                      <Index.Typography className='product-item-name' component="h4" variant="h4">Square steel pipe 12”...</Index.Typography>
                      <Index.Typography className='product-item-remove-text' component="p" variant="p">Remove</Index.Typography>
                    </Index.Box>
                    <PagesIndex.OrangeFillButton btnLabel="Go to cart" onClick={() => {
                      navigate("/my-cart")
                    }} className="orange-fill-btn go-tocart-btn" />
                  </Index.Box>
                  <Index.Box className="product-bulk-card common-card">
                    <Index.Box className="bulk-order-flex">
                      <Index.Typography className='bulk-order-text' component="h3" variant="h3">Bulk order</Index.Typography>
                      <Index.Link className='download-sample-text'><img src={PagesIndex.Svg.downloadicon} className='download-icon' alt='download sample' />Download sample<span className='download-info-icon-main'><img src={PagesIndex.Svg.downinfo} className='down-info-icon' /></span></Index.Link>
                    </Index.Box>
                    <Index.Box className="choose-file-main">
                      <Index.TextField className='choose-file-control' type='file' placeholder='Choose file' />
                      <Index.Box className="choose-file-cust-main">
                        <Index.Box className="choose-file-cust-flex">
                          <Index.Box className="choose-file-left-main">
                            <Index.Typography className="choose-file-text" component="p" variant="p">Choose file</Index.Typography>
                          </Index.Box>
                          <Index.Box className="choose-file-right-main">
                            <Index.Typography className="choose-browse-text" component="p" variant="p">Browse file</Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="upload-blue-main">
                      <Index.Button className='upload-blue-btn'><img src={PagesIndex.Svg.uploadfileicon} alt='upload file' className='upload-file-icon' />Upload</Index.Button>
                    </Index.Box>
                    <Index.Typography className='payment-term-text' component="p" variant="p">Payment term</Index.Typography>
                    <Index.Box className="payment-tem-drop-main">
                      <PagesIndex.DropDown />
                    </Index.Box>
                    <PagesIndex.GrayFillButton btnLabel="Send enquiry" className="gray-fill-btn gray-send-inquery-btn" />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <PagesIndex.YouMayLike />
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openGotoVerification}
        onClose={handleCloseGotoVerification}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='modal-common-main'
      >
        <Index.Box sx={style} className="goto-veryfication-modal-inner-main cus-scrollbar modal-hgt-scroll modal-common-style">
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseGotoVerification} />
            </Index.Box>
            <Index.Box className="modal-logo-main">
              <img src={PagesIndex.Svg.logo} className='modal-logo' />
            </Index.Box>
            <Index.Typography className='veryfy-modal-para' component="p" variant='p'>Your account is not verified please
              verify your account</Index.Typography>
            <Index.Box className="modal-veryfy-btn-main">
              <PagesIndex.BlueFillButton btnLabel="Go to account verification" className="blue-fill-btn veryfy-modal-btn" />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  )
}

import React from "react";
import Index from "../../Index";

export default function OrangeFillButton(props) {
  return (
    <>
      <Index.Box className="orange-fill-btn-main">
        <Index.Button
          className={props.className}
          onClick={props.onClick}
          type={props.type}
          disableRipple
          {...props}
        >
          {props.btnLabel}
        </Index.Button>
      </Index.Box>

      {/* use this button like below demo */}
      {/* <PagesIndex.OrangeFillButton btnLabel="View Button" className="orange-fill-btn"/> */}
    </>
  );
}

import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

export default function Blogs() {
  return (
    <Index.Box className="main-blogs" component="main">
      <PagesIndex.Breadcrumb />
      <PagesIndex.RecentBlogs />
      <PagesIndex.LatestPosts />
      <PagesIndex.LatestPostsAll />
    </Index.Box>
  )
}
import React, { useState } from 'react'
import Index from '../../../Index'
import './faqMain.css'
import './faqMain.responsive.css'

function FaqTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box>
          <Index.Box>{children}</Index.Box>
        </Index.Box>
      )}
    </div>
  );
}

FaqTabPanel.propTypes = {
  children: Index.PropTypes.node,
  index: Index.PropTypes.number.isRequired,
  value: Index.PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FaqMain() {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const topicItemBuyer = [
    {
      title: "Topic 1",
      id: "B1",
      children: [
        {
          question: "Buyer Question 1",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Buyer Question 2",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Buyer Question 3",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
      ],
    },
    {
      title: "Topic 2",
      id: "B2",
      children: [
        {
          question: "Buyer Question 4",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Buyer Question 5",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Buyer Question 6",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
      ],
    },
    {
      title: "Topic 3",
      id: "B3",
      children: [
        {
          question: "Buyer Question 7",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Buyer Question 8",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Buyer Question 9",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
      ],
    },
  ]

  const topicItemSeller = [
    {
      title: "Topic 1",
      id: "S1",
      children: [
        {
          question: "Seller Question 1",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Seller Question 2",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Seller Question 3",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
      ],
    },
    {
      title: "Topic 2",
      id: "S2",
      children: [
        {
          question: "Seller Question 4",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Seller Question 5",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Seller Question 6",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
      ],
    },
    {
      title: "Topic 3",
      id: "S3",
      children: [
        {
          question: "Seller Question 7",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Seller Question 8",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
        {
          question: "Seller Question 9",
          answer: "At Stelmart, we understand the challenges and complexities that can arise when searching for and procuring steel and polymer products. That's why we've developed a user-friendly platform that streamlines the entire process. Whether you're looking for specific dimensions, grades, or coatings, our platform provides a seamless experience to help you find the right product for your needs.",
        },
      ],
    },
  ]

  return (
    <Index.Box className="cus-container">
      <Index.Box className="main-faq-wrapper">
        <Index.Box className="sidebar-tab-desktop">
          <Index.Tabs className="sidebar-tab-button" value={value} onChange={handleChange} aria-label="basic tabs example">
            <Index.Tab label="Buyer" {...a11yProps(0)} />
            <Index.Tab label="Seller" {...a11yProps(1)} />
          </Index.Tabs>
          <FaqTabPanel value={value} index={0} className="buyer-tab common-tab cus-scrollbar">
            {topicItemBuyer.map((item, key) => (
              <Index.Button
                key={key}
                onClick={(() => {
                  const faqElement = document.getElementById(item.id);
                  faqElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
                })}
                className="tab-button buyer-tab-button"
              >
                {item.title}
              </Index.Button>
            ))}
          </FaqTabPanel>
          <FaqTabPanel value={value} index={1} className="seller-tab common-tab cus-scrollbar">
            {topicItemSeller.map((item, key) => (
              <Index.Button
                key={key}
                onClick={(() => {
                  const faqElement = document.getElementById(item.id);
                  faqElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
                })}
                className="tab-button seller-tab-button"
              >
                {item.title}
              </Index.Button>
            ))}
          </FaqTabPanel>
        </Index.Box>
        <Index.Box className="question-section">
          <Index.Box className="faq-search-box">
            <Index.Input
              autoFocus
              className="faq-search"
              placeholder="Search"
            />
            <img src={Index.Svg.greysearch} className='faq-search-icon' alt='search icon' />
          </Index.Box>
          <Index.Box className="topic-item-wrapper">
            <FaqTabPanel value={value} index={0}>
              {topicItemBuyer.map((item, key) => (
                <Index.Box key={key} className="topic-item" id={item.id}>
                  <Index.Box className="topic-item-title-box">
                    <Index.Typography variant='span' component="span" className="topic-item-title">
                      {item.title}
                    </Index.Typography>
                  </Index.Box>
                  {item.children.map((res, key) => (
                    <Index.Accordion key={key} className="topic-item-box">
                      <Index.AccordionSummary
                        expandIcon={<Index.ExpandMoreIcon />}
                      >
                        <Index.Box className="topic-question">
                          {res.question}
                        </Index.Box>
                      </Index.AccordionSummary>
                      <Index.AccordionDetails>
                        <Index.Box className="topic-answer">
                          {res.answer}
                        </Index.Box>
                      </Index.AccordionDetails>
                    </Index.Accordion>
                  ))}
                </Index.Box>
              ))}
            </FaqTabPanel>
            <FaqTabPanel value={value} index={1}>
              {topicItemSeller.map((item, key) => (
                <Index.Box key={key} className="topic-item" id={item.id}>
                  <Index.Box className="topic-item-title-box">
                    <Index.Typography variant='span' component="span" className="topic-item-title">
                      {item.title}
                    </Index.Typography>
                  </Index.Box>
                  {item.children.map((res, key) => (
                    <Index.Accordion key={key} className="topic-item-box">
                      <Index.AccordionSummary
                        expandIcon={<Index.ExpandMoreIcon />}
                      >
                        <Index.Box className="topic-question">
                          {res.question}
                        </Index.Box>
                      </Index.AccordionSummary>
                      <Index.AccordionDetails>
                        <Index.Box className="topic-answer">
                          {res.answer}
                        </Index.Box>
                      </Index.AccordionDetails>
                    </Index.Accordion>
                  ))}
                </Index.Box>
              ))}
            </FaqTabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )

}
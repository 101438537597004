import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function AddPayment() {

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  return (
    <>
      <Index.Box className="add-payment">
        <Index.Box className="flex-all admin-title-box">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title admin-buyer-title"
              component="h3"
              variant="h3"
            >
              Add Payment
            </Index.Typography>
          </Index.Box>
          <Index.Box className="view-btn-main border-btn-main">
            <PagesIndex.BlueborderButton
              className="cancel-btn blue-border-btn"
              btnLabel="Back"
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="common-grid-main">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 4 }}
              className="login-row"
            >
              <Index.Box
                gridColumn={{
                  xxs: "span 12",
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="comman-form-group">
                  <Index.FormHelperText className="comman-form-label">
                    Select User
                  </Index.FormHelperText>
                  <Index.Box className="auth-input-main">
                    <Index.FormControl className="radio-form-control">
                      <Index.RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="buyer"
                        name="radio-buttons-group"
                        className="radio-group-main"
                      >
                        <Index.FormControlLabel
                          className="form-control-label"
                          value="buyer"
                          control={<Index.Radio />}
                          label="Buyer"
                        />
                        <Index.FormControlLabel
                          className="form-control-label"
                          value="seller"
                          control={<Index.Radio />}
                          label="Seller"
                        />
                      </Index.RadioGroup>
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xxs: "span 12",
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="comman-form-group">
                  <Index.FormHelperText className="comman-form-label">
                    Date
                  </Index.FormHelperText>
                  <Index.Box className="auth-input-main">
                    <Index.TextField
                      className="comman-form-control"
                      placeholder=""
                      type='date'
                      name="orderAmount"
                      defaultValue={date}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xxs: "span 12",
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="comman-form-group">
                  <Index.FormHelperText className="comman-form-label">
                    Transaction ID
                  </Index.FormHelperText>
                  <Index.Box className="auth-input-main">
                    <Index.TextField
                      className="comman-form-control"
                      placeholder=""
                      name="orderAmount"
                      value="159528"
                      disabled
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xxs: "span 12",
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="comman-form-group">
                  <Index.FormHelperText className="comman-form-label">
                    Transaction Amount
                  </Index.FormHelperText>
                  <Index.Box className="auth-input-main">
                    <Index.TextField
                      className="comman-form-control"
                      placeholder=""
                      name="orderAmount"
                      value="5000"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xxs: "span 12",
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="comman-form-group">
                  <Index.FormHelperText className="comman-form-label">
                    Payment Mode
                  </Index.FormHelperText>
                  <Index.Box className="order-status-drop-main">
                    <PagesIndex.DropDown />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-footer-btn-box">
          <PagesIndex.BlueborderButton
            className="cancel-btn blue-border-btn"
            btnLabel="Cancel"
          />
          <PagesIndex.BlueFillButton
            className="view-order-btn blue-fill-btn"
            btnLabel="Submit"
          />
        </Index.Box>
      </Index.Box>
    </>
  )
}

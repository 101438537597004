import React from 'react'
import Index from '../../Index'
import PagesIndex from '../../PagesIndex'

export default function CatalogueCard() {
  return (
    <Index.Box className="catalogue-card-main">
      <Index.Link to="#" className="catalogue-card-link">
        <Index.Box className="you-may-img-box">
          <Index.Box className="you-may-overlay"></Index.Box>
          <img src={PagesIndex.Png.brandimg} className='you-may-img' />
          <Index.Typography className='you-may-product-name'>Name</Index.Typography>
        </Index.Box>
        <Index.Box className="you-may-like-content-box">
          <Index.Box className="you-may-flex">
            <Index.Typography className='you-may-para'>Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet </Index.Typography>
            <Index.Box className="you-may-right-icon-main">
              <img src={PagesIndex.Svg.roundarrow} className='you-may-round-arrow' />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Link>
    </Index.Box>
  )
}

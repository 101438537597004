import React from 'react'
import Index from '../../Index'

function Stepper() {
    return (
        <>

            <Index.Box className="stepper-max-content-details cus-scrollbar">
                <Index.Box className="stepper-list">
                    <Index.List className='stepper-list-details'>
                        <Index.ListItem className='stepper-listitem-details stepper-active-order'>
                            <Index.Box className="stpper-content-details">
                                <Index.Box className="border-stepper-green">
                                    <Index.Box className="white-border-stepper"></Index.Box>
                                </Index.Box>
                                <Index.Typography className="stpper-name-title">Order placed</Index.Typography>
                                <Index.Typography className="stpper-traker-date">11/10/23</Index.Typography>
                            </Index.Box>
                        </Index.ListItem>
                        <Index.ListItem className='stepper-listitem-details stepper-active-order'>
                            <Index.Box className="stpper-content-details">
                                <Index.Box className="border-stepper-green">
                                    <Index.Box className="white-border-stepper"></Index.Box>
                                </Index.Box>
                                <Index.Typography className="stpper-name-title">Manufacturing</Index.Typography>
                                <Index.Typography className="stpper-traker-date">24/10/23</Index.Typography>
                            </Index.Box>
                        </Index.ListItem>
                        <Index.ListItem className='stepper-listitem-details stepper-active-order'>
                            <Index.Box className="stpper-content-details">
                                <Index.Box className="border-stepper-green">
                                    <Index.Box className="white-border-stepper"></Index.Box>
                                </Index.Box>
                                <Index.Typography className="stpper-name-title">Packed</Index.Typography>
                                <Index.Typography className="stpper-traker-date">24/10/23</Index.Typography>
                            </Index.Box>
                        </Index.ListItem>
                        <Index.ListItem className='stepper-listitem-details'>
                            <Index.Box className="stpper-content-details">
                                <Index.Box className="border-stepper-green">
                                    <Index.Box className="white-border-stepper"></Index.Box>
                                </Index.Box>
                                <Index.Typography className="stpper-name-title">Dispatched</Index.Typography>
                                <Index.Typography className="stpper-traker-date">11/10/23</Index.Typography>
                            </Index.Box>
                        </Index.ListItem>
                        <Index.ListItem className='stepper-listitem-details'>
                            <Index.Box className="stpper-content-details">
                                <Index.Box className="border-stepper-green">
                                    <Index.Box className="white-border-stepper"></Index.Box>
                                </Index.Box>
                                <Index.Typography className="stpper-name-title">Delivered</Index.Typography>
                                <Index.Typography className="stpper-traker-date">11/10/23</Index.Typography>
                            </Index.Box>
                        </Index.ListItem>
                    </Index.List>
                    
                </Index.Box>
            </Index.Box>


        </>
    )
}

export default Stepper
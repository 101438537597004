import React, { useState } from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import './quotationRecieved.css'
import './quotationRecieved.responsive.css'
import { styled } from '@mui/material/styles';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function QuotationRecieved() {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openUploadPo, setOpenUploadPo] = useState(false);
    const handleOpenUploadPo = () => setOpenUploadPo(true);
    const handleCloseUploadPo = () => setOpenUploadPo(false);

    const [openProductPreview, setOpenProductPreview] = useState(false);
    const handleOpenProductPreview = () => setOpenProductPreview(true);
    const handleCloseProductPreview = () => setOpenProductPreview(false);

    return (
        <Index.Box className="quotation-recieved-main">
            <Index.Box className="quotation-header dashboard-common-header">
                <Index.Box className="header-title">
                    Quotation recieved
                </Index.Box>
                <Index.Box className="header-search">
                    <Index.Input
                        autoFocus
                        className="header-search-input"
                        placeholder="Search quotations"
                    />
                    <img src={Index.Svg.greysearch} className='search-icon' alt='search icon' />
                </Index.Box>
            </Index.Box>
            <Index.TableContainer className="quotation-table-container common-table-container cus-scrollbar">
                <Index.Table aria-label="simple table" className='quotation-table common-table action-common-table'>
                    <Index.TableHead className='quotation-table-head common-table-head'>
                        <Index.TableRow className='quotation-table-row common-table-row'>
                            <Index.TableCell className='quotation-table-th common-table-th' width="15%" align='left'>Enquiry no.</Index.TableCell>
                            <Index.TableCell className='quotation-table-th common-table-th' width="15%" align='left'>Brand Name</Index.TableCell>
                            <Index.TableCell className='quotation-table-th common-table-th' width="25%" align='center'>Requirements</Index.TableCell>
                            <Index.TableCell className='quotation-table-th common-table-th' width="15%" align='center'>Date</Index.TableCell>
                            <Index.TableCell className='quotation-table-th common-table-th' width="15%" align='center'>Bid</Index.TableCell>
                            <Index.TableCell className='quotation-table-th common-table-th' width="15%" align='center'>Action</Index.TableCell>
                        </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className='quotation-table-body common-table-body'>
                        <Index.TableRow>
                            <Index.TableCell width="100%" colSpan="6" sx={{ padding: 0 }} className='action-common-table-td'>
                                <Index.Table>
                                    <Index.TableRow className='quotation-table-row common-table-row'>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="15%" align='left'>
                                            00123
                                        </Index.TableCell>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="15%" align='left'>
                                            India expo steels pvt
                                        </Index.TableCell>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="25%" align='center'>
                                            <Index.List className='table-list'>
                                                <Index.ListItem className='table-list-item'>
                                                    Dimensions (6 X 6)
                                                </Index.ListItem>
                                                <Index.ListItem className='table-list-item'>
                                                    Qty (50 pieces)
                                                </Index.ListItem>
                                            </Index.List>
                                        </Index.TableCell>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="15%" align='center'>
                                            10/08/2023
                                        </Index.TableCell>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="15%" align='center'>
                                            <Index.Box className="bid-value">
                                                INR 50,00,000
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="15%" align='center'>
                                            <Index.Button
                                                className='action-btn'
                                                id="basic-button"
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleClick}
                                            >
                                                <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                            </Index.Button>
                                            <Index.Menu
                                                className='action-btn-menu'
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                                transformOrigin={{
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <Index.MenuItem onClick={handleOpenUploadPo}>Preview</Index.MenuItem>
                                                <Index.MenuItem onClick={handleOpenProductPreview}>Request Auction</Index.MenuItem>
                                            </Index.Menu>
                                        </Index.TableCell>
                                    </Index.TableRow>
                                    <Index.TableRow>
                                        <Index.TableCell className='quotation-table-td common-table-td table-row-action-td' width="100%" colSpan="6">
                                            <Index.Box className="table-row-action">
                                                <PagesIndex.OrangeFillButton btnLabel="Accept" className="orange-fill-btn" />
                                                <PagesIndex.OrangeOutlineButton btnLabel="Add Comment" className="orange-outline-inner" />
                                            </Index.Box>
                                        </Index.TableCell>
                                    </Index.TableRow>
                                </Index.Table>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow>
                            <Index.TableCell width="100%" colSpan="6" sx={{ padding: 0 }} className='action-common-table-td'>
                                <Index.Table>
                                    <Index.TableRow className='quotation-table-row common-table-row'>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="15%" align='left'>
                                            00123
                                        </Index.TableCell>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="15%" align='left'>
                                            India expo steels pvt
                                        </Index.TableCell>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="25%" align='center'>
                                            <Index.List className='table-list'>
                                                <Index.ListItem className='table-list-item'>
                                                    Dimensions (6 X 6)
                                                </Index.ListItem>
                                                <Index.ListItem className='table-list-item'>
                                                    Qty (50 pieces)
                                                </Index.ListItem>
                                            </Index.List>
                                        </Index.TableCell>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="15%" align='center'>
                                            10/08/2023
                                        </Index.TableCell>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="15%" align='center'>
                                            <Index.Box className="bid-value">
                                                INR 50,00,000
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='quotation-table-td common-table-td' width="15%" align='center'>
                                            <Index.Button className='action-btn'>
                                                <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                            </Index.Button>
                                        </Index.TableCell>
                                    </Index.TableRow>
                                    <Index.TableRow>
                                        <Index.TableCell className='quotation-table-td common-table-td table-row-action-td' width="100%" colSpan="6">
                                            <Index.Box className="table-row-action">
                                                <PagesIndex.OrangeFillButton btnLabel="Accept" className="orange-fill-btn" />
                                                <PagesIndex.OrangeOutlineButton btnLabel="Add Comment" className="orange-outline-inner" />
                                            </Index.Box>
                                        </Index.TableCell>
                                    </Index.TableRow>
                                </Index.Table>
                            </Index.TableCell>
                        </Index.TableRow>
                    </Index.TableBody>
                </Index.Table>
            </Index.TableContainer>
            <Index.Modal
                className="modal-common-main upload-po-details"
                open={openUploadPo}
                onClose={handleCloseUploadPo}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Index.Box sx={style} className="modal-common-style">
                    <Index.Box className="cus-scrollbar modal-hgt-scroll">
                        <Index.Box className="comman-modal-inner-title">
                            <Index.Typography component='p' variant='p' className="comman-modal-title">Upload PO Details</Index.Typography>
                            <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseUploadPo} />
                        </Index.Box>
                        <Index.Box className="upload-po-details-content">
                            <Index.Box className="enter-details-main">
                                <Index.Box className="comman-form-group">
                                    <Index.FormHelperText className='comman-form-label'>PO No.</Index.FormHelperText>
                                    <Index.TextField className="comman-form-control" placeholder='PO Number' />
                                </Index.Box>
                                <Index.Box className="comman-form-group">
                                    <Index.FormHelperText className='comman-form-label'>PO Date</Index.FormHelperText>
                                    <Index.TextField className="comman-form-control" placeholder='PO Date' />
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="payment-upload-details">
                                <Index.Box className="payment-upload-title">
                                    <Index.Typography className="upload-pay-title">Upload PO</Index.Typography>
                                </Index.Box>
                                <Index.Box className="upload-img-file-modal">
                                    <Index.Button component="label" variant="contained" className='upload-label-details' >
                                        <Index.Box className="upload-main-content">
                                            <img src={PagesIndex.Png.cloud} className='upload-cloud' alt="cloud"></img>
                                        </Index.Box>
                                        <Index.Typography className="browse-cloud-text">Browse Files to upload</Index.Typography>
                                        <Index.Typography className="browse-cloud-accepted">(accepted JPG, PNG & PDF only)</Index.Typography>
                                        <VisuallyHiddenInput type="file" />
                                    </Index.Button>
                                </Index.Box>
                                <Index.Box className="upload-mt-details">
                                    <Index.Box className="upload-document-show">
                                        <Index.Box className="upload-document-card">
                                            <Index.Box className="file-icon-name">
                                                <img src={PagesIndex.Png.descriptionupload} className='description-upload' alt="desc"></img>
                                                <Index.Typography className="file-payment-name">File 001 PO</Index.Typography>
                                            </Index.Box>
                                            <Index.Button className="btn-delete-file" disableRipple>
                                                <img src={PagesIndex.Png.deleteupload} className="delete-details" alt="delete"></img>
                                            </Index.Button>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="btnupload-payment-footer">
                            <Index.Box className="orangefill-btn-confirm btn-comman-mrl">
                                <PagesIndex.OrangeFillButton btnLabel="Confirm" className="orange-fill-btn" />
                            </Index.Box>
                            <Index.Box className="orange-outline-payment btn-comman-mrl">
                                <PagesIndex.OrangeOutlineButton btnLabel="Cancel" className="orange-outline-inner cancel-payment" />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>
            <Index.Modal
                className="modal-common-main product-preview-modal"
                open={openProductPreview}
                onClose={handleCloseProductPreview}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Index.Box sx={style} className="modal-common-style">
                    <Index.Box className="cus-scrollbar modal-hgt-scroll">
                        <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseProductPreview} />
                        <Index.Box className="product-preview-top">
                            <img src={PagesIndex.Png.cart} alt='product' className='product-preview-img' />
                            <Index.Box className="product-preview-desc">
                                <Index.Box className="product-preview-title">
                                    Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection
                                </Index.Box>
                                <Index.Box className="product-preview-content">
                                    Discription <br />
                                    orem ipsum dolor sit amet consectetur. Amet tincidunt fringilla erat varius massa tempus velit hac dolor. Pellentesque praesent viverra at quis vitae id. Eros ultrices nunc ipsum sit tincidunt nisl egestas nibh. Id enim purus consectetur in
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="product-preview-bottom">
                            <Index.Box className="product-preview-detail common-view-details-group">
                                <Index.Box className="product-preview-label common-label-title">
                                    Enquiry no.
                                </Index.Box>
                                <Index.Box className="product-preview-label common-details-show">
                                    Enquiry no.
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="product-preview-detail common-view-details-group">
                                <Index.Box className="product-preview-label common-label-title">
                                    Vendor
                                </Index.Box>
                                <Index.Box className="product-preview-label common-details-show">
                                    TATA steel
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="product-preview-detail common-view-details-group">
                                <Index.Box className="product-preview-label common-label-title">
                                    Specifications
                                </Index.Box>
                                <Index.Box className="product-preview-label common-details-show">
                                    Dimensions (6×6)
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="product-preview-detail common-view-details-group">
                                <Index.Box className="product-preview-label common-label-title">
                                    Date
                                </Index.Box>
                                <Index.Box className="product-preview-label common-details-show">
                                    24/10/2023
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="product-preview-detail common-view-details-group">
                                <Index.Box className="product-preview-label common-label-title">
                                    Bid
                                </Index.Box>
                                <Index.Box className="product-preview-label common-details-show">
                                    $ 6,00,000
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="product-preview-detail common-view-details-group">
                                <Index.Box className="product-preview-label common-label-title">
                                    Status
                                </Index.Box>
                                <Index.Box className="product-preview-label common-details-show">
                                    Confirm
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="product-preview-detail common-view-details-group">
                                <Index.Box className="product-preview-label common-label-title">
                                    Quantity
                                </Index.Box>
                                <Index.Box className="product-preview-label common-details-show">
                                    40,000 Kg
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="product-preview-detail common-view-details-group">
                                <Index.Box className="product-preview-label common-label-title">
                                    Material
                                </Index.Box>
                                <Index.Box className="product-preview-label common-details-show">
                                    Steel
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="product-preview-detail common-view-details-group">
                                <Index.Box className="product-preview-label common-label-title">
                                    Supplier
                                </Index.Box>
                                <Index.Box className="product-preview-label common-details-show">
                                    <Index.List className='product-Supplier-list'>
                                        <Index.ListItem className='product-Supplier-list-item'>
                                            Supplier 1
                                            <Index.Box className="Supplier-amount-box">
                                                <Index.Typography component="span" variant='span' className="Supplier-amount-label">
                                                    Amount : &nbsp;
                                                </Index.Typography>
                                                <Index.Typography component="span" variant='span' className="Supplier-amount-value">
                                                    $60,00,000
                                                </Index.Typography>
                                            </Index.Box>
                                        </Index.ListItem>
                                        <Index.ListItem className='product-Supplier-list-item'>
                                            Supplier 1
                                            <Index.Box className="Supplier-amount-box">
                                                <Index.Typography component="span" variant='span' className="Supplier-amount-label">
                                                    Amount : &nbsp;
                                                </Index.Typography>
                                                <Index.Typography component="span" variant='span' className="Supplier-amount-value">
                                                    $60,00,000
                                                </Index.Typography>
                                            </Index.Box>
                                        </Index.ListItem>
                                        <Index.ListItem className='product-Supplier-list-item'>
                                            Supplier 1
                                            <Index.Box className="Supplier-amount-box">
                                                <Index.Typography component="span" variant='span' className="Supplier-amount-label">
                                                    Amount : &nbsp;
                                                </Index.Typography>
                                                <Index.Typography component="span" variant='span' className="Supplier-amount-value">
                                                    $60,00,000
                                                </Index.Typography>
                                            </Index.Box>
                                        </Index.ListItem>
                                    </Index.List>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>
        </Index.Box>

    )

}

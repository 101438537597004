import React from 'react'
import Index from '../../Index'
import PagesIndex from '../../PagesIndex'
import './youmaylike.css'

export default function YouMayLike() {
  return (
    <Index.Box className="main-you-may-like">
      <Index.Box className="cus-container">
        <Index.Box className="you-may-like-section">
          <Index.Box className="page-title-main">
            <Index.Typography className='page-title'>You may also like</Index.Typography>
          </Index.Box>
          <Index.Box className="you-may-like-content-main">
            <Index.List className="you-may-like-ul">
              <Index.ListItem className='you-may-like-li'>
                <PagesIndex.CatalogueCard />
              </Index.ListItem>
              <Index.ListItem className='you-may-like-li'>
                <PagesIndex.CatalogueCard />
              </Index.ListItem>
              <Index.ListItem className='you-may-like-li'>
                <PagesIndex.CatalogueCard />
              </Index.ListItem>
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import './whoWeAre.css'
import './whoWeAre.responsive.css'

export default function WhoWeAre() {
    return (
        <Index.Box className="who-we-are" component="section">
            <Index.Box className="cus-container">
                <Index.Box className="top-section">
                    <Index.Box className="title">
                        Who We Are
                    </Index.Box>
                    <Index.Typography className="content">
                        Lorem ipsum dolor sit amet consectetur. Pretium varius mattis massa tellus amet. Nibh pulvinar turpis etiam eget posuere eu nam turpis. Ligula scelerisque consectetur nunc nulla. Laoreet mi tincidunt commodo in morbi magna tempor. Leo lectus non commodo nisi quam. Vulputate nullam aliquam euismod elit tempus duis. Viverra dignissim ullamcorper blandit aliquam. Urna non ornare velit eget cum turpis sed ut tellus. Aliquet tincidunt ut porta amet blandit tincidunt viverra a in. Feugiat enim in arcu vivamus nulla velit blandit mauris. Dolor velit mattis tincidunt vitae lacus in.
                    </Index.Typography>
                </Index.Box>
                <Index.Box className="image-section">
                    <img src={PagesIndex.Jpg.WhoWeAre} alt='Who We Are' />
                </Index.Box>
            </Index.Box>
        </Index.Box>
    )
}
import React from "react";
import Index from "../../../../Index";
import './helpsection.css'
import './helpsection.responsive.css'

function Helpsection() {
  return (
    <Index.Box className="help-wrape" component="section">
      <Index.Box className="cus-container">
        <Index.Typography component="h5">
          How can we help you?
        </Index.Typography>
        <Index.Typography component="p">
          <Index.Link to="/coming-soon">Get in touch</Index.Link> with us or find an <Index.Link to="/coming-soon">office</Index.Link> closest to you.
        </Index.Typography>
      </Index.Box>
    </Index.Box>
  )
}

export default Helpsection
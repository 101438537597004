import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useNavigate } from "react-router-dom";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import moment from "moment";
import { toast } from "react-toastify";

// for tabal data

const BuyerList = () => {
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [ediRowsData, setEdiRowsData] = useState({});
  const [viewRowsData, setViewRowsData] = useState({});
  const [buyerApproveReject, setBuyerApproveReject] = useState("");
  // add buyer
  const [openAddNewBuyerModal, setOpenAddNewBuyerModal] = React.useState(false);
  const handleOpenAddNewBuyerModal = () => setOpenAddNewBuyerModal(true);
  const handleCloseAddNewBuyerModal = () => setOpenAddNewBuyerModal(false);

  // edit buyer
  const [openEditBuyerModal, setOpenEditBuyerModal] = React.useState(false);
  const handleOpenEditBuyerModal = () => setOpenEditBuyerModal(true);
  const handleCloseEditBuyerModal = () => setOpenEditBuyerModal(false);

  // view buyer
  const [openViewBuyerModal, setOpenViewBuyerModal] = React.useState(false);
  const handleOpenViewBuyerModal = () => setOpenViewBuyerModal(true);
  const handleCloseViewBuyerModal = () => setOpenViewBuyerModal(false);

  // pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleView = (row) => {
    setViewRowsData(row);
    handleOpenViewBuyerModal();
  };
  const handleEdit = (row) => {
    console.log(row, 50);
    setEdiRowsData(row);
    handleOpenEditBuyerModal();
  };
  const handleApprove = async (row) => {
    if (row?.status === "Approved") {
      toast.warning("Buyer already approved");
    } else {
      const data = {
        id: row?._id,
        status: "Approved",
      };
      await DataService.post(Api.Admin.APPROVE_REJECT_BUYER, data)
        .then((res) => {
          toast.success(res?.data?.message);
          getBuyerList();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleReject = async (row) => {
    if (row?.status === "Rejected") {
      toast.warning("Buyer already rejected");
    } else {
      const data = {
        id: row?._id,
        status: "Rejected",
      };
      await DataService.post(Api.Admin.APPROVE_REJECT_BUYER, data)
        .then((res) => {
          toast.success(res?.data?.message);
          getBuyerList();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleStatus = async (row) => {
    const data = {
      id: row?._id,
      status: !row?.isActive,
    };
    await DataService.post(Api.Admin.ACTIVE_DEACTIVE_SELLER_BUYER, data)
      .then((res) => {
        toast.success(res?.data?.message);
        getBuyerList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBuyerList = async () => {
    setIsLoading(true);
    await DataService.get(Api.Admin.GET_ALL_BUYER)
      .then((res) => {
        setRows(res?.data?.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getBuyerList();
  }, [page, rowsPerPage]);

  if (isLoading) {
    return <PagesIndex.Loader />;
  } else {
    return (
      <>
        <Index.Box className="flex-all admin-title-box">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title admin-buyer-title"
              component="h3"
              variant="h3"
            >
              Buyer List
            </Index.Typography>
          </Index.Box>
          <Index.Box className="view-btn-main border-btn-main">
            <PagesIndex.BlueFillButton
              className="view-order-btn blue-fill-btn"
              btnLabel="Add New"
              onClick={handleOpenAddNewBuyerModal}
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="page-buyer-table-main">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container cus-scrollbar"
          >
            <Index.Table
              sx={{ minWidth: 1010 }}
              aria-label="simple table"
              className="table"
            >
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="15%"
                  >
                    Name
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="24%"
                  >
                    Email
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="14%"
                  >
                    Phone Number
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="12%"
                  >
                    Date
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="10%"
                  >
                    Status
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    align="center"
                    style={{ fontWeight: 700 }}
                    width="25%"
                  >
                    Action
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody className="table-body">
                {rows?.length > 0 ? (
                  rows
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => (
                      <Index.TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                        >
                          {row.fullName ? row.fullName : "-"}
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          {row.email ? row.email : "-"}
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          {row.phoneNumber ? row.phoneNumber : 0}
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          {row?.createdAt
                            ? moment(row?.createdAt).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )
                            : "-"}
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                        >
                          <Index.Box
                            sx={{
                              color: "white",
                              backgroundColor:
                                row?.status === "Rejected"
                                  ? "red"
                                  : row?.status === "Pending"
                                  ? "orange"
                                  : "green",
                            }}
                            className="buyer-list-status"
                          >
                            {row?.status ? row.status : "-"}
                          </Index.Box>
                        </Index.TableCell>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          className="table-td"
                          align="center"
                        >
                          <Index.Box sx={{ padding: "0px", margin: "0px" }}>
                            <Index.IconButton
                              // disabled={row?.status === "Approved"}
                              onClick={() => {
                                handleApprove(row);
                              }}
                            >
                              <Index.CheckIcon style={{ color: "green" }} />
                            </Index.IconButton>
                            <Index.IconButton
                              onClick={() => {
                                handleReject(row);
                              }}
                            >
                              <Index.ClearIcon style={{ color: "red" }} />
                            </Index.IconButton>
                            <Index.IconButton
                              onClick={() => {
                                handleEdit(row);
                              }}
                            >
                              <img
                                src={PagesIndex.Svg.editingIcon}
                                alt="edit icon"
                                width="20"
                                height="20"
                              />
                            </Index.IconButton>
                            <Index.IconButton
                              onClick={() => {
                                handleView(row);
                              }}
                            >
                              <PagesIndex.VisibilityIcon
                                style={{ color: "black" }}
                              />
                            </Index.IconButton>

                            <Index.IconButton
                              sx={{ width: "40px" }}
                              className="toggle-btn"
                              disableRipple
                            >
                              <Index.Switch
                                checked={row?.isActive && row?.isActive}
                                onClick={(e) => {
                                  handleStatus(row);
                                }}
                              />
                            </Index.IconButton>
                          </Index.Box>
                        </Index.TableCell>
                      </Index.TableRow>
                    ))
                ) : (
                  <Index.TableRow>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="table-td"
                      colSpan={6}
                    >
                      No Record found
                    </Index.TableCell>
                  </Index.TableRow>
                )}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
          {rows?.length > 0 ? (
            <>
              <Index.TablePagination
                className="cus-scrollbar"
                component="div"
                page={page}
                count={rows?.length}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 15, 20]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            ""
          )}
        </Index.Box>

        <PagesIndex.AddNewBuyer
          openAddNewBuyerModal={openAddNewBuyerModal}
          getBuyerList={getBuyerList}
          handleCloseAddNewBuyerModal={handleCloseAddNewBuyerModal}
        />
        <PagesIndex.EditBuyer
          openEditBuyerModal={openEditBuyerModal}
          rows={ediRowsData}
          getBuyerList={getBuyerList}
          handleCloseEditBuyerModal={handleCloseEditBuyerModal}
        />
        <PagesIndex.ViewBuyer
          openViewBuyerModal={openViewBuyerModal}
          rows={viewRowsData}
          handleCloseViewBuyerModal={handleCloseViewBuyerModal}
        />
      </>
    );
  }
};

export default BuyerList;

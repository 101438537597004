import React, { useState } from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import "./sellerConfirmedOrder.css"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

export default function SellerConfirmedOrder() {

    const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
    const handleOpenUpdateStatusModal = () => setOpenUpdateStatusModal(true);
    const handleCloseUpdateStatusModal = () => setOpenUpdateStatusModal(false);

    return (

        <Index.Box className="seller-confirmed-order-main">
            <Index.TableContainer className="seller-confirmed-order-table-container common-table-container cus-scrollbar">
                <Index.Table aria-label="simple table" className='seller-confirmed-order-table common-table action-common-table'>
                    <Index.TableHead className='seller-confirmed-order-table-head common-table-head'>
                        <Index.TableRow className='seller-confirmed-order-table-row common-table-row'>
                            <Index.TableCell className='seller-confirmed-order-table-th common-table-th' width="15%" align='left'>Order ID</Index.TableCell>
                            <Index.TableCell className='seller-confirmed-order-table-th common-table-th' width="25%" align='left'>Product Details</Index.TableCell>
                            <Index.TableCell className='seller-confirmed-order-table-th common-table-th' width="20%" align='center'>Pending Amount</Index.TableCell>
                            <Index.TableCell className='seller-confirmed-order-table-th common-table-th' width="20%" align='center'>Status</Index.TableCell>
                            <Index.TableCell className='seller-confirmed-order-table-th common-table-th' width="20%" align='center'>PO No.</Index.TableCell>
                        </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className='seller-confirmed-order-table-body common-table-body'>
                        <Index.TableRow>
                            <Index.TableCell width="100%" colSpan="5" sx={{ padding: 0 }} className='action-common-table-td'>
                                <Index.Table>
                                    <Index.TableRow className='seller-confirmed-order-table-row common-table-row'>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="15%" align='left'>
                                            <Index.Box className='enquiry-order'>
                                                #1234
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="25%" align='left'>
                                            <Index.Box className="order-product-details">
                                                <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                                <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="20%" align='center'>
                                            <Index.Box className='value-added'>
                                                INR 8,00,000
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="20%" align='center'>
                                            <Index.Box className='seller-confirm-pending'>
                                                Your confirmation pending
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="20%" align='center'>
                                            12345
                                        </Index.TableCell>
                                    </Index.TableRow>
                                    <Index.TableRow>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td table-row-action-td' width="100%" colSpan="5">
                                            <Index.Box className="table-row-action seller">
                                                <Index.Box className="button-box">
                                                    <PagesIndex.OrangeFillButton btnLabel="Confirm Order" className="orange-fill-btn" />
                                                </Index.Box>
                                                <Index.Button className='action-btn'>
                                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                                </Index.Button>
                                            </Index.Box>
                                        </Index.TableCell>
                                    </Index.TableRow>
                                </Index.Table>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow>
                            <Index.TableCell width="100%" colSpan="5" sx={{ padding: 0 }} className='action-common-table-td'>
                                <Index.Table>
                                    <Index.TableRow className='seller-confirmed-order-table-row common-table-row'>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="15%" align='left'>
                                            <Index.Box className='enquiry-order'>
                                                #1234
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="25%" align='left'>
                                            <Index.Box className="order-product-details">
                                                <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                                <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="20%" align='center'>
                                            <Index.Box className='value-added'>
                                                INR 8,00,000
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="20%" align='center'>
                                            <Index.Box className='order-green-text'>
                                                confirmed
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="20%" align='center'>
                                            12345
                                        </Index.TableCell>
                                    </Index.TableRow>
                                    <Index.TableRow>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td table-row-action-td' width="100%" colSpan="5">
                                            <Index.Box className="table-row-action seller">
                                                <Index.Box className="button-box">
                                                    <PagesIndex.OrangeFillButton btnLabel="Update Status" onClick={handleOpenUpdateStatusModal} className="orange-fill-btn" />
                                                </Index.Box>
                                                <Index.Button className='action-btn'>
                                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                                </Index.Button>
                                            </Index.Box>
                                        </Index.TableCell>
                                    </Index.TableRow>
                                </Index.Table>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow>
                            <Index.TableCell width="100%" colSpan="5" sx={{ padding: 0 }} className='action-common-table-td'>
                                <Index.Table>
                                    <Index.TableRow className='seller-confirmed-order-table-row common-table-row'>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="15%" align='left'>
                                            <Index.Box className='enquiry-order'>
                                                #1234
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="25%" align='left'>
                                            <Index.Box className="order-product-details">
                                                <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                                <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="20%" align='center'>
                                            <Index.Box className='value-added'>
                                                INR 8,00,000
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="20%" align='center'>
                                            <Index.Box className='order-green-text'>
                                                Manufacturing Date/Time
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td' width="20%" align='center'>
                                            12345
                                        </Index.TableCell>
                                    </Index.TableRow>
                                    <Index.TableRow>
                                        <Index.TableCell className='seller-confirmed-order-table-td common-table-td table-row-action-td' width="100%" colSpan="5">
                                            <Index.Box className="table-row-action seller">
                                                <Index.Box className="button-box">
                                                    <PagesIndex.OrangeFillButton btnLabel="Update Status" onClick={handleOpenUpdateStatusModal} className="orange-fill-btn" />
                                                </Index.Box>
                                                <Index.Button className='action-btn'>
                                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                                </Index.Button>
                                            </Index.Box>
                                        </Index.TableCell>
                                    </Index.TableRow>
                                </Index.Table>
                            </Index.TableCell>
                        </Index.TableRow>
                    </Index.TableBody>
                </Index.Table>
            </Index.TableContainer>
            <Index.Modal
                open={openUpdateStatusModal}
                onClose={handleCloseUpdateStatusModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modal-common-main'
            >
                <Index.Box sx={style} className="update-sts-inner-main modal-common-style">
                    <Index.Box className="cus-scrollbar modal-hgt-scroll">
                        <Index.Box className="modal-header">
                            <Index.Typography className='update-sts-modal-title'>Update status</Index.Typography>
                            <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseUpdateStatusModal} />
                        </Index.Box>
                        <Index.Box className="modal-body">
                            <Index.Box className="update-sts-box">
                                <Index.Button className="update-sts-item order-confirmed">
                                    <img src={PagesIndex.Png.CheckedWhiteBg} alt='checked icon' />
                                    <Index.Typography variant='span' component="span">
                                        Ordered confirm
                                    </Index.Typography>
                                    <Index.Typography variant='span' component="span">
                                        Apr 12,2023 2:24PM
                                    </Index.Typography>
                                </Index.Button>
                                <Index.Button className="update-sts-item manufacturing">
                                    Manufacturing
                                </Index.Button>
                                <Index.Button className="update-sts-item dispatched">
                                    Dispatched
                                </Index.Button>
                                <Index.Button className="update-sts-item delivered">
                                    Order delivered
                                </Index.Button>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="modal-footer">
                            <Index.Box className="update-sts-btn-main">
                                <PagesIndex.OrangeFillButton btnLabel="Update" className="orange-fill-btn update-sts-btn" />
                                <PagesIndex.OrangeOutlineButton btnLabel="Cancel" onClick={handleCloseUpdateStatusModal} className="orange-outline-inner review-modal-btn" />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>
        </Index.Box>

    )

}
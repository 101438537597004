import { createAsyncThunk } from "@reduxjs/toolkit";
import PageIndex from "../../../../container/PagesIndex";
import DataService from "../../../../config/DataService";
// import PageIndex from "../../../../container/PageIndex";

export const userLogin = createAsyncThunk("user/userLogin", async (data) => {
  try {
    const response = await PageIndex.doPost(
      PageIndex.Api.User.Auth.USER_LOGIN,
      data
    );
    console.log(response,12)
    return response.data;
  } catch (error) {}
});

export const userForgotPassword = createAsyncThunk(
  "user/userForgotPassword",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.User.Auth.userForgotPassword,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

export const userVerifyOTP = createAsyncThunk(
  "user/userVerifyOTP",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.User.Auth.userVerifyOTP,
        data
      );
      return response.data;
    } catch (error) {}
  }
);
export const userResendOTP = createAsyncThunk(
  "user/userVerifyOTP",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.User.Auth.userResendOTP,
        data
      );
      return response.data;
    } catch (error) {}
  }
);
export const userResetPassword = createAsyncThunk(
  "user/userResetPassword",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.User.Auth.userResetPassword,
        data
      );
      return response.data;
    } catch (error) {}
  }
);
export const checkUser = createAsyncThunk("user/checkUser", async (data) => {
  try {
    const response = await PageIndex.doPost(
      PageIndex.Api.User.Auth.checkUser,
      data
    );
    return response.data;
  } catch (error) {}
});

export const userUpdateProfile = createAsyncThunk(
  "user/checkUser",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.User.Auth.userUpdateProfile,
        data
      );
      return response.data;
    } catch (error) {}
  }
);
export const userChangePassword = createAsyncThunk(
  "user/checkUser",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.User.Auth.userChangePassword,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

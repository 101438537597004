import React from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PagesIndex";
import './joinUsSection.css'
import './joinUsSection.responsive.css'

export default function JoinUsSection() {
  return (
    <Index.Box className="join-us-wrape" component="section">
      <Index.Box className="cus-container">
        <Index.Box className="grid inner-wrape">
          <Index.Box className="big-size">
            <img src={PageIndex.Jpg.joinus} alt="join-us" className="join-img"/>
            <Index.Box className="content">
              <Index.Typography component="h3">Join us </Index.Typography>
              <Index.Typography component="p">
                Attention Buyers, Sellers <br/> Welcome to the future of steel and polymer procurement with STELMART. Join our cutting-edge platform where buying and selling of steel and polymer Products becomes easy and transparent.
              </Index.Typography>
              <Index.Typography component="p">
                Connect, trade, and facilitate deals with ease. Register today – become part of the
                STELMART community
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="small-size">
            <img src={PageIndex.Png.buyer} alt="join-us" className="join-img" />
            <Index.Box className="content">
              <Index.Typography component="h3">Buyer </Index.Typography>
              <Index.Typography component="p">
                Meet the diverse variety of steel and polymer products with high quality and support
              </Index.Typography>
              <Index.Link to="/dashboard" className="link">
                Sign up as Buyer <img src={PageIndex.Svg.frwdbluearrrow} />
              </Index.Link>
            </Index.Box>
          </Index.Box>
          <Index.Box className="small-size">
            <img src={PageIndex.Png.seller} alt="join-us" className="join-img" />
            <Index.Box className="content">
              <Index.Typography component="h3">Seller</Index.Typography>
              <Index.Typography component="p">
                Join us to grow & elevate your steel and polymer business with cutting-edge technology
              </Index.Typography>
              <Index.Link to="/coming-soon" className="link">
                Sign up as Seller <img src={PageIndex.Svg.frwdbluearrrow} />
              </Index.Link>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

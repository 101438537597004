import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import './blogdetails.css'
import './blogdetails.responsive.css'

export default function BlogDetails() {
  return (
    <Index.Box className="main-blogs-details" component="main">
      <PagesIndex.Breadcrumb />
      <Index.Box className="blogs-details-content-main">
        <Index.Box className="cus-container">
          <Index.Box className="blog-details-img-flex">
            <Index.Box className="blog-details-left-main">

              <Index.Box className="blog-details-img-main">
                <img src={PagesIndex.Jpg.blogImg1} alt='blog post' className='blog-details-image' />
              </Index.Box>
            </Index.Box>
            <Index.Box className="blog-details-right-main">
              <Index.Box className="blog-search-main">
                <Index.Typography className="blog-search-title" component="h3" variant="h3">Search</Index.Typography>
                <Index.TextField className="search-white-control" placeholder="Search here"></Index.TextField>
              </Index.Box>
              <Index.Box className="blogs-keyword-main">
                <Index.Typography className="blog-keyword-title">Keywords</Index.Typography>
                <Index.List className="blog-keyword-ul">
                  <Index.ListItem className="blog-keyword-li blog-keyword-active">Steel</Index.ListItem>
                  <Index.ListItem className="blog-keyword-li">Polymer</Index.ListItem>
                  <Index.ListItem className="blog-keyword-li">How to order</Index.ListItem>
                  <Index.ListItem className="blog-keyword-li">Dummy</Index.ListItem>
                  <Index.ListItem className="blog-keyword-li">Lorem Ipsum</Index.ListItem>
                </Index.List>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="blog-details-theory-content-main">
            <Index.Typography className="blog-details-inner-titles" component="h2" variant="h2">PRICING - WHAT HAPPENED TO LEVELING OFF? HERE WE GO
              AGAIN</Index.Typography>
            <Index.Typography className="blog-details-para" component="p" variant="p">Structural tubing prices have hit bottom and are now back on the way up. We have now seen 3 increases on structural tubing since mid-October. Mills are holding firm on elevated offer prices, with most major mills’ December order books full and into January, sources say. Meanwhile, tentative agreements between the Big Three automakers and the United Auto Workers union could boost demand and provide further support to the hot band market.
            </Index.Typography>
            <Index.Box className="blog-details-para-flex">
              <Index.Box className="blog-details-para-left-main">
                <Index.Typography className="blog-details-para" component="p" variant="p">Mechanical tubing seems to be following a similar trend to structural tubing. Drawn Over Mandrel (DOM), Cold Drawn Seamless (CDS) and Hot Rolled Seamless (HRS) tubing are all ticking upward. Over the last few weeks, we have seen prices increase due to coil and the CRU index rising. These increases are reversing the lows we have seen over the last few months. While decreases are never “announced” in mechanical tubing, we had been seeing several months of lowering prices until last week (see estimate in chart below).
                </Index.Typography>
              </Index.Box>
              <Index.Box className="blog-details-img-right-main">
                <img src={PagesIndex.Png.aboutdetailsimg} alt='blog post' className='blog-details-para-img' />
              </Index.Box>
            </Index.Box>
            <Index.Typography className="blog-details-para" component="p" variant="p">The CRU index, which is an indication of where coil and sheet steel prices are sitting, increased $51 per ton on November 8th and now sits at $853 per ton. Hot rolled coil futures are over $1,000/ton for December. Cleveland-Cliffs announced its fourth price increase since the end of September, with Cliffs now targeting a minimum base HRC price of $1,000/ton, effective immediately. The prior three increases were largely successful. Nucor followed Cliffs’ lead in raising prices but announced it will increase its spot HRC prices to a minimum of $950/ton (Source: Company Press Release)
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PagesIndex.LatestPostsAll />
    </Index.Box>
  )
}

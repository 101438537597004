import React from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PagesIndex";
import "./heroSection.css";
import "./heroSection.responsive.css";

function HeroSection() {
  return (
    <>
      {/* Hero section start */}
      <Index.Box className="home-hero-main" component="section">
        <PageIndex.Swiper
          loop={true}
          speed={2000}
          modules={[PageIndex.Navigation, PageIndex.Pagination, PageIndex.Autoplay]}
          className="mySwiper"
          // autoplay={{
          //   delay: 5000,
          //   disableOnInteraction: false,
          // }}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
        >
          <PageIndex.SwiperSlide>
            <img src={PageIndex.Jpg.slide3} alt="main hero" className="banner-slide" loading="lazy" />
          </PageIndex.SwiperSlide>
          <PageIndex.SwiperSlide>
            <img src={PageIndex.Jpg.slide1} alt="main hero" className="banner-slide" loading="lazy" />
          </PageIndex.SwiperSlide>
          <PageIndex.SwiperSlide>
            <img src={PageIndex.Jpg.slide2} alt="main hero" className="banner-slide" loading="lazy" />
          </PageIndex.SwiperSlide>
          <Index.Box className="controler">
            <Index.Box className="swiper-button-prev">
              <img src={PageIndex.Svg.prvblackarrrow} alt="arrow icon" />
            </Index.Box>
            <Index.Box className="swiper-button-next">
              <img src={PageIndex.Svg.nxtblackarrrow} alt="arrow icon" />
            </Index.Box>
          </Index.Box>
        </PageIndex.Swiper>
        <Index.Box className="banner-content">
          <Index.Box className="cus-container">
            <Index.Box className="banner-content-inner">
              <Index.Typography component="h1" className="banner-content-title">
                Premier digital marketplace for steel & polymer products
              </Index.Typography>
              <Index.Box className="search-area">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Seamless Pipe"
                />
                <Index.Button type="submit" className="search-btn">
                  Search
                </Index.Button>
              </Index.Box>
              <Index.Box className="tab-wrape">
                <Index.Typography className="tab-wrape-title">
                  Frequently searched:
                </Index.Typography>
                <Index.Box className="tab-wrape-inner">
                  <Index.Link to="/coming-soon" className="tab-wrape-link">
                    Seamless Pipe
                  </Index.Link>
                  <Index.Link to="/coming-soon" className="tab-wrape-link">
                    Welded Pipe
                  </Index.Link>
                  <Index.Link to="/coming-soon" className="tab-wrape-link">
                    HDPE
                  </Index.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="link-wrape">
          <Index.Link to="/coming-soon" className="link-text">
            Buyer Central
          </Index.Link>
          <Index.Link to="/coming-soon" className="link-text">
            Become a supplier
          </Index.Link>
          <Index.Link to="/coming-soon" className="link-text">
            Get the app
          </Index.Link>
        </Index.Box>


        <Index.Box className="controler mobile-shows">
          <Index.Box className="swiper-button-prev">
            <img src={PageIndex.Svg.prvblackarrrow} alt="arrow icon" />
          </Index.Box>
          <Index.Box className="swiper-button-next">
            <img src={PageIndex.Svg.nxtblackarrrow} alt="arrow icon" />
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* Hero section end */}
    </>
  )
}

export default HeroSection

import React from 'react'

export default function MailIcons() {
    return (

        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.33366 13.6666C1.87533 13.6666 1.48296 13.5034 1.15658 13.177C0.830187 12.8506 0.666992 12.4583 0.666992 11.9999V1.99992C0.666992 1.54159 0.830187 1.14922 1.15658 0.822835C1.48296 0.496446 1.87533 0.333252 2.33366 0.333252H15.667C16.1253 0.333252 16.5177 0.496446 16.8441 0.822835C17.1705 1.14922 17.3337 1.54159 17.3337 1.99992V11.9999C17.3337 12.4583 17.1705 12.8506 16.8441 13.177C16.5177 13.5034 16.1253 13.6666 15.667 13.6666H2.33366ZM9.00033 7.83325L2.33366 3.66659V11.9999H15.667V3.66659L9.00033 7.83325ZM9.00033 6.16658L15.667 1.99992H2.33366L9.00033 6.16658ZM2.33366 3.66659V1.99992V11.9999V3.66659Z" fill="#333333" />
        </svg>

    )
}

import React from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PagesIndex";
import "./blogssection.css";
import "./blogssection.responsive.css";

function Blogssection() {
  return (
    <Index.Box className="blog-wrape" component="section">
      <Index.Box className="cus-container">
        <Index.Box className="top-content">
          <Index.Box className="left-wrape">
            <Index.Typography component="h5">Blogs</Index.Typography>
            <Index.Typography component="p">
              Key Challenges Faced by Steel & Polymer product buyers in the Indian market
            </Index.Typography>
          </Index.Box>
          <Index.Box className="right-wrape">
            <Index.Box className="white-box">
              <Index.Typography component="h5">Quality Concerns </Index.Typography>
              <Index.Typography component="p">
                Ensuring that the manufactured products are of good quality is a major concern for buyers. Sometimes, it's hard to know if the Products...
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="crousel-wrape">
          <PageIndex.Swiper
            slidesPerView={1}
            spaceBetween={20}
            loop={true}
            pagination={{
              el: ".my-custom",
              clickable: true,
              type: "fraction",
            }}
            modules={[PageIndex.Navigation, PageIndex.Pagination]}
            className="mySwiper"
            navigation={{
              nextEl: ".cus-swiper-button-next",
              prevEl: ".cus-swiper-button-prev",
            }}
          >
            <PageIndex.SwiperSlide>
              <Index.Box className="box-grid">
                <Index.Box className="img-wrape">
                  <img src={PageIndex.Png.polymer} />
                </Index.Box>
                <Index.Box className="bottom-info">
                  <Index.Typography component="p" className="detail">
                    Learn more{" "}
                  </Index.Typography>
                  <Index.Link to="/blog-details" className="link">
                    <img
                      src={PageIndex.Svg.frwdorangearrrow}
                      className="arrow"
                    />{" "}
                  </Index.Link>
                </Index.Box>
              </Index.Box>
            </PageIndex.SwiperSlide>

            <PageIndex.SwiperSlide>
              <Index.Box className="box-grid">
                <Index.Box className="img-wrape">
                  <img src={PageIndex.Png.polymer} />
                </Index.Box>
                <Index.Box className="bottom-info">
                  <Index.Typography component="p" className="detail">
                    Learn more{" "}
                  </Index.Typography>
                  <Index.Link to="/blog-details" className="link">
                    <img src={PageIndex.Svg.frwdorangearrrow} className="arrow" />{" "}
                  </Index.Link>
                </Index.Box>
              </Index.Box>
            </PageIndex.SwiperSlide>
          </PageIndex.Swiper>
        </Index.Box>
        <Index.Box className="slide-controller">
          <div className="my-custom"></div>
          <Index.Box className="control">
            <Index.Box className="cus-swiper-button-prev">
              <img src={PageIndex.Svg.frwmtorangearrrow} />
            </Index.Box>
            <Index.Box className="cus-swiper-button-next">
              <img src={PageIndex.Svg.backmtorangearrrow} />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

export default Blogssection
import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { Form, Formik } from "formik";
// editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// import ReactHtmlParser from "react-html-parser";

const AddTags = () => {
  const navigate = useNavigate();
  const initialValues = {
    title: "",
    description: "",
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .matches(/^[a-zA-Z\s-, ]+$/, "Accept only alphabetic and white space")
      .required("Please enter your title"),
    description: Yup.string()
      .required("Please enter description")
      .test("maxWords", "Description must be at most 100 words", (value) => {
        if (value) {
          const words = value.split(/\s+/).filter((word) => word.length > 0);
          return words.length <= 100;
        }
        return true;
      }),
  });
  const handleFormSubmit = async (values) => {
    console.log(values, 13);
    await DataService.post(Api.Admin.CREATE_TAGS, values)
      .then((res) => {
        console.log(res, 62);
        toast.success(res?.data?.message);
        navigate("/admin/blogs-list");
      })
      .catch((error) => {
        console.log(error, 64);
        toast.success(error?.response?.data?.message);
      });
  };
  return (
    <>
      <Index.Box className="flex-all admin-title-box">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            className="admin-sub-title admin-buyer-title"
            component="h3"
            variant="h3"
          >
            Add Tags
          </Index.Typography>
        </Index.Box>
      </Index.Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Index.Box className="common-grid-main">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                  className="login-row"
                >
                  <Index.Box
                    gridColumn={{
                      xxs: "span 12",
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Title
                        <span className="astric-sign">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="auth-input-main">
                        <Index.TextField
                          className="comman-form-control"
                          placeholder=""
                          type="test"
                          name="title"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.title}
                        />
                      </Index.Box>
                      {errors.title && touched.title && (
                        <p className="error-text">{errors.title}</p>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xxs: "span 12",
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Description
                        <span className="astric-sign">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="auth-input-main">
                        <CKEditor
                          editor={ClassicEditor}
                          name="description"
                          data={values.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            const strippedData = data.replace(
                              /<\/?[^>]+(>|$)/g,
                              ""
                            );
                            const words = strippedData.trim().split(/\s+/);
                            const limitedContent = words
                              .slice(0, 100)
                              .join(" ");

                            handleChange({
                              target: {
                                name: "description",
                                value: data,
                              },
                            });
                          }}
                        />
                      </Index.Box>
                      {errors.description && touched.description && (
                        <p className="error-text">{errors.description}</p>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-footer-btn-box">
              <PagesIndex.BlueFillButton
                btnLabel="Submit"
                className="blue-fill-btn"
                type="submit"
              />
            </Index.Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddTags;

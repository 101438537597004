import React from "react";
import Index from "../../../../Index";
import "./analyticsDashboard.css";
import "./analyticsDashboard.responsive.css";

function AnalyticsDashboard() {
  return (
    <>
      <Index.Box className="main-analytics-dashboard">
        <Index.Box className="analytics-user-list-main">
          <Index.Box className="analytic-inner-content">
            <Index.Box className="analytics-main-grid">
              <Index.Box className="analytics-col1">
                <Index.Box className="order-title-content-main">
                  <Index.Typography
                    className="order-title-list"
                    variant="h5"
                    components="h5"
                  >
                    <span className="dot-analytics green-dots"></span>Completed
                    Orders
                  </Index.Typography>
                  <Index.Typography
                    className="order-review-listing"
                    variant="p"
                    component="p"
                  >
                    12
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="analytics-col1">
                <Index.Box className="order-title-content-main">
                  <Index.Typography
                    className="order-title-list"
                    variant="h5"
                    components="h5"
                  >
                    <span className="dot-analytics orange-dots"></span>Pending
                    Orders
                  </Index.Typography>
                  <Index.Typography
                    className="order-review-listing"
                    variant="p"
                    component="p"
                  >
                    12
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="analytics-col1">
                <Index.Box className="order-title-content-main">
                  <Index.Typography
                    className="order-title-list"
                    variant="h5"
                    components="h5"
                  >
                    <span className="dot-analytics green-dots"></span>Quotation
                    Sent
                  </Index.Typography>
                  <Index.Typography
                    className="order-review-listing"
                    variant="p"
                    component="p"
                  >
                    12
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="analytics-col1">
                <Index.Box className="order-title-content-main">
                  <Index.Typography
                    className="order-title-list"
                    variant="h5"
                    components="h5"
                  >
                    <span className="dot-analytics orange-dots"></span>Pending
                    Quotation
                  </Index.Typography>
                  <Index.Typography
                    className="order-review-listing"
                    variant="p"
                    component="p"
                  >
                    12
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="analytics-details-grid-list">
          <Index.Box className="analytics-grid-col-row">
            <Index.Box className="dash-analytics-col2">
              <Index.Box className="dash-analytic-card">
                <Index.Box className="order-title-content-main">
                  <Index.Typography
                    className="order-title-list"
                    variant="h5"
                    components="h5"
                  >
                    <span className="dot-analytics green-dots"></span>Completed
                    Orders
                  </Index.Typography>
                  <Index.Typography
                    className="order-review-listing"
                    variant="p"
                    component="p"
                  >
                    12
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="dash-analytics-col2">
              <Index.Box className="dash-analytic-card">
                <Index.Box className="order-title-content-main">
                  <Index.Typography
                    className="order-title-list"
                    variant="h5"
                    components="h5"
                  >
                    <span className="dot-analytics green-dots"></span>Quotation
                    Sent
                  </Index.Typography>
                  <Index.Typography
                    className="order-review-listing"
                    variant="p"
                    component="p"
                  >
                    12
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="dash-analytics-col2">
              <Index.Box className="dash-analytic-card">
                <Index.Box className="order-title-content-main">
                  <Index.Typography
                    className="order-title-list"
                    variant="h5"
                    components="h5"
                  >
                    <span className="dot-analytics orange-dots"></span>Pending
                    Orders
                  </Index.Typography>
                  <Index.Typography
                    className="order-review-listing"
                    variant="p"
                    component="p"
                  >
                    12
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="dash-analytics-col2">
              <Index.Box className="dash-analytic-card">
                <Index.Box className="order-title-content-main">
                  <Index.Typography
                    className="order-title-list"
                    variant="h5"
                    components="h5"
                  >
                    <span className="dot-analytics orange-dots"></span>Pending
                    Quotation
                  </Index.Typography>
                  <Index.Typography
                    className="order-review-listing"
                    variant="p"
                    component="p"
                  >
                    12
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AnalyticsDashboard;

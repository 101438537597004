import React from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import './sellerQuotations.css'

export default function SellerQuotations() {

    return (

        <Index.Box className="seller-quotations-main">
            <Index.TableContainer className="seller-quotation-table-container common-table-container cus-scrollbar">
                <Index.Table aria-label="simple table" className='seller-quotation-table common-table action-common-table'>
                    <Index.TableHead className='seller-quotation-table-head common-table-head'>
                        <Index.TableRow className='seller-quotation-table-row common-table-row'>
                            <Index.TableCell className='seller-quotation-table-th common-table-th' width="18%" align='left'>Enquiry No.</Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-th common-table-th' width="22%" align='left'>Product Details</Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-th common-table-th' width="20%" align='center'>Qty</Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-th common-table-th' width="20%" align='center'>Date</Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-th common-table-th' width="20%" align='center'>Value</Index.TableCell>
                        </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className='seller-quotation-table-body common-table-body'>
                        <Index.TableRow>
                            <Index.TableCell width="100%" colSpan="5" sx={{ padding: 0 }} className='action-common-table-td'>
                                <Index.Table>
                                    <Index.TableRow className='seller-quotation-table-row common-table-row'>
                                        <Index.TableCell className='seller-quotation-table-td common-table-td' width="18%" align='left'>
                                            <Index.Box className='enquiry-order'>
                                                #1234
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-quotation-table-td common-table-td' width="22%" align='left'>
                                            <Index.Box className="order-product-details">
                                                <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                                <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-quotation-table-td common-table-td' width="20%" align='center'>
                                            <Index.Box className='seller-qty'>
                                                6 X 6 - 50 pipes
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-quotation-table-td common-table-td' width="20%" align='center'>
                                            <Index.Box className='seller-date'>
                                                24/08/2023
                                            </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell className='seller-quotation-table-td common-table-td' width="20%" align='center'>
                                            <Index.Box className="comman-form-group insert-value">
                                                <Index.TextField className="comman-form-control" placeholder='Insert value' />
                                            </Index.Box>
                                        </Index.TableCell>
                                    </Index.TableRow>
                                    <Index.TableRow>
                                        <Index.TableCell className='seller-quotation-table-td common-table-td table-row-action-td' width="100%" colSpan="5">
                                            <Index.Box className="table-row-action seller">
                                                <Index.Box className="button-box">
                                                    <PagesIndex.OrangeFillButton btnLabel="Send" className="orange-fill-btn" />
                                                    <PagesIndex.OrangeOutlineButton btnLabel="Reject" className="orange-outline-inner" />
                                                </Index.Box>
                                                <Index.Button className='action-btn'>
                                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                                </Index.Button>
                                            </Index.Box>
                                        </Index.TableCell>
                                    </Index.TableRow>
                                </Index.Table>
                            </Index.TableCell>
                        </Index.TableRow>
                    </Index.TableBody>
                </Index.Table>
            </Index.TableContainer>

            <Index.TableContainer className="seller-quotation-container common-table-container cus-scrollbar">
                <Index.Table aria-label="simple table" className='seller-quotation common-table'>
                    <Index.TableHead className='seller-quotation-head common-table-head'>
                        <Index.TableRow className='seller-quotation-row common-table-row'>
                            <Index.TableCell className='seller-quotation-table-th common-table-th' width="18%" align='left'>Order ID</Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-th common-table-th' width="22%" align='left'>Product Details</Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-th common-table-th' width="20%" align='center'>Qty</Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-th common-table-th' width="20%" align='center'>Reverse Auction</Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-th common-table-th' width="20%" align='center'>Value Added</Index.TableCell>
                        </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className='seller-quotation-body common-table-body'>
                        <Index.TableRow className='seller-quotation-row common-table-row'>
                            <Index.TableCell className='seller-quotation-table-td common-table-td' width="18%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-td common-table-td' width="22%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='seller-qty'>
                                    6 X 6 - 50 pipes <br />
                                    Loerm ipsum..
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-td common-table-td' width="20%" align='center'>
                                <Index.Box class="ra-value scheduled-color-text">
                                    Scheduled on <br />
                                    24/10/23
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quotation-table-td common-table-td' width="20%" align='center'>
                                <Index.Box className='value-added'>
                                    INR 6,00,000
                                </Index.Box>
                            </Index.TableCell>
                        </Index.TableRow>
                    </Index.TableBody>
                </Index.Table>
            </Index.TableContainer>
        </Index.Box>

    )

}
import React from "react";
import {
  BrowserRouter,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import Layout from "../component/user/defaulLayout/Layout";
import Home from "../container/user/pages/home/Home";
import Comingsoon from "../container/user/pages/comingsoon/Comingsoon";
import About from "../container/user/pages/about/About";
import Faq from "../container/user/pages/faq/Faq";
import Blogs from "../container/user/pages/blogs/Blogs";
import ProductListing from "../container/user/pages/productlisting/ProductListing";
import Dashboard from "../container/user/pages/dashboard/Dashboard";
import MyCart from "../container/user/pages/mycart/MyCart";
import MyAccount from "../container/user/pages/myaccount/MyAccount";
import BlogDetails from "../container/user/pages/blogdetails/BlogDetails";
import AdminLayout from "../container/admin/pages/adminlayout/AdminLayout";
import AdminDashboard from "../container/admin/pages/dashboard/AdminDashboard";
import AdminAbout from "../container/admin/pages/cms/about/About";
import PrivacyPolicy from "../container/admin/pages/cms/privacypolicy/PrivacyPolicy";
import TermsAndConditions from "../container/admin/pages/cms/termsandconditions/TermsAndConditions";
import Login from "../container/admin/auth/login/Login";
import ForgotPassWord from "../container/admin/auth/forgotPassword/ForgotPassword";
import BuyerList from "../container/admin/pages/buyerlist/BuyerList";
import Otp from "../container/admin/auth/otp/OTP";
import ResetPassWord from "../container/admin/auth/resetpassword/ResetPassword";
import Career from "../container/user/pages/career/Career";
import MyAccountContent from "../component/user/pages/myaccount/myaccountcontent/MyAccountContent";
import AdminPrivateRoute from "./AdminPrivateRoute";
import ProductDetail from "../container/user/pages/productDetail/ProductDetail";
import SellerDashboard from "../container/seller/pages/sellerDashboard/SellerDashboard";
import Catalogue from "../container/user/pages/catalogue/Catalogue";
import SellerMyAccount from "../container/seller/pages/sellerMyAccount/SellerMyAccount";
import SellerList from "../container/admin/pages/sellerlist/SellerList";
import ViewBuyer from "../container/admin/pages/buyerlist/ViewBuyer";
import ViewSeller from "../container/admin/pages/sellerlist/ViewSeller";
import BlogsList from "../container/admin/pages/blogs/BlogsList";
import PageNotFound from "../component/common/pageNotFound/PageNotFound";
import AddCategory from "../container/admin/pages/blogs/AddCategory";
import AddSubCategory from "../container/admin/pages/blogs/AddSubCategory";
import AddTags from "../container/admin/pages/blogs/AddTags";
import ProductManagement from "../container/admin/pages/productManagement/ProductManagement";
import AddBlog from "../container/admin/pages/blogs/AddBlog";
import ViewBlog from "../container/admin/pages/blogs/ViewBlog";
import OrderManagement from "../container/admin/pages/orderManagement/OrderManagement";
import AddProduct from "../container/admin/pages/productManagement/AddProduct";
import ViewProduct from "../container/admin/pages/productManagement/ViewProduct";
import EditProduct from "../container/admin/pages/productManagement/EditProduct";
import PaymentManagement from "../container/admin/pages/paymentManagement/PaymentManagement";
import EditOrder from "../container/admin/pages/orderManagement/EditOrder";
import ViewOrder from "../container/admin/pages/orderManagement/ViewOrder";
import UserPrivateRoute from "./UserPrivateRoute";
import BannerManagement from "../container/admin/pages/bannerManagement/BannerManagement";
import QueryManagement from "../container/admin/pages/queryManagement/QueryManagement";
import RatingAndReview from "../container/admin/pages/ratingAndReview/RatingAndReview";
import InvoiceManagement from "../container/admin/pages/invoiceManagement/InvoiceManagement";
import GenerateInvoice from "../container/admin/pages/invoiceManagement/GenerateInvoice";
import ViewInvoice from "../container/admin/pages/invoiceManagement/ViewInvoice";

export default function Routers() {
  return (
    <BrowserRouter>
      <Routes>
        {/* user start */}
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="coming-soon" element={<Comingsoon />} />
          <Route path="about" element={<About />} />
          <Route path="faq" element={<Faq />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="product-listing" element={<ProductListing />} />
          {/* <Route path="dashboard" element={<Dashboard />} /> */}
          <Route path="my-cart" element={<MyCart />} />

          <Route path="blog-details" element={<BlogDetails />} />
          <Route path="career" element={<Career />} />
          <Route path="my-account" element={<MyAccount />} />
          {/* <Route path="my-account" element={<MyAccountContent />} /> */}
          <Route path="product-detail" element={<ProductDetail />} />
          <Route path="catalogue" element={<Catalogue />} />

          <Route element={<UserPrivateRoute />}>
            <Route path="my-account" element={<MyAccount />} />
            <Route path="/seller/my-account" element={<SellerMyAccount />} />
            <Route path="/seller/dashboard" element={<SellerDashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Route>
        {/* user end */}

        {/* seller start */}
        <Route path="/seller" element={<Layout />}>
          {/* <Route path="dashboard" element={<SellerDashboard />} /> */}
          {/* <Route path="my-account" element={<SellerMyAccount />} /> */}
        </Route>
        {/* seller end */}

        {/* admin start */}
        <Route path="/admin/">
          <Route path="" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassWord />} />
          <Route path="verify-otp" element={<Otp />} />
          <Route path="reset-password" element={<ResetPassWord />} />
        </Route>

        <Route element={<AdminPrivateRoute />}>
          <Route path="/admin/" element={<AdminLayout />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="buyer-list" element={<BuyerList />} />
            <Route path="seller-list" element={<SellerList />} />
            <Route path="view-buyer" element={<ViewBuyer />} />
            <Route path="view-seller" element={<ViewSeller />} />
            <Route path="blogs-list" element={<BlogsList />} />
            <Route path="blogs-add-category" element={<AddCategory />} />
            <Route path="blogs-add-sub-category" element={<AddSubCategory />} />
            <Route path="blogs-add-tags" element={<AddTags />} />
            <Route path="product-management" element={<ProductManagement />} />
            <Route path="blogs-add" element={<AddBlog />} />
            <Route path="blogs-view" element={<ViewBlog />} />
            <Route path="admin-about" element={<AdminAbout />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-conditions" element={<TermsAndConditions />} />
            <Route path="product-add-edit" element={<AddProduct />} />
            <Route path="product-view" element={<ViewProduct />} />
            <Route path="product-edit" element={<EditProduct />} />
            <Route path="payment-management" element={<PaymentManagement />} />
            <Route path="order-management" element={<OrderManagement />} />
            <Route path="order-edit" element={<EditOrder />} />
            <Route path="order-view" element={<ViewOrder />} />
            <Route path="banner-management" element={<BannerManagement />} />
            <Route path="query-management" element={<QueryManagement />} />
            <Route path="rating-review" element={<RatingAndReview />} />
            <Route path="invoice-management" element={<InvoiceManagement />} />
            <Route path="generate-invoice" element={<GenerateInvoice />} />
            <Route path="view-invoice" element={<ViewInvoice />} />
          </Route>
        </Route>
        {/* admin end */}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

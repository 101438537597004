import React, { useState } from 'react'
import Index from '../../../../Index';
import PagesIndex from '../../../../PagesIndex';

function CancelRequest() {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>

            <Index.Box className="cancel-request-table-list">
                <Index.TableContainer className="cancelrequest-table-container common-table-container cus-scrollbar">
                    <Index.Table aria-label="simple table" className='cancelrequest-table common-table'>
                        <Index.TableHead className='cancelrequest-table-head common-table-head'>
                            <Index.TableRow className='cancelrequest-table-row common-table-row'>
                                <Index.TableCell className='cancelrequest-table-th common-table-th' width="15%" align="left">Company</Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-th common-table-th' width="25%" align="center">Requirements</Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-th common-table-th' width="20%" align="center">Date</Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-th common-table-th' width="20%" align="center">Bid</Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-th common-table-th' width="20%" align="center">Action</Index.TableCell>
                            </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className='cancelrequest-table-body common-table-body'>
                            <Index.TableRow
                                className='cancelrequest-table-row common-table-row'
                            >
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="15%" align="left">
                                    <Index.Box className="company-list-cancel">
                                        India expo <br/>
                                        steels pvt
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="25%" align="center">
                                    <Index.Box className="cancel-product-details">
                                        <Index.List className='table-list'>
                                            <Index.ListItem className='table-list-item'>
                                                Dimensions (6 X 6)
                                            </Index.ListItem>
                                            <Index.ListItem className='table-list-item'>
                                                Qty (50 pieces)
                                            </Index.ListItem>
                                        </Index.List>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="20%" align="center">
                                    <Index.Box className="date-cancel-order">
                                        10/08/2023
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="20%" align="center" >
                                    <Index.Box className="cancel-amount-list bid-value">
                                        INR <span>50,0000</span>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="20%" align="center">
                                    <Index.Box className="myorder-btn-action">
                                        <Index.Button
                                            className='action-btn'
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                        >
                                            <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                        </Index.Button>
                                        <Index.Menu
                                            className='action-btn-menu'
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                            transformOrigin={{
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Index.MenuItem onClick={handleClose}>Preview</Index.MenuItem>
                                            <Index.MenuItem onClick={handleClose}>Cancel</Index.MenuItem>
                                        </Index.Menu>
                                    </Index.Box>
                                </Index.TableCell>
                            </Index.TableRow>
                            <Index.TableRow
                                className='cancelrequest-table-row common-table-row'
                            >
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="15%" align="left">
                                    <Index.Box className="company-list-cancel">
                                        India expo <br/>
                                        steels pvt
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="25%" align="center">
                                    <Index.Box className="cancel-product-details">
                                        <Index.List className='table-list'>
                                            <Index.ListItem className='table-list-item'>
                                                Dimensions (6 X 6)
                                            </Index.ListItem>
                                            <Index.ListItem className='table-list-item'>
                                                Qty (50 pieces)
                                            </Index.ListItem>
                                        </Index.List>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="20%" align="center">
                                    <Index.Box className="date-cancel-order">
                                        10/08/2023
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="20%" align="center" >
                                    <Index.Box className="cancel-amount-list bid-value">
                                        INR <span>50,0000</span>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="20%" align="center">
                                    <Index.Box className="myorder-btn-action">
                                        <Index.Button
                                            className='action-btn'
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                        >
                                            <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                        </Index.Button>
                                        <Index.Menu
                                            className='action-btn-menu'
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                            transformOrigin={{
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Index.MenuItem onClick={handleClose}>Preview</Index.MenuItem>
                                            <Index.MenuItem onClick={handleClose}>Cancel</Index.MenuItem>
                                        </Index.Menu>
                                    </Index.Box>
                                </Index.TableCell>
                            </Index.TableRow>
                            <Index.TableRow
                                className='cancelrequest-table-row common-table-row'
                            >
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="15%" align="left">
                                    <Index.Box className="company-list-cancel">
                                        India expo <br/>
                                        steels pvt
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="25%" align="center">
                                    <Index.Box className="cancel-product-details">
                                        <Index.List className='table-list'>
                                            <Index.ListItem className='table-list-item'>
                                                Dimensions (6 X 6)
                                            </Index.ListItem>
                                            <Index.ListItem className='table-list-item'>
                                                Qty (50 pieces)
                                            </Index.ListItem>
                                        </Index.List>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="20%" align="center">
                                    <Index.Box className="date-cancel-order">
                                        10/08/2023
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="20%" align="center" >
                                    <Index.Box className="cancel-amount-list bid-value">
                                        INR <span>50,0000</span>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='cancelrequest-table-td common-table-td' width="20%" align="center">
                                    <Index.Box className="myorder-btn-action">
                                        <Index.Button
                                            className='action-btn'
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                        >
                                            <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                        </Index.Button>
                                        <Index.Menu
                                            className='action-btn-menu'
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                            transformOrigin={{
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Index.MenuItem onClick={handleClose}>Preview</Index.MenuItem>
                                            <Index.MenuItem onClick={handleClose}>Cancel</Index.MenuItem>
                                        </Index.Menu>
                                    </Index.Box>
                                </Index.TableCell>
                            </Index.TableRow>
                        </Index.TableBody>
                    </Index.Table>
                </Index.TableContainer>
            </Index.Box>

        </>
    )
}

export default CancelRequest
import React, { useState } from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'

export default function SellerCompletedOrder() {

    // RatingAndReviewModal modal
    const [openRatingAndReviewModal, setOpenRatingAndReviewModal] = useState(false);
    const handleOpenRatingAndReviewModal = () => setOpenRatingAndReviewModal(true);
    const handleCloseRatingAndReviewModal = () => setOpenRatingAndReviewModal(false);

    return (

        <Index.Box className="seller-completed-order-main">
            <Index.TableContainer className="seller-completed-order-table-container common-table-container cus-scrollbar">
                <Index.Table aria-label="simple table" className='seller-completed-order-table common-table'>
                    <Index.TableHead className='seller-completed-order-table-head common-table-head'>
                        <Index.TableRow className='seller-completed-order-table-row common-table-row'>
                            <Index.TableCell className='seller-completed-order-table-th common-table-th' width="15%" align='left'>Order ID</Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-th common-table-th' width="23%" align='left'>Product Details</Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-th common-table-th' width="15%" align='center'>Date</Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-th common-table-th' width="15%" align='center'>Status</Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-th common-table-th' width="17%" align='center'>Payment Status</Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-th common-table-th' width="15%" align='center'>Action</Index.TableCell>
                        </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className='seller-completed-order-table-body common-table-body'>
                        <Index.TableRow className='seller-completed-order-table-row common-table-row'>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="15%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="23%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quote-be-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Box className='seller-date'>
                                    30/10/23
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="15%" align='center'>
                                <Index.Box className='order-green-text'>
                                    Completed
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="17%" align='center'>
                                Full paid
                                <Index.Box className="rating-track-order-list">
                                    <Index.Box className="ratinig-track-content">
                                        <PagesIndex.Rating />
                                    </Index.Box>
                                    <Index.Box className="rat-box-edit" onClick={handleOpenRatingAndReviewModal}>
                                        <Index.Button className="edit-rate-icon"><img src={PagesIndex.Svg.editrat} alt="edit" className="edit-rat"></img></Index.Button>
                                    </Index.Box>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="15%" align='center'>
                                <Index.Button className='action-btn'>
                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                </Index.Button>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='seller-completed-order-table-row common-table-row'>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="15%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="23%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quote-be-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Box className='seller-date'>
                                    30/10/23
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="15%" align='center'>
                                <Index.Box className='order-green-text'>
                                    Completed
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="17%" align='center'>
                                Full paid
                                <Index.Box className="rating-track-order-list">
                                    <Index.Box className="ratinig-track-content">
                                        <PagesIndex.Rating />
                                    </Index.Box>
                                    <Index.Box className="rat-box-edit" onClick={handleOpenRatingAndReviewModal}>
                                        <Index.Button className="edit-rate-icon"><img src={PagesIndex.Svg.editrat} alt="edit" className="edit-rat"></img></Index.Button>
                                    </Index.Box>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="15%" align='center'>
                                <Index.Button className='action-btn'>
                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                </Index.Button>
                            </Index.TableCell>
                        </Index.TableRow>
                        <Index.TableRow className='seller-completed-order-table-row common-table-row'>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="15%" align='left'>
                                <Index.Box className='enquiry-order'>
                                    #1234
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="23%" align='left'>
                                <Index.Box className="order-product-details">
                                    <img src={PagesIndex.Png.cart} className='cart-order-img' alt="cart-order"></img>
                                    <Index.Typography component='p' variant='p' className='cart-order-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection </Index.Typography>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-quote-be-shared-table-td common-table-td' width="15%" align='center'>
                                <Index.Box className='seller-date'>
                                    30/10/23
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="15%" align='center'>
                                <Index.Box className='order-green-text'>
                                    Completed
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="17%" align='center'>
                                Full paid
                                <Index.Box className="rating-track-order-list">
                                    <Index.Box className="ratinig-track-content">
                                        <PagesIndex.Rating />
                                    </Index.Box>
                                    <Index.Box className="rat-box-edit" onClick={handleOpenRatingAndReviewModal}>
                                        <Index.Button className="edit-rate-icon"><img src={PagesIndex.Svg.editrat} alt="edit" className="edit-rat"></img></Index.Button>
                                    </Index.Box>
                                </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell className='seller-completed-order-table-td common-table-td' width="15%" align='center'>
                                <Index.Button className='action-btn'>
                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                </Index.Button>
                            </Index.TableCell>
                        </Index.TableRow>
                    </Index.TableBody>
                </Index.Table>
            </Index.TableContainer>

            <PagesIndex.RatingAndReviewModal openRatingAndReviewModal={openRatingAndReviewModal} handleCloseRatingAndReviewModal={handleCloseRatingAndReviewModal} />
        </Index.Box>

    )

}
import React from 'react'
import './myCart.css'
import './myCart.responsive.css'
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useNavigate } from 'react-router-dom';



// for modal design 

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};



function MyCart() {
    
    const navigate = useNavigate()
    // EnquirySuccess modal
    const [openEnquirySuccess, setOpenEnquirySuccess] = React.useState(false);
    const handleOpenEnquirySuccess = () => setOpenEnquirySuccess(true);
    const handleCloseEnquirySuccess = () => setOpenEnquirySuccess(false);


    return (
        <>

            <Index.Box className="my-cart-bg-main">
                <Index.Box className="mycart-breadcrumb">
                    <PagesIndex.Breadcrumb />
                </Index.Box>
                <Index.Box className="cus-container">
                    <Index.Box className="mycart-details-content">
                        <Index.Box className="mycart-details-title common-buyer-title">
                            <Index.Typography component='h6' variant='h6' className="common-title-inner">My Cart</Index.Typography>
                        </Index.Box>
                        <Index.Box className="mycart-row-details">
                            <Index.Box className="mycart-col1">
                                <Index.Box className="item-show-content">
                                    <Index.Box className="show-item-content">
                                        <Index.Typography component='p' variant='p' className="show-item-details">3 item(s)in your cart</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="show-purchase-item cus-scrollbar">
                                        <Index.Box className="listing-cart-details">
                                            <Index.Box className="listing-title-details">
                                                <Index.Box className="title-cart-col1 common-cart-details">
                                                    <Index.Typography component='p' variant='p' className="title-cart-listing">Item</Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="title-cart-col2 common-cart-details">
                                                    <Index.Typography component='p' variant='p' className="title-cart-listing">Requirements</Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="title-cart-col3 common-cart-details">
                                                    <Index.Typography component='p' variant='p' className="title-cart-listing">Qty</Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="title-cart-col4 common-cart-details">
                                                    <Index.Typography component='p' variant='p' className="title-cart-listing"></Index.Typography>
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="listing-cart-details-show">
                                                <Index.Box className="listing-title-details">
                                                    <Index.Box className="title-cart-col1 common-cart-details">
                                                        <Index.Box className="item-details-cart">
                                                            <img src={PagesIndex.Png.cart} alt="cart" className="cart-img-show"></img>
                                                            <Index.Box className="description-details-cart">
                                                                <Index.Typography component='p' variant='p' className='description-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection ..</Index.Typography>
                                                                <Index.Typography component='p' variant='p' className='brand-details-content'>Brand name</Index.Typography>
                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="title-cart-col2 common-cart-details">
                                                        <Index.Box className="listing-requirement">
                                                            <Index.List className="list-main-requirement">
                                                                <Index.ListItem className='listitem-requiremnt'><span className="list-style-span"></span>Type (Anodised)</Index.ListItem>
                                                                <Index.ListItem className='listitem-requiremnt'><span className="list-style-span"></span>Size (6 X 6)</Index.ListItem>
                                                            </Index.List>
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="title-cart-col3 common-cart-details">
                                                        <Index.Box className="qty-check-details">
                                                            <Index.Button className="qty-minus-btn qty-btn-main">
                                                                <span>-</span>
                                                            </Index.Button>

                                                            <Index.Box className="qty-input-main">
                                                                <Index.Input className="qty-input-details" value="50" autoComplete="off" />
                                                            </Index.Box>

                                                            <Index.Button className="qty-puls-btn qty-btn-main">
                                                                <span>+</span>
                                                            </Index.Button>
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="title-cart-col4 common-cart-details">
                                                        <Index.Box className="action-cart">
                                                            <Index.Link className="common-action-cart primary-edit-color">Edit</Index.Link>
                                                            <Index.Link className="common-action-cart danger-edit-color">Delete</Index.Link>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="listing-title-details">
                                                    <Index.Box className="title-cart-col1 common-cart-details">
                                                        <Index.Box className="item-details-cart">
                                                            <img src={PagesIndex.Png.cart} alt="cart" className="cart-img-show"></img>
                                                            <Index.Box className="description-details-cart">
                                                                <Index.Typography component='p' variant='p' className='description-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection ..</Index.Typography>
                                                                <Index.Typography component='p' variant='p' className='brand-details-content'>Brand name</Index.Typography>
                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="title-cart-col2 common-cart-details">
                                                        <Index.Box className="listing-requirement">
                                                            <Index.List className="list-main-requirement">
                                                                <Index.ListItem className='listitem-requiremnt'><span className="list-style-span"></span>Type (Anodised)</Index.ListItem>
                                                                <Index.ListItem className='listitem-requiremnt'><span className="list-style-span"></span>Size (6 X 6)</Index.ListItem>
                                                            </Index.List>
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="title-cart-col3 common-cart-details">
                                                        <Index.Box className="qty-check-details">
                                                            <Index.Button className="qty-minus-btn qty-btn-main">
                                                                <span>-</span>
                                                            </Index.Button>

                                                            <Index.Box className="qty-input-main">
                                                                <Index.Input className="qty-input-details" value="50" autoComplete="off" />
                                                            </Index.Box>

                                                            <Index.Button className="qty-puls-btn qty-btn-main">
                                                                <span>+</span>
                                                            </Index.Button>
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="title-cart-col4 common-cart-details">
                                                        <Index.Box className="action-cart">
                                                            <Index.Link className="common-action-cart primary-edit-color">Edit</Index.Link>
                                                            <Index.Link className="common-action-cart danger-edit-color">Delete</Index.Link>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="listing-title-details">
                                                    <Index.Box className="title-cart-col1 common-cart-details">
                                                        <Index.Box className="item-details-cart">
                                                            <img src={PagesIndex.Png.cart} alt="cart" className="cart-img-show"></img>
                                                            <Index.Box className="description-details-cart">
                                                                <Index.Typography component='p' variant='p' className='description-details'>Fastgear 24 inch 15mm Steel Black Wire Plumbing Connection ..</Index.Typography>
                                                                <Index.Typography component='p' variant='p' className='brand-details-content'>Brand name</Index.Typography>
                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="title-cart-col2 common-cart-details">
                                                        <Index.Box className="listing-requirement">
                                                            <Index.List className="list-main-requirement">
                                                                <Index.ListItem className='listitem-requiremnt'><span className="list-style-span"></span>Type (Anodised)</Index.ListItem>
                                                                <Index.ListItem className='listitem-requiremnt'><span className="list-style-span"></span>Size (6 X 6)</Index.ListItem>
                                                            </Index.List>
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="title-cart-col3 common-cart-details">
                                                        <Index.Box className="qty-check-details">
                                                            <Index.Button className="qty-minus-btn qty-btn-main">
                                                                <span>-</span>
                                                            </Index.Button>

                                                            <Index.Box className="qty-input-main">
                                                                <Index.Input className="qty-input-details" value="50" autoComplete="off" />
                                                            </Index.Box>

                                                            <Index.Button className="qty-puls-btn qty-btn-main">
                                                                <span>+</span>
                                                            </Index.Button>
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="title-cart-col4 common-cart-details">
                                                        <Index.Box className="action-cart">
                                                            <Index.Link className="common-action-cart primary-edit-color">Edit</Index.Link>
                                                            <Index.Link className="common-action-cart danger-edit-color">Delete</Index.Link>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="mycart-col2">
                                <Index.Box className="summary-details-show">
                                    <Index.Box className="summary-card-details">
                                        <Index.Typography component='p' className='summary-title'>Summary</Index.Typography>
                                        <Index.Box className="summary-details">
                                            <Index.Box className="summary-brand-details">
                                                <Index.Typography component='p' variant='p' className='brand-select-name'>Brands selected</Index.Typography>
                                                <Index.Typography component='p' variant='p' className='brand-select-value'>03</Index.Typography>
                                            </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="payment-term-details">
                                            <Index.Box className="terms-title">
                                                <Index.Typography component='p' variant='p' className='payment-terms-titles'>Payment term</Index.Typography>
                                            </Index.Box>
                                            <Index.Box className="payemt-term-drop-compo">
                                                <PagesIndex.DropDown />
                                            </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="send-enquiry-main-btn">
                                            <PagesIndex.OrangeFillButton btnLabel="Send enquiry" onClick={handleOpenEnquirySuccess} className="orange-fill-btn send-enquiry-btn" />
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>

            <Index.Modal
                open={openEnquirySuccess}
                onClose={handleCloseEnquirySuccess}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modal-common-main'
            >
                <Index.Box sx={style} className="goto-veryfication-modal-inner-main modal-common-style">
                    <Index.Box className="cus-scrollbar modal-hgt-scroll">
                        <Index.Box className="modal-header">
                            <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseEnquirySuccess} />
                        </Index.Box>
                        <Index.Box className="modal-logo-main">
                            <img src={PagesIndex.Svg.logo} className='modal-logo' />
                        </Index.Box>
                        <Index.Typography className='veryfy-modal-para' component="p" variant='p'>Enquiry has been placed!<br />You can check your Qutations right now</Index.Typography>
                        <Index.Box className="modal-veryfy-btn-main" >
                            <PagesIndex.BlueFillButton 
                            btnLabel="Go to my Quotations" 
                            className="blue-fill-btn veryfy-modal-btn"
                            onClick={() => navigate('/dashboard', {
                                state: {num : 2}
                            })}
                            />
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>

        </>
    )
}

export default MyCart
import React from 'react'

export default function WorkIcon() {
  return (

    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.66667 17.8333C3.20833 17.8333 2.81597 17.6701 2.48958 17.3437C2.16319 17.0174 2 16.625 2 16.1667V7C2 6.54167 2.16319 6.14931 2.48958 5.82292C2.81597 5.49653 3.20833 5.33333 3.66667 5.33333H7V3.66667C7 3.20833 7.16319 2.81597 7.48958 2.48958C7.81597 2.16319 8.20833 2 8.66667 2H12C12.4583 2 12.8507 2.16319 13.1771 2.48958C13.5035 2.81597 13.6667 3.20833 13.6667 3.66667V5.33333H17C17.4583 5.33333 17.8507 5.49653 18.1771 5.82292C18.5035 6.14931 18.6667 6.54167 18.6667 7V16.1667C18.6667 16.625 18.5035 17.0174 18.1771 17.3437C17.8507 17.6701 17.4583 17.8333 17 17.8333H3.66667ZM3.66667 16.1667H17V7H3.66667V16.1667ZM8.66667 5.33333H12V3.66667H8.66667V5.33333Z" fill="#333333" />
    </svg>

  )
}

import React, { useState } from 'react'
import Index from '../../../../Index'
import PagesIndex from '../../../../PagesIndex'
import { styled } from '@mui/material/styles';
import './ra.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function Ra() {

    const [openRa, setOpenRa] = useState(false);
    const handleOpenRa = () => setOpenRa(true);
    const handleCloseRa = () => setOpenRa(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>

            <Index.Box className="ra-content-details">
                <Index.Box className="quotation-header dashboard-common-header">
                    <Index.Box className="header-title">
                        Reverse Auction
                    </Index.Box>
                    <Index.Box className="header-search">
                        <Index.Input
                            autoFocus
                            className="header-search-input"
                            placeholder="Search quotations"
                        />
                        <img src={Index.Svg.greysearch} className='search-icon' alt='search icon' />
                    </Index.Box>
                </Index.Box>
                <Index.TableContainer className="table-bottom-container common-table-container cus-scrollbar">
                    <Index.Table aria-label="simple table" className='table-bottom common-table action-common-table'>
                        <Index.TableHead className='table-bottom-head common-table-head'>
                            <Index.TableRow className='table-bottom-row common-table-row'>
                                <Index.TableCell className='table-bottom-th common-table-th' width="5%" align='left'></Index.TableCell>
                                <Index.TableCell className='table-bottom-th common-table-th' width="12%" align='left'>Enquiry no.</Index.TableCell>
                                <Index.TableCell className='table-bottom-th common-table-th' width="14%" align='left'>Brand Name</Index.TableCell>
                                <Index.TableCell className='table-bottom-th common-table-th' width="20%" align='center'>Requirements</Index.TableCell>
                                <Index.TableCell className='table-bottom-th common-table-th' width="10%" align='center'>Date</Index.TableCell>
                                <Index.TableCell className='table-bottom-th common-table-th' width="14%" align='center'>Bid</Index.TableCell>
                                <Index.TableCell className='table-bottom-th common-table-th' width="17%" align='center'>Reverse Auction</Index.TableCell>
                                <Index.TableCell className='table-bottom-th common-table-th' width="8%" align='center'>Action</Index.TableCell>
                            </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className='table-bottom-body common-table-body'>
                            <Index.TableRow>
                                <Index.TableCell width="100%" colSpan="8" sx={{ padding: 0 }} className='action-common-table-td'>
                                    <Index.Table>
                                        <Index.TableRow className='table-bottom-row common-table-row'>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="5%" align='left'>
                                                <Index.Box className='action-select'>
                                                    <Index.Checkbox />
                                                </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="12%" align='left'>
                                                00123
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="14%" align='left'>
                                                India expo steels pvt
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="20%" align='center'>
                                                <Index.List className='table-list'>
                                                    <Index.ListItem className='table-list-item'>
                                                        Dimensions (6 X 6)
                                                    </Index.ListItem>
                                                    <Index.ListItem className='table-list-item'>
                                                        Qty (50 pieces)
                                                    </Index.ListItem>
                                                </Index.List>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="10%" align='center'>
                                                10/08/2023
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="14%" align='center'>
                                                <Index.Box className="bid-value">
                                                    INR 6,00,0000
                                                </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="17%" align='center'>
                                                <Index.Box className="ra-value request-color-text">
                                                    Request
                                                    <br />
                                                    sent
                                                </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="8%" align='center'>
                                                <Index.Button
                                                    className='action-btn'
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick}
                                                >
                                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                                </Index.Button>
                                                <Index.Menu
                                                    className='action-btn-menu'
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                    transformOrigin={{
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <Index.MenuItem>Preview</Index.MenuItem>
                                                </Index.Menu>
                                            </Index.TableCell>
                                        </Index.TableRow>
                                    </Index.Table>
                                </Index.TableCell>
                            </Index.TableRow>
                            <Index.TableRow>
                                <Index.TableCell width="100%" colSpan="8" sx={{ padding: 0 }} className='action-common-table-td'>
                                    <Index.Table>
                                        <Index.TableRow className='table-bottom-row common-table-row'>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="5%" align='left'>
                                                <Index.Box className='action-select'>
                                                    <Index.Checkbox />
                                                </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="12%" align='left'>
                                                00123
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="14%" align='left'>
                                                India expo steels pvt
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="20%" align='center'>
                                                <Index.List className='table-list'>
                                                    <Index.ListItem className='table-list-item'>
                                                        Dimensions (6 X 6)
                                                    </Index.ListItem>
                                                    <Index.ListItem className='table-list-item'>
                                                        Qty (50 pieces)
                                                    </Index.ListItem>
                                                </Index.List>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="10%" align='center'>
                                                10/08/2023
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="14%" align='center'>
                                                <Index.Box className="bid-value">
                                                    INR 6,00,0000
                                                </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="17%" align='center'>
                                                <Index.Box className="ra-value scheduled-color-text">
                                                    Scheduled<br />
                                                    on 24/08/23
                                                </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="8%" align='center'>
                                                <Index.Button
                                                    className='action-btn'
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick}
                                                >
                                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                                </Index.Button>
                                                <Index.Menu
                                                    className='action-btn-menu'
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                    transformOrigin={{
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <Index.MenuItem>Preview</Index.MenuItem>
                                                </Index.Menu>
                                            </Index.TableCell>
                                        </Index.TableRow>

                                    </Index.Table>
                                </Index.TableCell>
                            </Index.TableRow>
                            <Index.TableRow>
                                <Index.TableCell width="100%" colSpan="8" sx={{ padding: 0 }} className='action-common-table-td'>
                                    <Index.Table>
                                        <Index.TableRow className='table-bottom-row common-table-row'>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="5%" align='left'>
                                                <Index.Box className='action-select'>
                                                    <Index.Checkbox />
                                                </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="12%" align='left'>
                                                00123
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="14%" align='left'>
                                                India expo steels pvt
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="20%" align='center'>
                                                <Index.List className='table-list'>
                                                    <Index.ListItem className='table-list-item'>
                                                        Dimensions (6 X 6)
                                                    </Index.ListItem>
                                                    <Index.ListItem className='table-list-item'>
                                                        Qty (50 pieces)
                                                    </Index.ListItem>
                                                </Index.List>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="10%" align='center'>
                                                10/08/2023
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="14%" align='center'>
                                                <Index.Box className="bid-value">
                                                    INR 6,00,0000
                                                </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="17%" align='center'>
                                                <Index.Box className="ra-value">
                                                    TATA steel <br />
                                                    INR  49,00,0000
                                                </Index.Box>
                                            </Index.TableCell>
                                            <Index.TableCell className='table-bottom-td common-table-td' width="8%" align='center'>
                                                <Index.Button
                                                    className='action-btn'
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick}
                                                >
                                                    <img src={PagesIndex.Svg.moreVert} alt='action icon' />
                                                </Index.Button>
                                                <Index.Menu
                                                    className='action-btn-menu'
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                    transformOrigin={{
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <Index.MenuItem >Preview</Index.MenuItem>
                                                </Index.Menu>
                                            </Index.TableCell>
                                        </Index.TableRow>
                                        <Index.TableRow>
                                            <Index.TableCell className='table-bottom-td common-table-td table-row-action-td' width="100%" colSpan="8">
                                                <Index.Box className="table-row-action">
                                                    <PagesIndex.OrangeFillButton btnLabel="Accept" className="orange-fill-btn" />
                                                    <PagesIndex.OrangeOutlineButton onClick={handleOpenRa} btnLabel="Add Comment" className="orange-outline-inner" />
                                                </Index.Box>
                                            </Index.TableCell>
                                        </Index.TableRow>
                                    </Index.Table>
                                </Index.TableCell>
                            </Index.TableRow>
                        </Index.TableBody>
                    </Index.Table>
                </Index.TableContainer>
            </Index.Box >

            {/* RA Details start */}
            <Index.Box className="modal-viewpo-payment">
                <Index.Modal
                    className="modal-common-main modal-viewpo-details"
                    open={openRa}
                    onClose={handleCloseRa}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Index.Box sx={style} className="modal-common-style proof-viewpo-style ">
                        <Index.Box className="cus-scrollbar modal-hgt-scroll">
                            <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseRa} />
                            <Index.Box className="ra-comment-preview">
                                <Index.Box className="textarea-common-control">
                                    <Index.TextareaAutosize className='common-textarea-autosize' minRows={8} placeholder='Add a comment...' />
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="btnupload-payment-footer">
                                <Index.Box className="orangefill-btn-confirm btn-comman-mrl">
                                    <PagesIndex.OrangeFillButton btnLabel="Send" className="orange-fill-btn" />
                                </Index.Box>
                                <Index.Box className="orange-outline-payment btn-comman-mrl">
                                    <PagesIndex.OrangeOutlineButton btnLabel="Cancel" className="orange-outline-inner cancel-payment" />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Modal>
            </Index.Box>
            {/* RA Details end */}

        </>
    )
}

export default Ra
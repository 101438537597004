import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import PageIndex from "../container/PagesIndex";
// import DataService from "../config/DataService";
// import { adminLogout } from "../redux/features/slices/admin/AdminSlice";

export default function UserPrivateRoute() {
  const { userToken } = PageIndex.useSelector((state) => state.user);

  const isAuthenticate = (token) => {
    if (!token) return false;
    PageIndex.DataService.defaults.headers.common.auth = token;
    return true;
  };

  return isAuthenticate(userToken) ? (
    <Outlet />
  ) : (
    <PageIndex.Navigate to="/" replace={true} />
  );
}

import React from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PagesIndex";
import './categoriessection.css'
import './categoriessection.responsive.css'

function Categoriessection() {
  return (
    <Index.Box className="view-our-category-wrape" component="section">
      <Index.Box className="cus-container">
        <Index.Box className="title-box">
          <Index.Typography component="h4" className="title">
            View our categories
          </Index.Typography>
          <Index.Typography className="sub-title">
            STELMART primarily specializes in two product categories: Steel products and Polymer products.
          </Index.Typography>
        </Index.Box>

        <Index.Box className="category-box-wrapper">
          <Index.Box className="category-box">
            <Index.Box className="img-wrape">
              <Index.Typography component="h6">Polymer</Index.Typography>
              <img src={PageIndex.Png.polymerOriginal} />
            </Index.Box>
            <Index.Box className="bottom-info">
              <Index.Typography component="p" className="detail">
                A wide range of UPVC, CPVC, HDPE pipes and fittings...
              </Index.Typography>
              <Index.Link to="/coming-soon" className="link">
                <img src={PageIndex.Svg.roundarrow} className="arrow" />{" "}
              </Index.Link>
            </Index.Box>
          </Index.Box>
          <Index.Box className="category-box">
            <Index.Box className="img-wrape">
              <Index.Typography component="h6">Steel</Index.Typography>
              <img src={PageIndex.Png.steel} />
            </Index.Box>
            <Index.Box className="bottom-info">
              <Index.Typography component="p" className="detail">
                Seamless Steel Pipes, ERW pipes, LSAW pipes, HSAW pipes, Steel Sheets, coil...
              </Index.Typography>
              <Index.Link to="/coming-soon" className="link">
                <img src={PageIndex.Svg.roundarrow} className="arrow" />{" "}
              </Index.Link>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

export default Categoriessection
import React from "react";
import Index from "../../Index";
import "./rating.css";
import StarIcon from "@mui/icons-material/Star";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function Rating({ handleChange, valueData, name }) {
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  return (
    <>
      <Index.Box className="rating-main">
        <Index.Rating
          //     name="hover-feedback"
          value={valueData}
          precision={0.5}
          getLabelText={getLabelText}
          //     onChange={(event, newValue) => {
          //       setValue(newValue);
          //     }}
          onChange={handleChange}
          name={name}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon className="star-icon" />}
        />
      </Index.Box>
    </>
  );
}

import React, { useState } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'
import "./productDetail.css"
import './productDetail.responsive.css'
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

function ProductDetailTab(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box>
          {children}
        </Index.Box>
      )}
    </div>
  );
}

ProductDetailTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ProductDetail() {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate()
  // EnquirySuccess modal
  const [openEnquirySuccess, setOpenEnquirySuccess] = useState(false);
  const handleOpenEnquirySuccess = () => setOpenEnquirySuccess(true);
  const handleCloseEnquirySuccess = () => setOpenEnquirySuccess(false);

  return (
    <>
      <Index.Box className="main-product-detail" component="main">
        <PagesIndex.Breadcrumb />
        <Index.Box className="cus-container">
          <Index.Box className="product-detail-wrapper">
            <Index.Box className="product-detail-left">
              <Index.Box className="product-title-box">
                <Index.Box className="product-title">
                  Mild Steel Sheet
                </Index.Box>
                <Index.Box className="product-content-box">
                  <Index.Box className="product-content">
                    Lorem ipsum dolor sit amet consectetur. Vulputate sodales neque nibh semper nec tellus.
                  </Index.Box>
                  <Index.Box className="product-review-box">
                    <Index.Box className="product-review-star">
                      4.5
                      <PagesIndex.StarIcon />
                    </Index.Box>
                    <Index.Box className="product-review-count">
                      (25 Reviews)
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-image-box">
                <Index.Box className="product-image-thumb-box">
                  <img src={PagesIndex.Jpg.productImg1} alt='Product' className='product-image-thumb' />
                  <img src={PagesIndex.Jpg.productImg1} alt='Product' className='product-image-thumb' />
                  <Index.Box className="product-image-more">
                    <PagesIndex.PlusIcon />
                    More
                  </Index.Box>
                </Index.Box>
                <Index.Box className="product-image-main">
                  <img src={PagesIndex.Jpg.productImg1} alt='Product' className='product-image' />
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="product-detail-right">
              <Index.Box className="product-select-top">
                <Index.Box className="product-select-row">
                  <Index.Box className='product-select-label'>
                    Brand
                  </Index.Box>
                  <Index.Box className="product-select-drop-main">
                    <PagesIndex.DropDown />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="product-select-row">
                  <Index.Box className='product-select-label'>
                    Dimensions
                  </Index.Box>
                  <Index.Box className="product-select-drop-main">
                    <PagesIndex.DropDown />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="product-select-row">
                  <Index.Box className='product-select-label'>
                    Qty
                  </Index.Box>
                  <Index.Box className='product-select-qty'>
                    <Index.Button className='product-select-qty-btn'>
                      -
                    </Index.Button>
                    <Index.Input value="2" />
                    <Index.Button className='product-select-qty-btn'>
                      +
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="product-select-row">
                  <Index.Box className='product-select-label'>
                    Payment term
                  </Index.Box>
                  <Index.Box className="product-select-drop-main">
                    <PagesIndex.DropDown />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <PagesIndex.OrangeFillButton btnLabel="Send enquiry" onClick={handleOpenEnquirySuccess} className="orange-fill-btn send-enquiry-btn" />
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="product-detail-tab-bg">
          <Index.Box className="cus-container">
            <Index.Box className="product-detail-tab-wrapper">
              <Index.Tabs
                variant="scrollable"
                scrollButtons={false}
                value={value}
                onChange={handleChange}
                className="product-detail-tab-box"
                aria-label="product-detail-tab-box"
              >
                <Index.Tab className="product-detail-tab-btn" label="Description" {...a11yProps(0)} />
                <Index.Tab className="product-detail-tab-btn" label="Specification" {...a11yProps(1)} />
              </Index.Tabs>
              <ProductDetailTab value={value} index={0} className="product-detail-tab-panel">
                Lorem ipsum dolor sit amet consectetur. Amet tincidunt fringilla erat varius massa tempus velit hac dolor. Pellentesque praesent viverra at quis vitae id. Eros ultrices nunc ipsum sit tincidunt nisl egestas nibh. Id enim purus consectetur in condimentum. Volutpat in feugiat enim et tortor faucibus pellentesque massa. Eget mauris ullamcorper egestas arcu lacus gravida curabitur fermentum commodo.
                Malesuada donec sed tincidunt commodo. Felis dictumst adipiscing vitae dis tellus id arcu mi sapien. Urna nam at tortor tempus consectetur nulla aliquet eget. Ornare egestas quisque et eget nam lorem placerat adipiscing. Auctor commodo pulvinar urna id. Nulla in amet malesuada nulla. Integer malesuada aliquet phasellus cursus. In scelerisque id eget sagittis pellentesque. Tortor sodales fringilla vitae aliquam feugiat quisque. Enim at nunc mattis ligula euismod a scelerisque. Urna odio nisl id lacinia lorem pulvinar facilisis. Tristique facilisis sed pellentesque id tincidunt augue. Sed netus nulla eget id.
              </ProductDetailTab>
              <ProductDetailTab value={value} index={1} className="product-detail-tab-panel">
                Lorem ipsum dolor sit amet consectetur. Amet tincidunt fringilla erat varius massa tempus velit hac dolor. Pellentesque praesent viverra at quis vitae id. Eros ultrices nunc ipsum sit tincidunt nisl egestas nibh. Id enim purus consectetur in condimentum. Volutpat in feugiat enim et tortor faucibus pellentesque massa. Eget mauris ullamcorper egestas arcu lacus gravida curabitur fermentum commodo.
              </ProductDetailTab>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <PagesIndex.YouMayLike />

      </Index.Box>
      <Index.Modal
        open={openEnquirySuccess}
        onClose={handleCloseEnquirySuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='modal-common-main'
      >
        <Index.Box sx={style} className="goto-veryfication-modal-inner-main modal-common-style">
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <img src={PagesIndex.Svg.closeicon} className="modal-close-icon" onClick={handleCloseEnquirySuccess} />
            </Index.Box>
            <Index.Box className="modal-logo-main">
              <img src={PagesIndex.Svg.logo} className='modal-logo' />
            </Index.Box>
            <Index.Typography className='veryfy-modal-para' component="p" variant='p'>Enquiry has been placed!<br />You can check your Qutations right now</Index.Typography>
            <Index.Box className="modal-veryfy-btn-main" >
              <PagesIndex.BlueFillButton
                btnLabel="Go to my Quotations"
                className="blue-fill-btn veryfy-modal-btn"
                onClick={() => navigate('/dashboard', {
                  state: { num: 2 }
                })}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  )
}

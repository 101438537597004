import React from 'react'
import Index from '../../Index'
import PagesIndex from '../../PagesIndex'

export default function Footer() {
  // ContactUsModal modal
  const [openContactUsModal, setOpenContactUsModal] = React.useState(false);
  const handleOpenContactUsModal = () => setOpenContactUsModal(true);
  const handleCloseContactUsModal = () => setOpenContactUsModal(false);

  return (
    <Index.Box className="footer" component="footer" >
      <Index.Box className="cus-container">
        <Index.Box className='footer-row'>
          <Index.Box className='footer-logo-box'>
            <Index.Link to="/" className="footer-logo-Link">
              <img src={PagesIndex.Svg.logo} className='footer-logo' alt='logo' />
            </Index.Link>
          </Index.Box>
          <Index.Box className="footer-col-box">
            <Index.Box className="footer-col">
              <Index.Typography className='footer-nav-titles'>Address</Index.Typography>
              <Index.Typography className='footer-address'>
                Electrip Private Limited <br />606, Unitech Arcadia, South City-2, Sector-49, <br />Gurgaon, Haryana-122018
              </Index.Typography>
              <Index.Typography className='footer-contact'>
                <b>Landline Number:</b> <Index.Link to="tel:0124-4438002" className='footer-contact-link'>0124-4438002</Index.Link>
              </Index.Typography>
            </Index.Box>
            <Index.Box className="footer-col">
              <Index.Box className="footer-nav-main">
                <Index.Typography className='footer-nav-titles'>Solutions</Index.Typography>

                <Index.Box className='footer-nav-ul'>
                  <Index.Link to="#" className='footer-nav-link'>Extensive product search</Index.Link>
                  <Index.Link to="#" className='footer-nav-link'>Strategic Financing</Index.Link>
                  <Index.Link to="#" className='footer-nav-link'>Supply chain management</Index.Link>
                  <Index.Link to="#" className='footer-nav-link'>Technical support</Index.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="footer-col">
              <Index.Box className="footer-nav-main">
                <Index.Typography className='footer-nav-titles'>Company</Index.Typography>
                <Index.Box className='footer-nav-ul'>
                  <Index.Link to="/about" className='footer-nav-link'>About Stelmart</Index.Link>
                  <Index.Box className='footer-nav-link' onClick={handleOpenContactUsModal}>Contact Us</Index.Box>
                  <Index.Link to="/career" className='footer-nav-link'>Careers</Index.Link>
                  <Index.Link to="/blogs" className='footer-nav-link'>Blogs</Index.Link>
                  <Index.Link to="/product-listing" className='footer-nav-link'>Product Catalogue</Index.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="footer-col">
              <Index.Box className="footer-nav-main">
                <Index.Typography className='footer-nav-titles'>Social</Index.Typography>
                <Index.Box className='social-wrape'>
                  <Index.Link to="#" className='footer-nav-link'>
                    <img src={PagesIndex.Svg.insta} />
                  </Index.Link>
                  <Index.Link to="#" className='footer-nav-link'>
                    <img src={PagesIndex.Svg.facebook} />
                  </Index.Link>
                  <Index.Link to="#" className='footer-nav-link'>
                    <img src={PagesIndex.Svg.linkedin} />
                  </Index.Link>
                  <Index.Link to="#" className='footer-nav-link'>
                    <img src={PagesIndex.Svg.twiter} />
                  </Index.Link>
                </Index.Box>
              </Index.Box>
              <Index.Box className="app-store-box">
                <Index.Link to="/coming-soon" className='app-store-link apple'>
                  <img src={PagesIndex.Svg.appleStore} alt='apple store' />
                </Index.Link>
                <Index.Link to="/coming-soon" className='app-store-link play'>
                  <img src={PagesIndex.Svg.playStore} alt='play store' />
                </Index.Link>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="footer-copy-right-box">
          All Rights Reserved
          &nbsp;|&nbsp;
          <Index.Link to="/coming-soon">Terms and Conditions</Index.Link>
          &nbsp;|&nbsp;
          <Index.Link to="/coming-soon">Privacy Policy</Index.Link>
        </Index.Box>
      </Index.Box>
      <PagesIndex.ContactUsModal handleCloseContactUsModal={handleCloseContactUsModal} openContactUsModal={openContactUsModal} />
    </Index.Box >
  )
}

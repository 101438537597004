import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import PagesIndex from '../../PagesIndex';

const Layout = () => {

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }
  useEffect(() => {
    onTop()
  }, [routePath]);

  const [loading, isLoading] = useState(false);

  useEffect(() => {
    isLoading(true)
    setTimeout(() => {
      isLoading(false)
    }, 3000)
  }, [])

  return (
    <>
      <PagesIndex.Header />
      {loading && <PagesIndex.Loader />}
      <Outlet />
      <PagesIndex.Footer />
    </>
  )
}

export default Layout;
import React from 'react'

export default function NotificationIcon() {
  return (

    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.6654 15.97L17.4981 15.8224C17.0234 15.3995 16.6079 14.9145 16.2629 14.3805C15.886 13.6435 15.6601 12.8387 15.5985 12.0133V9.58222C15.6017 8.28576 15.1315 7.03273 14.276 6.05856C13.4205 5.08439 12.2388 4.45614 10.9528 4.29184V3.65699C10.9528 3.48275 10.8836 3.31564 10.7604 3.19243C10.6372 3.06922 10.47 3 10.2958 3C10.1216 3 9.95445 3.06922 9.83124 3.19243C9.70803 3.31564 9.63881 3.48275 9.63881 3.65699V4.30168C8.36432 4.47783 7.19686 5.10988 6.35264 6.08078C5.50842 7.05167 5.04466 8.29562 5.04725 9.58222V12.0133C4.98562 12.8387 4.75974 13.6435 4.38288 14.3805C4.04375 14.9131 3.63494 15.3981 3.16732 15.8224L3 15.97V17.3578H17.6654V15.97Z" fill="#333333" />
      <path d="M9.03857 17.8746C9.08173 18.1865 9.23631 18.4723 9.47376 18.6792C9.71121 18.8861 10.0155 19 10.3304 19C10.6453 19 10.9496 18.8861 11.1871 18.6792C11.4245 18.4723 11.5791 18.1865 11.6222 17.8746H9.03857Z" fill="#333333" />
    </svg>

  )
}

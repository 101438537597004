import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import * as Yup from "yup";

const status = ["Confirmed", "In Process", "Shipped", "Delivered"];
export default function EditOrder() {
  const navigate = useNavigate();
  const location = useLocation();
  const row = location?.state?.row;
  const [isLoading, setIsLoading] = useState(false);
  const [singleData, setSingleData] = useState({});
  console.log(singleData, 123);
  const [statusData, setStatusData] = useState(status);
  const initialValues = {
    buyerName: singleData ? singleData?.buyerName?.fullName : "",
    productName: "",
    quantity: singleData ? singleData?.buyerName?.quantity : "",
    amount: singleData ? singleData?.amount : "",
    status: singleData ? singleData?.status : "",
  };
  const validationSchema = Yup.object().shape({
    buyerName: Yup.string().required("Please enter buyer name"),
    amount: Yup.string().required("Please enter your amount"),
    status: Yup.string().required("Please select your status"),
  });

  const cancelBtn = () => {
    navigate("/admin/order-management");
  };

  const handleFormSubmit = async (values) => {
    console.log(values, 13);
    const data = new URLSearchParams();

    data.append("id", row?._id);
    // data.append("productName", row?.buyerName);
    data.append("amount", values.amount);
    data.append("status", values.status);
    await DataService.post(Api.Admin.UPDATE_ORDER_DETAIL, data)
      .then((res) => {
        console.log(res, 21);
        toast.success(res?.data?.message);
        if (res?.data?.status == 200) {
          navigate("/admin/order-management");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSingleData = async () => {
    setIsLoading(true);
    await DataService.get(`${Api.Admin.GET_SINGLE_ORDER}`, {
      params: { id: row },
    })
      .then((res) => {
        console.log(res, 53);
        setSingleData(res?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, 55);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSingleData();
  }, []);

  if (isLoading) {
    return <PagesIndex.Loader />;
  } else {
    return (
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            handleSubmit,
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Index.Box className="edit-order">
                <Index.Box className="flex-all admin-title-box">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title admin-buyer-title"
                      component="h3"
                      variant="h3"
                    >
                      Edit Order
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="common-grid-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 4 }}
                      className="login-row"
                    >
                      <Index.Box
                        gridColumn={{
                          xxs: "span 12",
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="comman-form-group">
                          <Index.FormHelperText className="comman-form-label">
                            Buyer Name
                          </Index.FormHelperText>
                          <Index.Box className="auth-input-main">
                            {/* <Index.TextField
                              className="comman-form-control"
                              placeholder=""
                              name="buyerName"
                              value="John Doe"
                            /> */}
                            <Index.TextField
                              className="comman-form-control"
                              placeholder=""
                              type="test"
                              name="buyerName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.buyerName}
                              disabled={values.buyerName}
                            />
                            {errors.buyerName && touched.buyerName && (
                              <p className="error-text">{errors.buyerName}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xxs: "span 12",
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="comman-form-group">
                          <Index.FormHelperText className="comman-form-label">
                            Order Amount
                          </Index.FormHelperText>
                          <Index.Box className="auth-input-main">
                            {/* <Index.TextField
                              className="comman-form-control"
                              placeholder=""
                              name="orderAmount"
                              value="5000"
                            /> */}
                            <Index.TextField
                              className="comman-form-control"
                              placeholder=""
                              type="test"
                              name="amount"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.amount}
                            />
                            {errors.amount && touched.amount && (
                              <p className="error-text">{errors.amount}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xxs: "span 12",
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="comman-form-group">
                          <Index.FormHelperText className="comman-form-label">
                            Order Status
                          </Index.FormHelperText>
                          <Index.Box className="order-status-drop-main">
                            <PagesIndex.DropDown
                              options={statusData}
                              handleChange={handleChange}
                              value={values.status}
                              name="status"
                            />
                            {errors.status && touched.status && (
                              <p className="error-text">{errors.status}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="admin-footer-btn-box">
                  <PagesIndex.BlueborderButton
                    className="cancel-btn blue-border-btn"
                    btnLabel="Cancel"
                    // onClick={() => navigate("/admin/order-management")}
                    onClick={cancelBtn}
                  />
                  <PagesIndex.BlueFillButton
                    className="view-order-btn blue-fill-btn"
                    btnLabel="Save"
                    type="submit"
                  />
                </Index.Box>
              </Index.Box>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function ViewPayment() {

  return (
    <>
      <Index.Box className="view-payment">
        <Index.Box className="flex-all admin-title-box">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title admin-buyer-title"
              component="h3"
              variant="h3"
            >
              View Payment
            </Index.Typography>
          </Index.Box>
          <Index.Box className="view-btn-main border-btn-main">
            <PagesIndex.BlueborderButton
              className="cancel-btn blue-border-btn"
              btnLabel="Back"
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="common-grid-main">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 3 }}
              className="login-row"
            >
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Enquiry No :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    #1234
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Margin Amount :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    INR 10,0000
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Seller Amount :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    INR 40,0000
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Buyer Amount :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    INR 50,0000
                  </Index.Typography>
                </Index.Box>
              </Index.Box><Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Payment Term :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    Half Payment
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Proof Of Payment :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    <Index.Typography className="download-pdf-box">
                      95251528
                      <Index.Button className='download-pdf-btn' title='Download PDF'>
                        <img src={PagesIndex.Svg.pdfIcon} alt='pdf icon' />
                      </Index.Button>
                    </Index.Typography>
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <hr />
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                <Index.Box className="comman-view-detail-group title-devider">
                  Bank Details
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Bank Name :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    Bank Of India
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    IFSC Code :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    BKID0009525
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <hr />
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                <Index.Box className="comman-view-detail-group title-devider">
                  Transaction History
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Transaction ID :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    183336
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Transaction Date :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    12-12-2023
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Paid Amount :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    INR 30,0000
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Pending Amount :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    INR 20,0000
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Buyer Invoice :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    <Index.Typography className="download-pdf-box">
                      95251528
                      <Index.Button className='download-pdf-btn' title='Download PDF'>
                        <img src={PagesIndex.Svg.pdfIcon} alt='pdf icon' />
                      </Index.Button>
                    </Index.Typography>
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box gridColumn={{ xxs: "span 12", xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                <Index.Box className="comman-view-detail-group">
                  <Index.Typography className="comman-view-detail-label">
                    Seller Invoice :
                  </Index.Typography>
                  <Index.Typography className="comman-view-detail-value">
                    <Index.Typography className="download-pdf-box">
                      95251528
                      <Index.Button className='download-pdf-btn' title='Download PDF'>
                        <img src={PagesIndex.Svg.pdfIcon} alt='pdf icon' />
                      </Index.Button>
                    </Index.Typography>
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}

import React, { useState } from 'react'
import Index from '../../../../Index';
import PagesIndex from '../../../../PagesIndex';
import './expried.css'

function Expried() {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>

            <Index.Box className="expried-table-list">
                <Index.TableContainer className="expried-table-container common-table-container cus-scrollbar">
                    <Index.Table aria-label="simple table" className='expried-table common-table'>
                        <Index.TableHead className='expried-table-head common-table-head'>
                            <Index.TableRow className='expried-table-row common-table-row'>
                                <Index.TableCell className='expried-table-th common-table-th' width="12%" align="left">Enquiry no.</Index.TableCell>
                                <Index.TableCell className='expried-table-th common-table-th' width="18%" align="left">Company</Index.TableCell>
                                <Index.TableCell className='expried-table-th common-table-th' width="22%" align="center">Requirements</Index.TableCell>
                                <Index.TableCell className='expried-table-th common-table-th' width="17%" align="center">Date</Index.TableCell>
                                <Index.TableCell className='expried-table-th common-table-th' width="17%" align="center">Bid</Index.TableCell>
                                <Index.TableCell className='expried-table-th common-table-th' width="15%" align="center">Status</Index.TableCell>
                            </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className='expried-table-body common-table-body'>
                            <Index.TableRow
                                className='expried-table-row common-table-row'
                            >
                                <Index.TableCell className='expried-table-td common-table-td' align="left" width="12%">
                                    <Index.Box className="enquiry-no-list">
                                        00123
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="left" width="18%" >
                                    <Index.Box className="company-list-cancel">
                                        India expo <br />
                                        steels pvt
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="22%">
                                    <Index.Box className="cancel-product-details">
                                        <Index.List className='table-list'>
                                            <Index.ListItem className='table-list-item'>
                                                Dimensions (6 X 6)
                                            </Index.ListItem>
                                            <Index.ListItem className='table-list-item'>
                                                Qty (50 pieces)
                                            </Index.ListItem>
                                        </Index.List>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="17%">
                                    <Index.Box className="date-cancel-order">
                                        10/08/2023
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="17%">
                                    <Index.Box className="cancel-amount-list bid-value">
                                        INR <span>50,0000</span>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="15%">
                                    <Index.Box className="expried-title-action">
                                        Expired
                                    </Index.Box>
                                </Index.TableCell>
                            </Index.TableRow>
                            <Index.TableRow
                                className='expried-table-row common-table-row'
                            >
                                <Index.TableCell className='expried-table-td common-table-td' align="left" width="12%">
                                    <Index.Box className="enquiry-no-list">
                                        00123
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="left" width="18%" >
                                    <Index.Box className="company-list-cancel">
                                        India expo <br />
                                        steels pvt
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="22%">
                                    <Index.Box className="cancel-product-details">
                                        <Index.List className='table-list'>
                                            <Index.ListItem className='table-list-item'>
                                                Dimensions (6 X 6)
                                            </Index.ListItem>
                                            <Index.ListItem className='table-list-item'>
                                                Qty (50 pieces)
                                            </Index.ListItem>
                                        </Index.List>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="17%">
                                    <Index.Box className="date-cancel-order">
                                        10/08/2023
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="17%">
                                    <Index.Box className="cancel-amount-list bid-value">
                                        INR <span>50,0000</span>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="15%">
                                    <Index.Box className="expried-title-action">
                                        Expired
                                    </Index.Box>
                                </Index.TableCell>
                            </Index.TableRow>
                            <Index.TableRow
                                className='expried-table-row common-table-row'
                            >
                                <Index.TableCell className='expried-table-td common-table-td' align="left" width="12%">
                                    <Index.Box className="enquiry-no-list">
                                        00123
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="left" width="18%" >
                                    <Index.Box className="company-list-cancel">
                                        India expo <br />
                                        steels pvt
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="22%">
                                    <Index.Box className="cancel-product-details">
                                        <Index.List className='table-list'>
                                            <Index.ListItem className='table-list-item'>
                                                Dimensions (6 X 6)
                                            </Index.ListItem>
                                            <Index.ListItem className='table-list-item'>
                                                Qty (50 pieces)
                                            </Index.ListItem>
                                        </Index.List>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="17%">
                                    <Index.Box className="date-cancel-order">
                                        10/08/2023
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="17%">
                                    <Index.Box className="cancel-amount-list bid-value">
                                        INR <span>50,0000</span>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="15%">
                                    <Index.Box className="expried-title-action">
                                        Expired
                                    </Index.Box>
                                </Index.TableCell>
                            </Index.TableRow>
                            <Index.TableRow
                                className='expried-table-row common-table-row'
                            >
                                <Index.TableCell className='expried-table-td common-table-td' align="left" width="12%">
                                    <Index.Box className="enquiry-no-list">
                                        00123
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="left" width="18%" >
                                    <Index.Box className="company-list-cancel">
                                        India expo <br />
                                        steels pvt
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="22%">
                                    <Index.Box className="cancel-product-details">
                                        <Index.List className='table-list'>
                                            <Index.ListItem className='table-list-item'>
                                                Dimensions (6 X 6)
                                            </Index.ListItem>
                                            <Index.ListItem className='table-list-item'>
                                                Qty (50 pieces)
                                            </Index.ListItem>
                                        </Index.List>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="17%">
                                    <Index.Box className="date-cancel-order">
                                        10/08/2023
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="17%">
                                    <Index.Box className="cancel-amount-list bid-value">
                                        INR <span>50,0000</span>
                                    </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell className='expried-table-td common-table-td' align="center" width="15%">
                                    <Index.Box className="expried-title-action">
                                        Expired
                                    </Index.Box>
                                </Index.TableCell>
                            </Index.TableRow>
                        </Index.TableBody>
                    </Index.Table>
                </Index.TableContainer>
            </Index.Box>


        </>
    )
}

export default Expried
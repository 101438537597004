import React from "react";
import Index from "../../../../Index";
import PagesIndex from "../../../../PagesIndex";
import './ourkeyfeaturesection.css'
import './ourkeyfeaturesection.responsive.css'

function Ourkeyfeaturesection() {
  return (
    <Index.Box className="key-feature-wrape" component="section">
      <Index.Box className="cus-container">
        <Index.Typography component="h4" className="key-feature-title">Our key features</Index.Typography>
        <Index.Typography component="p" className="key-feature-subtitle">
          Lorem ipsum dolor sit amet consectetur.
        </Index.Typography>
      </Index.Box>
      <Index.Box className="crousel-wrape">
        <PagesIndex.Swiper
          modules={[PagesIndex.Navigation, PagesIndex.Pagination]}
          className="mySwiper"
          slidesPerView={1.1}
          spaceBetween={10}
          centeredSlides={true}
          loop={true}
          pagination={{
            el: ".custom-pagination",
            clickable: true,
            type: "fraction",
          }}
          navigation={{
            nextEl: ".cus-swiper-button-next",
            prevEl: ".cus-swiper-button-prev",
          }}
          breakpoints={{
            550: {
              spaceBetween: 10,
              slidesPerView: 1.4,
            },
            768: {
              spaceBetween: 15,
              slidesPerView: 2.4,
            },
            1024: {
              spaceBetween: 15,
              slidesPerView: 2.8,
            },
            1250: {
              spaceBetween: 20,
              slidesPerView: 3.4,
            },
          }}
        >
          <PagesIndex.SwiperSlide>
            <Index.Box className="white-box">
              <Index.Box className="icon-wrape">
                <img src={PagesIndex.Svg.bluesearch} alt="key-feature-icon" />
              </Index.Box>
              <Index.Typography component="h6">
                Search and Filtering Capabilities
              </Index.Typography>
              <Index.Typography component="p">
                The application empowers users with advanced filtering and
                customization options, making it easy to search for and find
                the right steel or PVC pipes.
              </Index.Typography>
            </Index.Box>
          </PagesIndex.SwiperSlide>
          <PagesIndex.SwiperSlide>
            <Index.Box className="white-box">
              <Index.Box className="icon-wrape">
                <img src={PagesIndex.Svg.bluetick} />
              </Index.Box>
              <Index.Typography component="h6">
                Turnkey Solutions
              </Index.Typography>
              <Index.Typography component="p">
                Stelmart provides end-to-end services and support to meet the
                diverse needs of buyers and sellers in both the steel and PVC
                pipe industries. This includes everything from product
                selection to delivery.
              </Index.Typography>
            </Index.Box>
          </PagesIndex.SwiperSlide>
          <PagesIndex.SwiperSlide>
            <Index.Box className="white-box">
              <Index.Box className="icon-wrape">
                <img src={PagesIndex.Svg.bluelist} alt="key-feature-icon" />
              </Index.Box>
              <Index.Typography component="h6">
                Detailed Product Information
              </Index.Typography>
              <Index.Typography component="p">
                Stelmart's listings provide detailed product information for
                steel pipes and PVC pipes, helping users make informed
                decisions when selecting the right pipes for their projects.
              </Index.Typography>
            </Index.Box>
          </PagesIndex.SwiperSlide>
          <PagesIndex.SwiperSlide>
            <Index.Box className="white-box">
              <Index.Box className="icon-wrape">
                <img src={PagesIndex.Svg.bluesearch} alt="key-feature-icon" />
              </Index.Box>
              <Index.Typography component="h6">
                Search and Filtering Capabilities
              </Index.Typography>
              <Index.Typography component="p">
                The application empowers users with advanced filtering and
                customization options, making it easy to search for and find
                the right steel or PVC pipes.
              </Index.Typography>
            </Index.Box>
          </PagesIndex.SwiperSlide>
          <PagesIndex.SwiperSlide>
            <Index.Box className="white-box">
              <Index.Box className="icon-wrape">
                <img src={PagesIndex.Svg.bluetick} />
              </Index.Box>
              <Index.Typography component="h6">
                Turnkey Solutions
              </Index.Typography>
              <Index.Typography component="p">
                Stelmart provides end-to-end services and support to meet the
                diverse needs of buyers and sellers in both the steel and PVC
                pipe industries. This includes everything from product
                selection to delivery.
              </Index.Typography>
            </Index.Box>
          </PagesIndex.SwiperSlide>
          <PagesIndex.SwiperSlide>
            <Index.Box className="white-box">
              <Index.Box className="icon-wrape">
                <img src={PagesIndex.Svg.bluelist} alt="key-feature-icon" />
              </Index.Box>
              <Index.Typography component="h6">
                Detailed Product Information
              </Index.Typography>
              <Index.Typography component="p">
                Stelmart's listings provide detailed product information for
                steel pipes and PVC pipes, helping users make informed
                decisions when selecting the right pipes for their projects.
              </Index.Typography>
            </Index.Box>
          </PagesIndex.SwiperSlide>
        </PagesIndex.Swiper>
      </Index.Box>
      <Index.Box className="slide-controller">
        <Index.Box className="cus-swiper-button-prev">
          <img src={PagesIndex.Svg.prvwhitethik} alt="" />
        </Index.Box>
        <div className="custom-pagination"></div>
        <Index.Box className="cus-swiper-button-next">
          <img src={PagesIndex.Svg.nxtwhitethik} alt="" />
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

export default Ourkeyfeaturesection
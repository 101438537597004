import React from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PagesIndex";
import './testimonialsection.css'
import './testimonialsection.responsive.css'

function Testimonialsection() {
  return (
    <Index.Box className="testimonial-wrape" component="section">
      <Index.Box className="cus-container">
        <Index.Typography component="h4">
          Personalize your buying and selling experience with curated benefits
        </Index.Typography>
        <Index.Box className="crousel-wrape">
          <PageIndex.Swiper
            loop={true}
            modules={[PageIndex.Navigation, PageIndex.Pagination]}
            className="mySwiper"
            navigation={{
              nextEl: ".swiper-button-nexts",
              prevEl: ".swiper-button-prevs",
            }}
          >
            <PageIndex.SwiperSlide>
              <Index.Box className="left-wrape">
                <Index.Box className="left-wrape-img">
                  <img src={PageIndex.Png.user} />
                </Index.Box>
                <Index.Box className="content">
                  <Index.Typography component="h6">
                    Rahul Sharma
                  </Index.Typography>
                  <Index.Typography component="span">
                    COO STELMART
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="right-wrape">
                <Index.Typography component="p">
                  Selling on STELMART will be a game-changer for any business. The platform's user-friendly interface, coupled with its extensive network of buyers, will not only simplify the selling process but also significantly expand the reach to a large number of buyers across the country. Real-time communication and transparent transactions will make the seller/buyer experience exceptionally smooth and profitable.
                </Index.Typography>
              </Index.Box>
            </PageIndex.SwiperSlide>
            <PageIndex.SwiperSlide>
              <Index.Box className="left-wrape">
                <Index.Box className="left-wrape-img">
                  <img src={PageIndex.Png.user} />
                </Index.Box>
                <Index.Box className="content">
                  <Index.Typography component="h6">
                    Rahul Sharma
                  </Index.Typography>
                  <Index.Typography component="span">
                    COO STELMART
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="right-wrape">
                <Index.Typography component="p">
                  Selling on STELMART will be a game-changer for any business. The platform's user-friendly interface, coupled with its extensive network of buyers, will not only simplify the selling process but also significantly expand the reach to a large number of buyers across the country. Real-time communication and transparent transactions will make the seller/buyer experience exceptionally smooth and profitable.
                </Index.Typography>
              </Index.Box>
            </PageIndex.SwiperSlide>
            <Index.Box className="control">
              <Index.Box className="swiper-button-prevs">
                <img src={PageIndex.Svg.testiprevarrrow} />
              </Index.Box>
              <Index.Box className="swiper-button-nexts">
                <img src={PageIndex.Svg.testinextarrrow} />
              </Index.Box>
            </Index.Box>
          </PageIndex.Swiper>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

export default Testimonialsection
import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function PaymentManagement() {
  return (
    <>
      <Index.Box className="payment-management">
        <Index.Box className="flex-all admin-title-box">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title admin-buyer-title"
              component="h3"
              variant="h3"
            >
              Payment Management
            </Index.Typography>
          </Index.Box>
          <Index.Box className="view-btn-main border-btn-main">
            <Index.Box className="admin-search-common">
              <Index.TextField fullWidth className="form-control" placeholder="Search" />
              <img src={PagesIndex.Svg.search} className='search-icon' />
            </Index.Box>
            <PagesIndex.BlueFillButton
              className="view-order-btn blue-fill-btn"
              btnLabel="Add Payment"
            />
          </Index.Box>
        </Index.Box>
        <Index.Box className="page-order-table-main">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container cus-scrollbar"
          >
            <Index.Table
              sx={{ minWidth: 1200 }}
              aria-label="simple table"
              className="table"
            >
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="10%"
                  >
                    Enquiry No.
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="15%"
                  >
                    Requirements
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="14%"
                  >
                    Payment Terms
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="10%"
                  >
                    Status
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="11%"
                  >
                    Amount paid
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    style={{ fontWeight: 700 }}
                    width="11%"
                  >
                    Margin
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    align='center'
                    style={{ fontWeight: 700 }}
                    width="8%"
                  >
                    Vendor Invoice
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    align='center'
                    style={{ fontWeight: 700 }}
                    width="8%"
                  >
                    Customer  Invoice
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    align="center"
                    style={{ fontWeight: 700 }}
                    width="13%"
                  >
                    Action
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody className="table-body">
                <Index.TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <Index.TableCell
                    component="td"
                    variant="td"
                    scope="row"
                    className="table-td"
                  >
                    #1234
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    Product Name
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    Advance Payment
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    <Index.Box
                      sx={{ color: "white", backgroundColor: "#27447C" }}
                      className="buyer-list-status"
                    >
                      Confirmed
                    </Index.Box>
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    INR 50,0000
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    INR 50,0000
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    align='center'
                    className="table-td"
                  >
                    <Index.Button className='table-download-icon'>
                      <img src={PagesIndex.Svg.downloadicon} className='download-icon' alt='download sample' />
                    </Index.Button>
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    align='center'
                    className="table-td"
                  >
                    <Index.Button className='table-download-icon'>
                      <img src={PagesIndex.Svg.downloadicon} className='download-icon' alt='download sample' />
                    </Index.Button>
                  </Index.TableCell>
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                    align="center"
                  >
                    <Index.Box sx={{ padding: "0px", margin: "0px" }}>
                      <Index.IconButton>
                        <Index.Visibility
                          style={{ color: "black" }}
                        />
                      </Index.IconButton>
                      <Index.IconButton>
                        <img
                          src={PagesIndex.Svg.editingIcon}
                          alt="edit icon"
                          width="20"
                          height="20"
                        />
                      </Index.IconButton>
                      <Index.IconButton>
                        <Index.DeleteOutlineIcon
                          style={{ color: "black" }}
                        />
                      </Index.IconButton>
                    </Index.Box>
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
          <Index.TablePagination
            className="cus-scrollbar"
            component="div"
          />
        </Index.Box>
      </Index.Box>
      <PagesIndex.AddPayment />
      <PagesIndex.ViewPayment />
    </>
  )
}

import React from 'react'
import Index from '../../../../Index'
import './numbersTalk.css'
import './numbersTalk.responsive.css'

export default function NumbersTalk() {

    const numberItem = [
        {
            number: "1000+",
            label: "Business",
        },
        {
            number: "1000+",
            label: "Business",
        },
        {
            number: "1000+",
            label: "Business",
        },
        {
            number: "1000+",
            label: "Business",
        },
    ]
    return (
        <Index.Box className="cus-container" component="section">
            <Index.Box className="numbers-talk">
                <Index.Box className="title">
                    Let the numbers talk
                </Index.Box>
                <Index.Box className="content">
                    Our drive and vision to make the next million businesses digital has helped us successfully serve over 1000+ clients across several industries.
                </Index.Box>
                <Index.Box className="numbers-item-box">
                    {numberItem.map((item, key) => (
                        <Index.Box key={key} className="numbers-item">
                            <Index.Box key={key} className="numbers-item-inner">
                                <Index.Box className="number">
                                    {item.number}
                                </Index.Box>
                                <Index.Box className="label">
                                    {item.label}
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    ))}
                </Index.Box>
            </Index.Box>
        </Index.Box>
    )
}
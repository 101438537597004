// const Joi = require("joi");

// const validateAdmin = Joi.object({
//   Email: Joi.string().max(50).email().required(),
//   PassWord: Joi.string().min(8).required(),
// });

// module.exports = { validateAdmin };
import * as Yup from "yup";

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const loginEmail = /^[a-z][a-z0-9]*(?:\.[a-z]+)*@[a-z]+\.[a-z]{2,}$/;

const passwordRegex =
  /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).+$/;
const startSpace = /^(?!\s).*$/;

export const adminLoginValidationSchema = ({ isLoginPage }) =>
  Yup.object().shape({
    email: Yup.string()
      .matches(startSpace, "Starting space not allow")
      .matches(loginEmail, "Please enter valid Email")
      .required("Please enter your email address"),
    password: isLoginPage
      ? Yup.string()
          .matches(startSpace, "Starting space not allow")
          .required("Please enter password")
      : Yup.string(),

    // remember: Yup.boolean().oneOf([true], "Remember me is required"),
  });

export const adminVerifyOtpValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .matches(/^\d{4}$/, "OTP must be a 4-digit number")
    .required("Please enter your OTP"),
});
export const adminResetPasswordValidationSchema = Yup.object().shape({
  Password: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .min(8, "Password must be at least 8 characters")
    .matches(passwordRegex, "Please enter strong password")
    .required("Please enter password"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("Password"), null],
      "New Password and Confirmed passwords do not match"
    )
    .min(8, "Confirm Password must be at least 8 characters")
    .required("Please enter confirm password"),
});

import React, { useRef, useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import "./auth.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";
import { toast } from "react-toastify";
// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function UserOtp({ openUserOtp, handleCloseUserOtp, email,handleCloseForgotUser,openLoginModal }) {
  const [openResetUser, setOpenResetUser] = useState(false);
  const handleOpenResetUser = () => setOpenResetUser(true);
  const handleCloseResetUser = () => setOpenResetUser(false);
  console.log(email, "email");
  const formikRef = useRef();

  let initialValues = {
    Otp: "",
  };
  const validationSchema = Yup.object().shape({
    Otp: Yup.string()
      .min(4, "Please enter valid OTP")
      .required("Please enter OTP"),
  });

  const handleFormSubmit = async (values) => {
    // handleOpenResetUser();
    console.log(values, 38);
    const data = {
      email: email.email,
      otp: values.Otp,
    };
    await DataService.post(Api.User.Auth.SEND_OTP, data)
      .then((res) => {
        console.log(res?.data, 744);
        toast.success(res?.data?.message);
        // if (res?.data?.status == 200) {
        handleOpenResetUser();
       
        // }
        values.Otp = "";
        formikRef.current.resetForm();
      })
      .catch((errors) => {
        console.log(errors, 74);
        toast.error(errors?.response?.data?.message);
        formikRef.current.resetForm();
      });
  };

  return (
    <>
      <Index.Modal
        open={openUserOtp}
        onClose={handleCloseUserOtp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="login-modal-inner-main auth-modal-inner modal-common-style"
        >
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <Index.Typography className="auth-modal-title">
                OTP
              </Index.Typography>
              <img
                src={PagesIndex.Svg.closeicon}
                className="modal-close-icon"
                onClick={handleCloseUserOtp}
              />
            </Index.Box>
            <Formik
              innerRef={formikRef}
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Index.Box className="modal-body">
                    <Index.Box className="comman-form-group comman-otp-details">
                      <Index.MuiOtpInput
                        length={4}
                        className="comman-form-control otp-input"
                        name="Otp"
                        onBlur={handleBlur}
                        type="number"
                        value={values.Otp}
                        onChange={(value) => setFieldValue("Otp", value)}
                        helperText={touched.Otp && errors.Otp}
                        error={Boolean(errors.Otp && touched.Otp)}
                      />
                      {touched.Otp && errors.Otp && (
                        <Index.FormHelperText error>
                          {errors.Otp}
                        </Index.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="modal-footer">
                    <Index.Box className="forgot-btn-footer">
                      <Index.Box className="modal-auth-btn-main">
                        <PagesIndex.OrangeFillButton
                          btnLabel="Submit"
                          className="orange-fill-btn auth-modal-btn"
                          type="submit"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <PagesIndex.ResetPassword
                    openResetUser={openResetUser}
                    email={email}
                    handleCloseResetUser={handleCloseResetUser}
                    handleCloseForgotUser={handleCloseForgotUser}
                    handleCloseUserOtp={ handleCloseUserOtp}
                    openLoginModal={openLoginModal}
                  />
                </Form>
              )}
            </Formik>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

import React from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PagesIndex";
import './statSection.css'
import './statSection.responsive.css'

export default function StatSection() {

  return (
    <Index.Box className="states-wrape" component="section">
      <Index.Box className="cus-container">
        <Index.Box className="inner">
          <Index.Box className="state">
            <Index.Typography component="h5" className="state-title">1475</Index.Typography>
            <Index.Typography component="p" className="state-sub-title">Trusted Buyers</Index.Typography>
          </Index.Box>
          <Index.Box className="state">
            <Index.Typography component="h5" className="state-title">
              2K<Index.Typography component="span">+</Index.Typography>
            </Index.Typography>
            <Index.Typography component="p" className="state-sub-title">Trusted Sellers</Index.Typography>
          </Index.Box>
          <Index.Box className="state">
            <Index.Typography component="h5" className="state-title">
              60<Index.Typography component="span">+</Index.Typography>
            </Index.Typography>
            <Index.Typography component="p" className="state-sub-title">
              Enquiries Received
            </Index.Typography>
          </Index.Box>
          <Index.Box className="state">
            <Index.Typography component="h5" className="state-title">
              40<Index.Typography component="span">+</Index.Typography>
            </Index.Typography>
            <Index.Typography component="p" className="state-sub-title">
              Quotations Submitted
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )

}

import React from 'react'
import Index from '../../Index'

function OrangeOutlineButton(props) {
    return (
        <>

            <Index.Box className='orange-outline-btn-main'>
                <Index.Button className={props.className} onClick={props.onClick}>{props.btnLabel}</Index.Button>
            </Index.Box>

        </>
    )
}

export default OrangeOutlineButton
import slide3 from './images/jpg/slider3.jpg';
import slide1 from './images/jpg/slider1.jpg';
import slide2 from './images/jpg/slider2.jpg';
import joinus from './images/jpg/joinus.jpg';
import aboutBanner from './images/jpg/about_banner.jpg';
import WhoWeAre from './images/jpg/who_we_are.jpg';
import blogImg1 from './images/jpg/blogImg1.jpg';
import blogImg2 from './images/jpg/blogImg2.jpg';
import blogImg3 from './images/jpg/blogImg3.jpg';
import blogImg4 from './images/jpg/blogImg4.jpg';
import blogImg5 from './images/jpg/blogImg5.jpg';
import blogImg6 from './images/jpg/blogImg6.jpg';
import blogImg7 from './images/jpg/blogImg7.jpg';
import blogImg8 from './images/jpg/blogImg8.jpg';
import blogImg9 from './images/jpg/blogImg9.jpg';
import blogImg10 from './images/jpg/blogImg10.jpg';
import blogImg11 from './images/jpg/blogImg11.jpg';
import blogImg12 from './images/jpg/blogImg12.jpg';
import blogImg13 from './images/jpg/blogImg13.jpg';
import teamImg1 from './images/jpg/teamImg1.jpg';
import teamImg2 from './images/jpg/teamImg2.jpg';
import teamImg3 from './images/jpg/teamImg3.jpg';
import teamImg4 from './images/jpg/teamImg4.jpg';
import teamImg5 from './images/jpg/teamImg5.jpg';
import productImg1 from './images/jpg/productImg1.jpg';
import catalougeBg from './images/jpg/catalouge_bg.jpg';
import invoiceSample from './images/jpg/simple-invoice-template.jpg';

const Jpg = {
    slide1,
    slide2,
    slide3,
    joinus,
    aboutBanner,
    WhoWeAre,
    blogImg1,
    blogImg2,
    blogImg3,
    blogImg4,
    blogImg5,
    blogImg6,
    blogImg7,
    blogImg8,
    blogImg9,
    blogImg10,
    blogImg11,
    blogImg12,
    blogImg13,
    teamImg1,
    teamImg2,
    teamImg3,
    teamImg4,
    teamImg5,
    productImg1,
    catalougeBg,
    invoiceSample,
}

export default Jpg;

import React from 'react'
import Index from '../../../../Index'
import './sellerAnalytics.css'
import './sellerAnalytics.responsive.css'

export default function SellerAnalytics() {

    const analyticsItem = [
        {
            label: "Total order value & Volume",
            value: "12",
        },
        {
            label: "Total order value & Volume",
            value: "12",
        },
        {
            label: "Total order value & Volume",
            value: "12",
        },
        {
            label: "Total order value & Volume",
            value: "12",
        },
        {
            label: "Total order value & Volume",
            value: "12",
        },
        {
            label: "Total order value & Volume",
            value: "12",
        },
    ]

    return (

        <Index.Box className="seller-analytics-main">
            <Index.Box className="analytics-item-box">
                {analyticsItem.map((item, key) => (
                    <Index.Box key={key} className="analytics-item">
                        <Index.Box className="analytics-item-label">
                            {item.label}
                        </Index.Box>
                        <Index.Box className="analytics-item-value">
                            {item.value}
                        </Index.Box>
                    </Index.Box>
                ))}
            </Index.Box>
        </Index.Box>

    )

}
import React from 'react'
import Index from '../../../Index'
import './career.css'
import './career.responsive.css'
import PagesIndex from '../../../PagesIndex';

export default function Career() {
  
  const [openCareerModal, setOpenCareerModal] = React.useState(false);
  const handleOpenCareerModal = () => setOpenCareerModal(true);
  const handleCloseCareerModal = () => setOpenCareerModal(false);
  
  return (
    <>
      <Index.Box className="main-career" component="main">
        <Index.Box className="career-banner-section">
          <Index.Box className="career-care-box">
            <Index.Typography className='career-care-text'>Careers</Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="career-content-main">
          <Index.Box className="cus-container">
            <Index.Box className="career-para" component="p" variant="p">Mechanical tubing seems to be following a similar trend to structural tubing. Drawn Over Mandrel (DOM), Cold Drawn Seamless (CDS) and Hot Rolled Seamless (HRS) tubing are all ticking upward. Over the last few weeks, we have seen prices increase due to coil and the CRU index rising. These increases are reversing the lows we have seen over the last few months. While decreases are never “announced” in mechanical tubing, we had been seeing several months of lowering prices until last week (see estimate in chart below).</Index.Box>
          </Index.Box>
          <Index.Box className="our-culture-section">
            <Index.Box className="our-culture-title-box">
              <Index.Typography className='our-culture-title'>OUR CULTURE</Index.Typography>
            </Index.Box>

            <Index.Box className='cus-container'>
              <Index.Box className="grid-main">
                <Index.Box className="culture-row" >
                  <Index.Box className="grid-column">
                    <Index.Box className="culture-main-box">
                      <Index.Typography className='culture-title' component="h3" variant="h3">Our People</Index.Typography>
                      <Index.Typography className='culture-para' component="p" variant="p">Mechanical tubing seems to be following a similar trend to structural tubing. Drawn Over Mandrel (DOM), Cold Drawn Seamless (CDS) and Hot Rolled Seamless (HRS) tubing are all ticking upward.</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="grid-column">
                    <Index.Box className="culture-main-box">
                      <Index.Typography className='culture-title' component="h3" variant="h3">Our Spirit</Index.Typography>
                      <Index.Typography className='culture-para' component="p" variant="p">Mechanical tubing seems to be following a similar trend to structural tubing. Drawn Over Mandrel (DOM), Cold Drawn Seamless (CDS) and Hot Rolled Seamless (HRS) tubing are all ticking upward.</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="grid-column">
                    <Index.Box className="culture-main-box">
                      <Index.Typography className='culture-title' component="h3" variant="h3">Our Passion</Index.Typography>
                      <Index.Typography className='culture-para' component="p" variant="p">Mechanical tubing seems to be following a similar trend to structural tubing. Drawn Over Mandrel (DOM), Cold Drawn Seamless (CDS) and Hot Rolled Seamless (HRS) tubing are all ticking upward.</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="career-inquire-section">
            <Index.Box className="cus-container">
              <Index.Box className="career-inqure-title-main">
                <Index.Typography className='career-inqure-title'>Contact us to inquire about the openings</Index.Typography>
              </Index.Box>
              <Index.Box className="career-inqure-row" >
                <Index.Box className="grid-column">
                  <Index.Box className="career-inqure-main-box" onClick={handleOpenCareerModal}>
                    <Index.Typography className='career-inqure-lables' component="h3" variant="h3">North America Support
                      Centers</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="career-inqure-main-box" onClick={handleOpenCareerModal}>
                    <Index.Typography className='career-inqure-lables' component="h3" variant="h3">Stores and Design &
                      Service Centers</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="career-inqure-main-box" onClick={handleOpenCareerModal}>
                    <Index.Typography className='career-inqure-lables' component="h3" variant="h3">Banter by Piercing
                      Pagoda</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="career-inqure-main-box" onClick={handleOpenCareerModal}>
                    <Index.Typography className='career-inqure-lables' component="h3" variant="h3">Diamonds Direct</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="career-inqure-main-box" onClick={handleOpenCareerModal}>
                    <Index.Typography className='career-inqure-lables' component="h3" variant="h3">James Allen</Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="grid-column">
                  <Index.Box className="career-inqure-main-box" onClick={handleOpenCareerModal}>
                    <Index.Typography className='career-inqure-lables' component="h3" variant="h3">UK Job Openings</Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PagesIndex.CareerModal handleCloseCareerModal={handleCloseCareerModal} openCareerModal={openCareerModal} />
    </>
  )
}

import React from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";

export default function Dropdown({ handleChange, value, name, options }) {
  // for open handleChangedropdown

  console.log(handleChange, value, name, "option8");
  const [age, setAge] = React.useState("");

  const handleChangedropdown = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Index.Box className="input-box">
        <Index.Box className="form-group">
          <Index.Box className="dropdown-box">
            <Index.FormControl className="form-control">
              <Index.Select
                className="dropdown-select "
                // value={value}
                // onChange={handleChange}
                value={value}
                name={name}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                // name="status"
              >
                {/* <Index.MenuItem value="Confirmed" className="menuitem">
                  Confirmed
                </Index.MenuItem>
                <Index.MenuItem value="InProcess" className="menuitem">
                  {" "}
                  In Process
                </Index.MenuItem>
                <Index.MenuItem value="Shipped" className="menuitem">
                  {" "}
                  Shipped
                </Index.MenuItem>
                <Index.MenuItem value="Delivered" className="menuitem">
                  {" "}
                  Delivered
                </Index.MenuItem> */}
                {options?.map((variant) => (
                  <Index.MenuItem key={variant} value={variant}>
                    {console.log(variant, 219)}

                    <Index.ListItemText primary={variant} />
                  </Index.MenuItem>
                ))}
              </Index.Select>
            </Index.FormControl>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import PagesIndex from "../../../../container/PagesIndex";
import { userLogin } from "./UserService";
// import PageIndex from "../../../../container/PageIndex";

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    userToken: "",
    isAuthenticated: false,
    isLoading: false,
    userData: {},
  },
  reducers: {
    getUserData: (state, action) => {
      state.userData = action.payload;
    },
    userLogout: (state, action) => {
      state.userToken = "";
      state.isAuthenticated = false;
      state.isLoading = false;
      state.userData = {};
    },
  },

  extraReducers: (builder) => {
    // builder.addCase(LoginAdmin.fulfilled, (state, action) => {
    //   state.isAuthenticated = true;
    // });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.userData = action?.payload?.data;
      state.userToken = action?.payload?.data?.token;
    });
  },
});

export const { getUserData, userLogout } = UserSlice.actions;

export default UserSlice.reducer;

import React from 'react'
import Index from '../../Index'
import PagesIndex from '../../PagesIndex'

export default function ProductCard() {
  return (
      <Index.Box className="brand-main">
        <Index.Box className="brand-img-main">
          <Index.Link to="/product-detail" className='brnad-img-box'>
            <img src={PagesIndex.Png.brandimg} alt='brand' className='brand-img' />
          </Index.Link>
          <Index.Box className="brand-content-main">
            <Index.Box className="brand-flex">
              <Index.Typography className="" component="h3" variant='h3'>
                <Index.Link to="/product-detail" className='brand-name'>
                  Brand Name
                </Index.Link>
              </Index.Typography>
              <Index.Typography className="brand-estate-text" component="h6" variant='h6'>The grand estate</Index.Typography>
            </Index.Box>
            <Index.Typography className="brand-para" component="p" variant='p'>Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet </Index.Typography>
            <PagesIndex.BlueFillButton btnLabel="Add to cart" className="blue-fill-btn prodct-cart-btn" />
          </Index.Box>
        </Index.Box>
      </Index.Box>
  )
}
